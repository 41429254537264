import React, { useEffect, useState } from 'react';

import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import moment from 'moment';
import ReservationServices from '../services/ReservationsServices';
import ReservationOverviewModal from './Timeline/modals/ReservationOverviewModal';
import ReservationItem from '../components/ReservationItem';

export default function ActivityScreen({ navigation }: any) {
  const [reservations, setReservations] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [updatedDate, setUpdatedDate] = useState(moment());

  const getReservations = () => {
    setRefreshing(true);
    try {
      ReservationServices.getUpcomingReservations().then(res => {
        if (res) {
          setUpdatedDate(moment());
          setReservations(res);
        }
      });
    } catch (error) {
      console.log(error);
    }
    setRefreshing(false);
  };

  useEffect(() => {
    getReservations();
  }, []);

  useEffect(() => {
    const listener = navigation.addListener('didFocus', () => {
      getReservations();
    });
    return () => listener.remove();
  }, []);

  const renderItem = ({ item }) => (
    <ReservationItem
      dateFrom={item.reservation_date}
      dateTo={item.reservation_date_end}
      dateCreated={item.date_create}
      name={item.customer?.fullname}
      persons={item.persons}
      multiTable={item?.tables.length > 1}
      cancelled={item.cancelled}
      createdByRestaurant={item.created_by_restaurant || item.manual_entry}
      webForm={item.created_by_non_registered_customer}
      walkIn={item.walk_in}
      messages={item?.messages}
      onPress={() => setSelectedReservation(item)}
      showStatus
      showTimeAgo
      // hideIcons
    />
  );

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.title}>Recent Reservations</Text>

        <TouchableOpacity onPress={() => getReservations()}>
          <Text style={styles.lastUpdated}>
            Updated at {updatedDate.format('HH:mm')}
          </Text>
        </TouchableOpacity>
      </View>
      <FlatList
        data={reservations}
        renderItem={renderItem}
        keyExtractor={item => `${item.id}`}
        refreshing={refreshing}
        onRefresh={getReservations}
        contentContainerStyle={{ marginHorizontal: 10 }}
      />
      <ReservationOverviewModal
        isVisible={!!selectedReservation}
        onBackDropPress={() => setSelectedReservation(null)}
        onSwipeComplete={() => setSelectedReservation(null)}
        selectedReservation={selectedReservation}
      />
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  lastUpdated: {
    color: '#999',
    fontSize: 12,
    backgroundColor: '#f3f3f3',
    padding: 6,
    borderRadius: 6,
    overflow: 'hidden',
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
  },
});
