import Buttons from '../styles/Buttons';
import { Text, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import React from 'react';

const HeaderButton = ({
  small,
  label,
  icon,
  backgroundColor,
  color,
  onPress,
  shadow,
  iconSize,
}) => {
  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={onPress}
      style={[
        Buttons.button,
        small && Buttons.buttonSmall,
        backgroundColor && { backgroundColor },
        shadow && Buttons.shadow,
      ]}
    >
      <Text selectable={false} style={[Buttons.buttonIcon, color && { color }]}>
        <Ionicons name={icon} size={iconSize || 22} />
      </Text>
      <Text
        style={[Buttons.buttonText, color && { color }]}
        selectable={false}
        numberOfLines={1}
      >
        {small ? '' : ` ${label} `}
      </Text>
    </TouchableOpacity>
  );
};

export default HeaderButton;
