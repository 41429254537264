import React, { createRef } from 'react';
import {
  Animated,
  Dimensions,
  Image,
  PanResponder,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import moment from 'moment';
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import NewReservationModal from './modals/NewReservationModal';
import QuickAddReservation from './modals/QuickAddReservation';

import Colors from '../../constants/Colors';
import deviceStorage from '../../services/DeviceStorage';
import CalendarModal from './modals/CalendarModal';
import TimelineHeadStyle from '../../styles/TimelineHeadStyle';

import DateSelectItem from '../../components/timelineComponents/DateSelectItem';
import CurrentTimeIndicator from '../../components/timelineComponents/CurrentTimeIndicator';
import DrawTables from '../../components/timelineComponents/DrawTables';
import DrawGrid from '../../components/timelineComponents/DrawGrid';
import DrawHours from '../../components/timelineComponents/DrawHours';
import { withGlobalContext } from '../../GlobalContext';
import Buttons from '../../styles/Buttons';
import ReservationOverviewModal from './modals/ReservationOverviewModal';
import SelectRestaurantModal from './modals/SelectRestaurantModal';
import OptionsModal from './modals/OptionsModal';
import HeaderButton from '../../components/HeaderButton';
import DrawReservation from '../../components/timelineComponents/DrawReservation';
import ConfirmEditsModal from './modals/ConfirmEditsModal';

const TABLE_COLUMN_WIDTH = 90;
const TABLE_COLUMN_WIDTH_SMALL = 76;

const downArrow = (
  <Ionicons name="options" size={21} color={Colors.lightBlue} />
);

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hourLength: 132,
      rowHeight: 48,
      hideCancelled: false,
      scrolling: false,
      scrollingMomentum: false,
      isReservationDetailsVisible: false,
      isCalendarVisible: false,
      isNewReservationVisible: false,
      isSelectRestaurantVisible: false,
      isOptionsModalVisible: false,
      selectedReservation: {},
      time: moment(),
      pan: new Animated.ValueXY({ x: 0, y: 0 }),
      helperCirclePan: new Animated.ValueXY({ x: 0, y: 0 }),
      boxOpacity: new Animated.Value(0),
      helperCircleOpacity: new Animated.Value(0),
      boxScale: new Animated.Value(0),
      offsets: { x: 0, y: 0 },
      isBoxActive: false,
      iconOpacity: new Animated.Value(0),
      buttonOpacity: new Animated.Value(1),
      isQuickAddVisible: false,
      isConfirmEditsModalVisible: false,
      quickAddParameters: null,
      tableColumnWidth: 90,
      isNarrow: false,
      isPhone: false,
      editingReservations: false,
      reservationEdits: [],
    };

    this.viewMarker = createRef();
    this.newReservationButtonRef = createRef();
    this.timelineHeaderScroll = createRef();
    this.timelineScroll = createRef();

    this._bootstrapAsync();

    this._panResponder = PanResponder.create({
      onMoveShouldSetPanResponder: (e, gestureState) => {
        // this.showNewReservationBox(e);
        return true;
        // const { isBoxActive } = this.state;
        // return isBoxActive;
      },
      onStartShouldSetPanResponder: (e, gestureState) => {
        // this.showNewReservationBox(e);
        return true;
      },
      onPanResponderGrant: (e, gestureState) => {
        const { iconOpacity, buttonOpacity } = this.state;

        iconOpacity.setValue(0);
        Animated.timing(buttonOpacity, {
          toValue: 0,
          duration: 40,
          useNativeDriver: true,
        }).start();
        this.showNewReservationBox(e);

        // this.viewMarker.measureInWindow((x, y, width, height) => {
        //   console.log('ii', x, y, width, height);
        // });
        this.viewMarker.current.measure((x, y, width, height, pageX, pageY) => {
          this.setState({
            offsets: { x: pageX, y: pageY },
          });
        });
      },
      onPanResponderTerminationRequest: (e, gestureState) => {
        const { boxOpacity, buttonOpacity, boxScale } = this.state;
        console.log('on Termination request');
        Animated.timing(boxOpacity, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
        }).start();
        Animated.timing(boxScale, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
        }).start();
        Animated.timing(buttonOpacity, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          delay: 1400,
        }).start();
        return true;
      },
      onPanResponderTerminate: (e, gestureState) => {
        // Another component has become the responder, so this gesture
        setTimeout(() => {
          this.setState({ isBoxActive: false });
        }, 100);
        console.log('terminated');
      },
      onPanResponderEnd: (e, gestureState) => {
        // setTimeout(() => {
        //   this.setState({ isBoxActive: false });
        // }, 500);
        const { helperCirclePan, helperCircleOpacity } = this.state;
        const { showAlert } = this.props.global;
        const dist = gestureState.dx ** 2 + gestureState.dy ** 2;
        if (dist < 500) {
          helperCirclePan.setValue({
            x: gestureState.x0 - 20,
            y: gestureState.y0 - 20,
          });

          Animated.timing(helperCircleOpacity, {
            toValue: 1,
            duration: 300,
            delay: 2600,
            useNativeDriver: false,
          }).start(() => {
            Animated.timing(helperCircleOpacity, {
              toValue: 0,
              duration: 500,
              delay: 2800,
              useNativeDriver: false,
            }).start();
          });
          Animated.timing(
            helperCirclePan, // Auto-multiplexed
            {
              toValue: {
                x: gestureState.x0 - 90,
                y: gestureState.y0 + 130,
              },
              duration: 1800,
              delay: 3000,
              useNativeDriver: false,
            },
          ).start();

          showAlert(
            // 'You can add new reservation by dragging\n  + Reservation button to timeline.',
            'To add  a reservation, drag the + button to the timeline.',
            'info',
          );
        }
      },
      onShouldBlockNativeResponder: (e, gestureState) => {
        console.log('should block native');
        return true;
      },

      onPanResponderMove: (evt, gestureState) => {
        const { iconOpacity, boxOpacity, pan } = this.state;
        pan.setValue({
          x: gestureState.moveX,
          y: gestureState.moveY,
        });

        if (gestureState.dy < 60 && Math.abs(gestureState.dx) > 30) {
          Animated.timing(boxOpacity, {
            toValue: 0.5,
            duration: 30,
            useNativeDriver: false,
          }).start();
        } else {
          Animated.timing(boxOpacity, {
            toValue: 1,
            duration: 30,
            useNativeDriver: false,
          }).start();
        }
      },
      onPanResponderRelease: (evt, gestureState) => {
        setTimeout(() => {
          this.setState({ isBoxActive: false });
        }, 50);
        const {
          boxOpacity,
          pan,
          iconOpacity,
          offsets,
          buttonOpacity,
          boxScale,
        } = this.state;
        const { hourLength, rowHeight } = this.state;
        // const timelinePosition = {
        //   x: gestureState.moveX - offsets.x + hourLength * 0.75,
        //   y: gestureState.moveY - offsets.y + rowHeight / 2,
        // };
        const timelinePosition = {
          x: gestureState.moveX - offsets.x + hourLength * 0.3,
          y: gestureState.moveY - offsets.y + rowHeight * 0.4,
        };
        const timeUnit = hourLength / 4;
        const adjustToIntervalX =
          timelinePosition.x % timeUnit > timeUnit / 2
            ? timeUnit - (timelinePosition.x % timeUnit)
            : -timelinePosition.x % timeUnit;
        const adjustToIntervalY =
          timelinePosition.y % rowHeight > rowHeight / 2
            ? rowHeight - (timelinePosition.y % rowHeight)
            : -timelinePosition.y % rowHeight;

        const adjustedTimelinePosition = {
          x: timelinePosition.x + adjustToIntervalX - hourLength * 1.5,
          y: timelinePosition.y + adjustToIntervalY - rowHeight,
        };

        if (
          adjustedTimelinePosition.y >= 0 &&
          Math.abs(gestureState.vx) + Math.abs(gestureState.vy) < 1 &&
          gestureState.dy > 50
        ) {
          setTimeout(() => {
            // this.setState({ isBoxActive: false });
            this.showQuickAddModal(adjustedTimelinePosition);
          }, 350);
          Animated.timing(iconOpacity, {
            toValue: 0,
            duration: 80,
            useNativeDriver: true,
            delay: 0,
          }).start();
          const extraPaddingWebY = Platform.OS === 'web' ? -5 : 0;
          Animated.spring(
            pan, // Auto-multiplexed
            {
              toValue: {
                x: gestureState.moveX + adjustToIntervalX,
                y: gestureState.moveY + adjustToIntervalY + extraPaddingWebY,
              },
              // duration: 3000,
              speed: 30,
              useNativeDriver: false,
            },
          ).start();
          Animated.timing(buttonOpacity, {
            toValue: 1,
            duration: 400,
            useNativeDriver: true,
            delay: 1500,
          }).start();
          // Animated.timing(boxOpacity, {
          //   toValue: 1,
          //   duration: 300,
          //   useNativeDriver: false,
          //   delay: 800,
          // }).start();
        } else {
          Animated.timing(buttonOpacity, {
            toValue: 1,
            duration: 350,
            useNativeDriver: true,
            delay: 600,
          }).start();
          Animated.timing(boxOpacity, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false,
            delay: 0,
          }).start();
          Animated.timing(boxScale, {
            toValue: 0,
            duration: 30,
            useNativeDriver: false,
            delay: 500,
          }).start();
        }
      },
    });
  }

  _bootstrapAsync = async () => {
    const userToken = await AsyncStorage.getItem('jwtToken');
    if (!userToken) {
      const { logout } = this.props.global;
      logout();
    }
  };

  updateDimensions = ({ window, screen }) => {
    this.setState({
      isNarrow: window.width < 550,
      isPhone: window.width < 550 || window.height < 550,
    });
  };

  componentDidMount = async () => {
    const { restaurantId } = this.props.global;

    Dimensions.addEventListener('change', this.updateDimensions);

    if (restaurantId) {
      this.interval = setInterval(() => this.periodicTimeUpdate(), 1000);
    }
    const isPhone =
      Dimensions.get('window').width < 550 ||
      Dimensions.get('window').height < 550;
    if (isPhone) {
      this.updateHourLength(50);
      this.updateRowHeight(44);
    }

    this.setState({
      isNarrow: Dimensions.get('window').width < 550,
      isPhone,
      // isPhone: false,
    });
    this.scrollToCurrentTime();
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {
      scrolling,
      scrollingMomentum,
      offsets,
      isOptionsModalVisible,
      isQuickAddVisible,
      isCalendarVisible,
      isBoxActive,
    } = this.state;
    if (!isBoxActive && nextState.isBoxActive) {
      return false;
    }
    if (isBoxActive) {
      return false;
    }
    if (
      isOptionsModalVisible !== nextState.isOptionsModalVisible ||
      isQuickAddVisible !== nextState.isQuickAddVisible ||
      isCalendarVisible !== nextState.isCalendarVisible
    ) {
      return true;
    }
    return !(
      nextState.scrolling ||
      nextState.scrollingMomentum ||
      scrolling ||
      scrollingMomentum ||
      offsets !== nextState.offsets
    );
  }

  componentWillUnmount = () => {
    Dimensions.removeEventListener('change', this.updateDimensions);
    clearInterval(this.interval);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { restaurants, selectedDate } = this.props.global;
    if (prevProps.global.restaurants === null && restaurants) {
      if (restaurants.length === 1) {
        this.saveRestaurantId(restaurants[0].id);
      } else if (restaurants.length > 1) {
        this.setState({
          isSelectRestaurantVisible: true,
        });
      }
    }
    if (
      prevProps.global.selectedDate !== selectedDate &&
      selectedDate.isSame(new Date(), 'date')
    ) {
      this.scrollToCurrentTime();
    }
  };

  showNewReservationBox = event => {
    const { boxOpacity, boxScale, pan } = this.state;
    boxOpacity.setValue(0);
    boxScale.setValue(0);
    Animated.timing(boxOpacity, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
      delay: 0,
    }).start();

    Animated.timing(boxScale, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
      // speed: 6,
    }).start();
    pan.setValue({
      x: event.nativeEvent.pageX,
      y: event.nativeEvent.pageY,
    });
    // pan.setOffset({
    //   x: -this.state.hourLength * 0.75,
    //   y: -this.state.rowHeight / 2,
    // });
    pan.setOffset({
      x: -this.state.hourLength * 1.2,
      y: -this.state.rowHeight * 0.6,
    });

    this.setState({
      isBoxActive: true,
    });
  };

  periodicTimeUpdate = () => {
    const { time } = this.state;
    const currentTime = moment(new Date());
    // if (parseInt(currentTime.format('ss')) % 10 === 0) {
    //   this.getReservations(true, false);
    // }
    if (time.format('HH:mm') !== currentTime.format('HH:mm')) {
      this.setState({ time: currentTime });
    }
  };

  showQuickAddModal = position => {
    const { tables, selectedDate, firstHour } = this.props.global;
    // const { firstHour } = this.state;
    const startDateTime = selectedDate
      .clone()
      .set({ hours: firstHour, minutes: 0 })
      .set({ second: 0, millisecond: 0 });
    const minutesFromStart = (position.x / this.state.hourLength) * 60;

    startDateTime.add(minutesFromStart, 'minutes');
    const tableIndex = position.y / this.state.rowHeight;
    const table = tables?.[tableIndex];
    if (table) {
      const quickAddParameters = {
        persons: 2,
        table,
        date: startDateTime,
        duration: moment.duration('02:30'),
      };

      this.setState({
        isQuickAddVisible: true,
        quickAddParameters,
      });
    } else {
      this.hideQuickAddModal(false);
    }
  };

  hideQuickAddModal = reload => {
    this.setState({ isQuickAddVisible: false });
    const { boxOpacity, boxScale } = this.state;
    const { selectedDate, getReservations } = this.props.global;
    getReservations(selectedDate?.format('YYYY-MM-DD'), reload);
    Animated.timing(boxOpacity, {
      toValue: 0,
      duration: reload ? 200 : 200,
      useNativeDriver: false,
    }).start();
    Animated.timing(boxScale, {
      toValue: 0,
      duration: reload ? 200 : 200,
      useNativeDriver: false,
    }).start();
    // this.getReservations(reload, !reload);
  };

  hideConfirmEditsModal = () => {
    this.setState({ isConfirmEditsModalVisible: false });
  };

  scrollToCurrentTime = () => {
    const { tableColumnWidth } = this.state;
    const { firstHour } = this.props.global;
    const currentDate = moment(new Date());
    const hourStart = moment(new Date().setHours(firstHour, 0));
    const offsetTime = currentDate.diff(hourStart, 'minutes');
    const screenWidth = Dimensions.get('window').width;
    const { hourLength } = this.state;
    const offsetMargin =
      (offsetTime * hourLength) / 60 - screenWidth / 2 + tableColumnWidth;
    const { selectedDate } = this.props.global;
    if (selectedDate.format('MM-DD') === currentDate.format('MM-DD')) {
      setTimeout(() => {
        this.timelineScroll.current.scrollTo({
          x: offsetMargin,
          animated: true,
        });
      }, 400);
    }
  };

  getInitialData = force => {
    console.log('lol');
  };

  plusMinusDate = action => {
    const { selectedDate } = this.props.global;
    let changeValue = 0;
    if (action === 'add') {
      changeValue = 1;
    } else if (action === 'subtract') {
      changeValue = -1;
    }

    const date = moment(selectedDate.clone().add(changeValue, 'days')).format(
      'YYYY-MM-DD',
    );
    this.props.global.updateSelectedDate(date);
  };

  changeSelectedDate = date => {
    this.hideCalendarModal();
    this.props.global.updateSelectedDate(date);
  };

  showReservationDetailsModal = reservationObject => {
    this.setState({
      selectedReservation: reservationObject,
      isReservationDetailsVisible: true,
    });
  };

  hideReservationDetailsModal = () => {
    this.setState({ isReservationDetailsVisible: false });
  };

  hideSelectRestaurantsModal = () => {
    this.setState({ isSelectRestaurantVisible: false });
  };

  hideOptionsModal = () => {
    this.setState({ isOptionsModalVisible: false });
  };

  saveRestaurantId = id => {
    const idString = id.toString();
    deviceStorage
      .saveKey('restaurantId', idString)
      .then(() => this.getInitialData(true));
    this.props.global.changeRestaurantId(id);
  };

  showCalendarModal = () => {
    this.setState({ isCalendarVisible: true });
  };

  hideCalendarModal = () => {
    this.setState({ isCalendarVisible: false });
  };

  showNewReservationModal = () => {
    this.setState({ isNewReservationVisible: true });
  };

  hideNewReservationModal = force => {
    this.setState({ isNewReservationVisible: false });
    // this.getReservations(force, !force);
  };

  setTablesColumnWidth = size => {
    this.setState({
      tableColumnWidth: size,
    });
  };

  updateHourLength = value => {
    this.setState({ hourLength: value });
    if (value < 85) {
      this.setTablesColumnWidth(TABLE_COLUMN_WIDTH_SMALL);
    } else {
      this.setTablesColumnWidth(TABLE_COLUMN_WIDTH);
    }
  };

  updateRowHeight = value => {
    this.setState({ rowHeight: value });
  };

  hideCancelledSwitch = () => {
    const { hideCancelled } = this.state;
    this.setState({
      hideCancelled: !hideCancelled,
    });
  };

  isPhoneAndPortrait = () => {
    const { height, width } = Dimensions.get('window');
    const { isNarrow } = this.state;
    return isNarrow && height > width;
  };

  setEditingReservations = state => {
    this.setState({
      editingReservations: state,
    });
  };

  onReservationEdit = (reservation, dateFrom, dateTo, updatedTables) => {
    const { reservationEdits } = this.state;
    /*
    reservationEdits = [
      {
        reservationId: 10,
        reservation: [reservationObject]
        updatedDateFrom: 2021-10-10T12:00:00
        updatedDateTo: 2021-10-10T14:00:00
        updatedTables: [
          {
            prevTable: [table],
            updatedTable: [table] 
          }
        ]
      }
    ]
    */

    const existingEditIndex = reservationEdits.findIndex(
      res => res.reservationId === reservation.id,
    );
    if (existingEditIndex === -1) {
      const edit = {
        reservationId: reservation.id,
        reservation,
        updatedDateFrom: dateFrom,
        updatedDateTo: dateTo,
        updatedTables,
      };
      this.setState({
        reservationEdits: [...reservationEdits, edit],
      });
    } else {
      const existingEdit = reservationEdits[existingEditIndex];
      existingEdit.updatedDateFrom = dateFrom || existingEdit.updatedDateFrom;
      existingEdit.updatedDateTo = dateTo || existingEdit.updatedDateTo;
      existingEdit.updatedTables = updatedTables || existingEdit.updatedTables;
      const updatedReservationEdits = Object.assign([...reservationEdits], {
        [existingEditIndex]: existingEdit,
      });
      this.setState({
        reservationEdits: updatedReservationEdits,
      });
    }
  };

  onDoneEditing = () => {
    const { reservationEdits } = this.state;
    if (reservationEdits.length > 0) {
      this.setState({ isConfirmEditsModalVisible: true });
    } else {
      this.onCancelEditing();
    }
  };

  onCancelEditing = () => {
    this.setEditingReservations(false);
    this.setState({ isConfirmEditsModalVisible: false });
    this.setState({ reservationEdits: [] });
  };

  render() {
    const {
      selectedReservation,
      isNewReservationVisible,
      isCalendarVisible,
      isReservationDetailsVisible,
      isSelectRestaurantVisible,
      isOptionsModalVisible,
      hideCancelled,
      hourLength,
      rowHeight,
      pan,
      iconOpacity,
      quickAddParameters,
      isQuickAddVisible,
      isConfirmEditsModalVisible,
      boxOpacity,
      boxScale,
      tableColumnWidth,
      isNarrow,
      isPhone,
      helperCirclePan,
      helperCircleOpacity,
      editingReservations,
      reservationEdits,
    } = this.state;

    const {
      tables,
      reservations,
      jwtToken,
      restaurantId,
      selectedDate,
      restaurants,
      firstHour,
      lastHour,
      showAlert,
    } = this.props.global;

    const panStyle = {
      transform: pan.getTranslateTransform(),
    };
    const date = selectedDate.format('YYYY-MM-DD');

    return (
      <SafeAreaView style={TimelineHeadStyle.mainContainer} edges={['top']}>
        <View
          style={[
            TimelineHeadStyle.container,
            {
              flex: 2,
            },
          ]}
        >
          <View
            style={[
              TimelineHeadStyle.topBarContainer,
              isPhone && TimelineHeadStyle.topBarContainerSmall,
              this.isPhoneAndPortrait() &&
                TimelineHeadStyle.topBarContainerNotch,
            ]}
          >
            <View
              style={[
                TimelineHeadStyle.topBarSpace,
                this.isPhoneAndPortrait() && TimelineHeadStyle.topBarSpaceFixed,
                { justifyContent: 'flex-start', flexDirection: 'row' },
              ]}
            >
              {editingReservations ? (
                <HeaderButton
                  small={this.isPhoneAndPortrait()}
                  label={'Cancel editing'}
                  icon={'close-circle'}
                  backgroundColor={'white'}
                  color={Colors.light}
                  iconSize={24}
                  onPress={() => this.onCancelEditing()}
                />
              ) : (
                <View style={TimelineHeadStyle.logoContainer}>
                  <Image
                    style={[
                      TimelineHeadStyle.logo,
                      isPhone && TimelineHeadStyle.logoSmall,
                      isNarrow && TimelineHeadStyle.logoSquare,
                    ]}
                    source={
                      this.isPhoneAndPortrait()
                        ? require('../../assets/images/reetab_logo.png')
                        : require('../../assets/images/reetab.png')
                    }
                    resizeMode={'contain'}
                  />
                </View>
              )}
            </View>
            <View style={[TimelineHeadStyle.topBarSpace]}>
              <DateSelectItem
                selectedDate={selectedDate}
                changeSelectedDate={this.changeSelectedDate}
                plusMinusDate={this.plusMinusDate}
                showCalendarModal={this.showCalendarModal}
                size={isPhone || isNarrow ? 'small' : 'big'}
              />
            </View>
            <View
              style={[
                TimelineHeadStyle.topBarSpace,
                this.isPhoneAndPortrait() && TimelineHeadStyle.topBarSpaceFixed,
                {
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                },
              ]}
            >
              {editingReservations ? (
                <HeaderButton
                  small={this.isPhoneAndPortrait()}
                  label={'Done editing'}
                  icon={'checkmark'}
                  backgroundColor={Colors.lightBlue}
                  onPress={() => this.onDoneEditing()}
                  iconSize={22}
                  shadow
                />
              ) : (
                <Animated.View
                  ref={this.newReservationButtonRef}
                  onLayout={({ nativeEvent }) => {
                    if (this.newReservationButtonRef?.current) {
                      this.newReservationButtonRef.current.measure(() => {});
                    }
                  }}
                  style={{
                    opacity: this.state.buttonOpacity,
                  }}
                  {...this._panResponder.panHandlers}
                >
                  <HeaderButton
                    small={this.isPhoneAndPortrait()}
                    label={'Reservation'}
                    icon={'add-outline'}
                    shadow
                  />
                </Animated.View>
              )}
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View
              style={{
                height: 30,
                flexDirection: 'row',
                borderColor: '#ccc',
                borderBottomWidth: 0.4,
                zIndex: 1,
              }}
            >
              <TouchableOpacity
                onPress={() => this.setState({ isOptionsModalVisible: true })}
                style={{
                  backgroundColor: 'white',
                  width: tableColumnWidth,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    marginLeft:
                      tableColumnWidth === TABLE_COLUMN_WIDTH_SMALL ? 0 : 8,
                    marginTop: 4,
                  }}
                >
                  {downArrow}
                </Text>
                {/*<Text*/}
                {/*  style={{ fontWeight: '500', fontSize: 14, color: '#3273dc' }}*/}
                {/*>*/}
                {/*  {tableColumnWidth === TABLE_COLUMN_WIDTH_SMALL*/}
                {/*    ? ''*/}
                {/*    : 'Options'}*/}
                {/*</Text>*/}
              </TouchableOpacity>
              <View
                style={{
                  flex: 12,
                  zIndex: 4,
                  marginBottom: -2000,
                }}
                pointerEvents="none"
              >
                <ScrollView
                  // scrollEventThrottle={16}
                  horizontal
                  ref={this.timelineHeaderScroll}
                  scrollEnabled={false}
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{
                    flexGrow: 1,
                    height: 30,
                    zIndex: 5,
                    overflow: 'visible',
                    elevation: 50,
                  }}
                >
                  <DrawHours
                    rowLength={hourLength}
                    startTime={firstHour}
                    endTime={lastHour}
                  />
                  <CurrentTimeIndicator
                    position="top"
                    firstHour={firstHour}
                    hourLength={hourLength}
                    selectedDate={selectedDate}
                    color={'rgba(48,209,88,1)'}
                    lineColor={'rgba(48,209,88,0.7)'}
                  />
                </ScrollView>
              </View>
            </View>

            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{ flexGrow: 1 }}
              bounces={false}
              snapToInterval={rowHeight}
              showsVerticalScrollIndicator
              decelerationRate="fast"
              onMomentumScrollBegin={() =>
                this.setState({ scrollingMomentum: true })
              }
              onScrollBeginDrag={() => this.setState({ scrolling: true })}
              onMomentumScrollEnd={() =>
                this.setState({ scrollingMomentum: false })
              }
              onScrollEndDrag={() => this.setState({ scrolling: false })}
              StickyHeaderComponent={
                <View
                  style={{
                    width: 30,
                    height: 30,
                    backgroundColor: 'red',
                    zIndex: 80,
                  }}
                />
              }
            >
              <View style={{ flexDirection: 'row', flex: 1 }}>
                <View
                  style={{
                    // width: tableColumnWidth,
                    backgroundColor: '#f8f8f8',
                    // paddingLeft: 30,
                  }}
                >
                  <DrawTables
                    rowHeight={rowHeight}
                    tables={tables}
                    width={tableColumnWidth}
                    // extraPaddingLeft={this.isPhoneAndHorizontal() ? 40 : 10}
                  />
                </View>

                <View style={{ flex: 1 }}>
                  <ScrollView
                    ref={this.timelineScroll}
                    scrollEventThrottle={16}
                    onScroll={e =>
                      this.timelineHeaderScroll.current.scrollTo({
                        x: e.nativeEvent.contentOffset.x,
                        animated: false,
                      })
                    }
                    showsHorizontalScrollIndicator
                    horizontal
                    onMomentumScrollBegin={() =>
                      this.setState({ scrollingMomentum: true })
                    }
                    onScrollBeginDrag={() => this.setState({ scrolling: true })}
                    onMomentumScrollEnd={() =>
                      this.setState({ scrollingMomentum: false })
                    }
                    onScrollEndDrag={() => this.setState({ scrolling: false })}
                    bounces={false}
                    contentContainerStyle={{
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <View
                      // ref={ref => {
                      //   this.marker = ref;
                      // }}
                      ref={this.viewMarker}
                      onLayout={({ nativeEvent }) => {
                        if (this.viewMarker?.current) {
                          this.viewMarker?.current?.measure(() => {});
                        }
                      }}
                      style={{ flex: 0, opacity: 1 }}
                    />
                    <DrawGrid
                      rowHeight={rowHeight}
                      rowLength={hourLength}
                      rows={tables ? tables.length : 0}
                      columns={
                        lastHour - firstHour >= 0
                          ? lastHour - firstHour
                          : lastHour + 24 - firstHour
                      }
                    />
                    {reservations[date] &&
                      reservations[date]?.reservations?.map(reservation => (
                        <DrawReservation
                          key={`${reservation.id}${firstHour}${lastHour}${reservation.reservation_date}${reservation.reservation_date_end}`}
                          reservation={reservation}
                          tables={tables}
                          firstHour={firstHour}
                          lastHour={lastHour}
                          hourLength={hourLength}
                          rowHeight={rowHeight}
                          showReservationDetailsModal={
                            this.showReservationDetailsModal
                          }
                          hideCancelled={hideCancelled}
                          editingReservations={editingReservations}
                          setEditingReservations={this.setEditingReservations}
                          onReservationEdit={(dateFrom, dateTo, editedTables) =>
                            this.onReservationEdit(
                              reservation,
                              dateFrom,
                              dateTo,
                              editedTables,
                            )
                          }
                        />
                      ))}
                  </ScrollView>
                </View>
              </View>
            </ScrollView>
          </View>
          <CalendarModal
            size={isNarrow || isPhone ? 'small' : 'big'}
            isVisible={isCalendarVisible}
            onBackDropPress={this.hideCalendarModal}
            selectedDate={selectedDate}
            changeSelectedDate={dt => this.changeSelectedDate(dt)}
          />

          <ReservationOverviewModal
            isVisible={isReservationDetailsVisible}
            onBackDropPress={this.hideReservationDetailsModal}
            onSwipeComplete={this.hideReservationDetailsModal}
            selectedReservation={selectedReservation}
            fullScreen={isNarrow}
          />

          <SelectRestaurantModal
            isVisible={isSelectRestaurantVisible}
            restaurants={restaurants || []}
            closeModal={this.hideSelectRestaurantsModal}
            saveId={this.saveRestaurantId}
          />

          <OptionsModal
            hideModal={this.hideOptionsModal}
            isVisible={isOptionsModalVisible}
            hourLength={hourLength}
            rowHeight={rowHeight}
            updateHourLength={this.updateHourLength}
            updateRowHeight={this.updateRowHeight}
            hideCancelledSwitch={this.hideCancelledSwitch}
            hideCancelled={hideCancelled}
            portrait={this.isPhoneAndPortrait()}
          />
        </View>
        <Animated.View
          style={[
            panStyle,
            {
              position: 'absolute',
              opacity: boxOpacity,
              width: hourLength * 1.5,
              height: rowHeight,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 20,
            },
          ]}
          pointerEvents="none"
        >
          <Animated.View
            pointerEvents="none"
            style={[
              {
                marginTop: 2,
                // marginLeft: 1.5,
                margin: 1.5,
                width: boxScale.interpolate({
                  inputRange: [0, 1],
                  outputRange: [80, hourLength * 1.5],
                }),
                height: boxScale.interpolate({
                  inputRange: [0, 1],
                  outputRange: [36, rowHeight],
                }),
                backgroundColor: Colors.someGreen,
                flexDirection: 'row',
                paddingHorizontal: 12,
                alignItems: 'center',
                borderRadius: 3,
                // justifyContent:"center"
              },
            ]}
          >
            <Text
              selectable={false}
              style={[
                Buttons.buttonIcon,
                { marginRight: this.isPhoneAndPortrait() ? 0 : 0 },
              ]}
            >
              <Ionicons name="add" size={22} />
            </Text>
            <Text
              style={[Buttons.buttonText, {}]}
              selectable={false}
              numberOfLines={1}
              adjustsFontSizeToFit
            >
              {hourLength < 80 ? '' : ' Reservation '}
            </Text>
            {/*<SingleReservation*/}
            {/*  fullname="New reservation"*/}
            {/*  boxColor="hsla(135, 64%, 50%, 0.75)"*/}
            {/*  textColor="hsla(135, 64%, 20%, 0.75)"*/}
            {/*  iconColor="hsla(135, 100%, 20%, 0.75)"*/}
            {/*  showIcon*/}
            {/*  iconOpacity={iconOpacity}*/}
            {/*/>*/}
          </Animated.View>
        </Animated.View>
        <QuickAddReservation
          isVisible={isQuickAddVisible}
          hideModal={this.hideQuickAddModal}
          params={quickAddParameters}
          jwtToken={jwtToken}
          restaurantId={restaurantId}
          fullScreen={isNarrow}
          showAlert={showAlert}
        />
        <ConfirmEditsModal
          isVisible={isConfirmEditsModalVisible}
          hideModal={this.hideConfirmEditsModal}
          edits={reservationEdits}
          onCancel={this.onCancelEditing}
        />
        {isNewReservationVisible || Platform.OS === 'ios' ? (
          <NewReservationModal
            isVisible={isNewReservationVisible}
            closeMe={this.hideNewReservationModal}
            selectedDate={selectedDate}
            restaurantId={restaurantId}
            jwtToken={jwtToken}
          />
        ) : null}
        <Animated.View
          pointerEvents={'none'}
          style={[
            {
              position: 'absolute',
              opacity: helperCircleOpacity,
              transform: helperCirclePan.getTranslateTransform(),
              height: 40,
              width: 40,
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: 100,
              zIndex: 200,
            },
          ]}
        />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({});

const Timeline2 = withGlobalContext(Timeline);

Timeline2.navigationOptions = {
  headerShown: false,
  // headerTitle: () => <View />,
  //   // headerStyle: {
  //   //   shadowRadius: 0,
  //   //   height: null,
  //   //   shadowOffset: {
  //   //     height: 0,
  //   //   },
  //   // },
};
export default Timeline2;
