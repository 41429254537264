import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';

const Button = ({
  onPress,
  color,
  text,
  active,
  style,
  textColor,
  inactiveComponent,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      opacity={active ? 1 : 0.3}
      disabled={!active}
      style={[
        {
          backgroundColor: color,
          paddingVertical: 8,
          paddingHorizontal: 26,
          borderRadius: 4,
          alignSelf: 'flex-start',
          alignItems: 'center',
          opacity: active ? 1 : 0.3,
        },
        style,
      ]}
    >
      {!active && inactiveComponent}
      {active && (
        <Text
          style={{
            color: textColor || Colors.white,
            fontSize: 14,
            fontWeight: '500',
          }}
        >
          {text}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export default Button;
