import React from 'react';
import {
  Animated,
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Picker,
} from 'react-native';

import Modal from 'react-native-modal';

// import Modal from 'modal-enhanced-react-native-web';

import moment from 'moment';
import { Ionicons } from '@expo/vector-icons';
// import Collapsible from 'react-native-collapsible';

import DateTimePicker from 'react-native-modal-datetime-picker';

import CalendarDays from 'react-native-calendar-slider-carousel';
import CountIncreaser from '../../../components/CountIncreaser';
import AvailableTables from '../../../components/timelineComponents/AvailableTables';
import TimePicker from '../../../components/timelineComponents/TimePicker';
import ConfirmReservation from '../../../components/timelineComponents/ConfirmReservation';

import ReservationServices from '../../../services/ReservationsServices';
import Colors from '../../../constants/Colors';
import Collapsible from '../../../components/Collapsible';

const WIDTH_PERCENT = 0.39;
const DISABLED_DATES = ['2019-07-08', '2019-07-10'];

export default class NewReservationModal extends React.Component {
  loadAvailableTables = ReservationServices.getAvailableTables.bind(this);

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isExpanding: false,

      renderTables: false,
      availableTables: null,
      loadingAvailableTables: false,

      dateSelectCollapsed: true,
      timePickerCollapsed: true,
      isTimePickerModalVisible: false,
      showAvailableTables: false,

      selectedDate: props.selectedDate,
      selectedTime: '12:00',
      selectedDuration: moment.duration('02:00'),
      selectedPersons: 2,

      tableId: null,

      modalWidth: new Animated.Value(
        Dimensions.get('window').width * WIDTH_PERCENT,
      ),
      selectOptionsWidth: new Animated.Value(
        Dimensions.get('window').width * WIDTH_PERCENT,
      ),
      confirmReservationWidth: new Animated.Value(0),
      confirmReservationOpacity: new Animated.Value(0),
      backButtonOpacity: new Animated.Value(0),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isVisible !== state.isVisible) {
      if (props.isVisible) {
        return {
          isVisible: props.isVisible,
          isExpanding: false,
          dateSelectCollapsed: true,
          timePickerCollapsed: true,
          isTimePickerModalVisible: false,
          showAvailableTables: false,
          renderTables: false,

          selectedDate: props.selectedDate,
          selectedTime: '12:00',
          selectedDuration: moment.duration('02:00'),
          selectedPersons: 2,

          modalWidth: new Animated.Value(
            Dimensions.get('window').width * WIDTH_PERCENT,
          ),
          selectOptionsWidth: new Animated.Value(
            Dimensions.get('window').width * WIDTH_PERCENT,
          ),
          confirmReservationWidth: new Animated.Value(0),
          confirmReservationOpacity: new Animated.Value(0),
          backButtonOpacity: new Animated.Value(0),
        };
      }
      return {
        isVisible: props.isVisible,
      };
    }
    return null;
  }

  getAvailableTables = () => {
    const {
      selectedDate,
      selectedTime,
      selectedDuration,
      selectedPersons,
    } = this.state;

    const { restaurantId, jwtToken } = this.props;

    const dateFrom = moment.utc(
      `${selectedDate.format('YYYY-MM-DD')} ${selectedTime}`,
      'YYYY-MM-DD HH:mm',
    );
    const dateTo = dateFrom.clone().add(selectedDuration);

    this.setState({
      dateFrom,
      dateTo,
    });

    const parameters = {
      restaurantId,
      jwtToken,
      persons: selectedPersons,
      date_from: dateFrom.toISOString(false),
      date_to: dateTo.toISOString(false),
    };

    if (jwtToken && restaurantId) {
      this.setState({
        loadingAvailableTables: true,
      });
      this.loadAvailableTables(jwtToken, parameters).catch(err =>
        console.log(err),
      );
    } else {
    }
  };

  closeMe = force => {
    const { closeMe } = this.props;
    closeMe(force);
  };

  downArrow = () => {
    const name = 'ios-arrow-down';
    return (
      <Ionicons
        name={name}
        size={18}
        color={Colors.lightBlue}
        style={{ marginTop: Platform.OS === 'ios' ? 5 : 0, marginLeft: 16 }}
      />
    );
  };

  changeSelectedDate = date => {
    this.setState({
      dateSelectCollapsed: true,
      selectedDate: moment.utc(date, 'YYYY-MM-DD'),
    });
    console.log(moment.utc(date, 'YYYY-MM-DD').format());
    this.contractModal();
  };

  changeSelectedTime = time => {
    this.setState({
      selectedTime: time,
    });
  };

  showTimePickerModal = () => {
    const { timePickerCollapsed } = this.state;
    this.contractModal();

    if (Platform.OS === 'ios' && timePickerCollapsed) {
      this.setState({ timePickerCollapsed: false });
    } else if (Platform.OS === 'ios' && !timePickerCollapsed) {
      this.setState({ timePickerCollapsed: true });
    } else {
      this.setState({ isTimePickerModalVisible: true });
    }
  };

  hideTimePickerModal = () => {
    this.setState({ isTimePickerModalVisible: false });
  };

  updatePersonsCount = value => {
    this.setState({ selectedPersons: value });
    this.contractModal();
  };

  updateDurationValue = value => {
    this.setState({ selectedDuration: value });
    this.contractModal();
  };

  showDetails = tableId => {
    const {
      confirmReservationWidth,
      selectOptionsWidth,
      backButtonOpacity,
      confirmReservationOpacity,
    } = this.state;

    this.setState({ tableId });

    Animated.timing(confirmReservationWidth, {
      useNativeDriver: false,
      toValue: Dimensions.get('window').width * WIDTH_PERCENT,
      duration: 500,
    }).start();
    Animated.timing(confirmReservationOpacity, {
      useNativeDriver: false,
      toValue: 1,
      duration: 600,
      delay: 400,
    }).start();
    Animated.timing(selectOptionsWidth, {
      useNativeDriver: false,
      toValue: 0,
      duration: 500,
    }).start();
    Animated.timing(backButtonOpacity, {
      useNativeDriver: false,
      toValue: 1,
      duration: 500,
    }).start();
  };

  hideDetails = () => {
    const {
      confirmReservationWidth,
      selectOptionsWidth,
      backButtonOpacity,
      confirmReservationOpacity,
    } = this.state;

    Animated.timing(selectOptionsWidth, {
      useNativeDriver: false,
      toValue: Dimensions.get('window').width * WIDTH_PERCENT,
      duration: 200,
    }).start();
    Animated.timing(confirmReservationOpacity, {
      useNativeDriver: false,
      toValue: 0,
      duration: 40,
      delay: 0,
    }).start();
    Animated.timing(confirmReservationWidth, {
      useNativeDriver: false,
      toValue: 0,
      duration: 200,
      delay: 0,
    }).start();
    Animated.timing(backButtonOpacity, {
      useNativeDriver: false,
      toValue: 0,
      duration: 150,
    }).start();
  };

  handleTimePicked = date => {
    const hours = date.getHours();
    const minutes = Math.floor(date.getMinutes() / 15) * 15;
    const d = new Date();
    d.setHours(hours, minutes);

    this.setState({
      selectedTime: moment(d).format('HH:mm'),
    });

    this.hideTimePickerModal();
    this.contractModal();
  };

  // Show Free Tables
  expandModal = () => {
    this.getAvailableTables();

    const { modalWidth } = this.state;
    Animated.timing(modalWidth, {
      useNativeDriver: false,
      toValue: Dimensions.get('window').width * WIDTH_PERCENT * 2,
      duration: 700,
    }).start();

    this.setState({
      timePickerCollapsed: true,
      dateSelectCollapsed: true,
      showAvailableTables: true,
      isExpanding: true,
    });

    setTimeout(() => {
      this.setState({ renderTables: true, isExpanding: false });
    }, 800);
  };

  contractModal = () => {
    const { modalWidth } = this.state;
    Animated.timing(modalWidth, {
      useNativeDriver: false,
      toValue: Dimensions.get('window').width * WIDTH_PERCENT,
      duration: 300,
      delay: 10,
    }).start();

    setTimeout(() => {
      this.setState({ renderTables: false });
    }, 0);
  };

  showDates = () => {
    this.setState({ dateSelectCollapsed: false });
    this.contractModal();
  };

  render() {
    const durationOptions = [];
    const today = moment(new Date());

    const personsOptions = [];
    for (let i = 0; i < 50; i += 1) {
      personsOptions[i] = {
        label: i + 1,
        key: i + 1,
        value: i + 1,
      };
    }

    for (let i = 3; i < 20; i += 1) {
      const hours = Math.floor(i / 4);
      const minutes = (i % 4) * 15;
      const minutesString = minutes === 0 ? '00' : minutes;
      const time = `${hours}:${minutesString}`;

      durationOptions[i - 3] = {
        key: i - 3,
        label: time,
        value: moment.duration(15 * i, 'minutes'),
      };
    }

    const {
      modalWidth,
      backButtonOpacity,
      selectOptionsWidth,
      dateSelectCollapsed,
      isTimePickerModalVisible,
      timePickerCollapsed,
      selectedDate,
      selectedTime,
      selectedPersons,
      selectedDuration,
      loadingAvailableTables,
      isExpanding,
      availableTables,
      tableId,
      renderTables,
      confirmReservationWidth,
      confirmReservationOpacity,
      dateFrom,
      dateTo,
    } = this.state;

    const { isVisible, jwtToken, restaurantId } = this.props;

    const durationMinutes = selectedDuration.asMinutes();
    const endTime = moment
      .utc(selectedTime, 'HH:mm')
      .add(durationMinutes, 'minutes')
      .format('HH:mm');

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={500}
        onBackdropPress={this.closeMe}
        animationOut="fadeOut"
        animationOutTiming={500}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <Animated.View
          style={{
            width: modalWidth,
            height: Platform.OS === 'ios' ? '100%' : '110%',
            backgroundColor: '#fff',
            borderRadius: 8,
            alignSelf: 'center',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: 80,
            }}
          >
            <Animated.View
              style={{
                width: 80,
                height: '100%',
                opacity: backButtonOpacity,
              }}
            >
              <TouchableOpacity
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => this.hideDetails()}
              >
                <Ionicons name="ios-arrow-back" size={30} color="#555" />
              </TouchableOpacity>
            </Animated.View>
            <Text
              style={{
                alignSelf: 'center',
                fontSize: 22,
                fontWeight: '500',
                color: '#444',
                marginTop: 20,
                marginBottom: 25,
              }}
            >
              New Reservation
            </Text>
            <TouchableOpacity
              style={{
                width: 80,
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => this.closeMe()}
            >
              <Ionicons name="md-close" size={29} color="#ccc" />
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <Animated.View
              style={{
                width: selectOptionsWidth,
                marginTop: 0,
              }}
            >
              <View
                style={{
                  paddingHorizontal: 25,
                  width: Dimensions.get('window').width * WIDTH_PERCENT,
                }}
              >
                <View style={styles.itemSelect}>
                  <View style={styles.itemSelectText}>
                    <Text style={styles.fontLeft}>Date</Text>
                    <TouchableOpacity
                      style={styles.touchableSelect}
                      onPress={() => this.showDates()}
                    >
                      <Text style={styles.fontRight}>
                        {selectedDate
                          ? selectedDate.calendar(null, {
                              sameDay: '[Today]',
                              nextDay: 'DD. MMMM YYYY',
                              nextWeek: 'DD. MMMM YYYY',
                              lastDay: 'DD. MMMM YYYY',
                              lastWeek: 'DD. MMMM YYYY',
                              sameElse: 'DD. MMMM YYYY',
                            })
                          : null}
                      </Text>
                      <this.downArrow />
                    </TouchableOpacity>
                  </View>
                  <Collapsible
                    collapsed={dateSelectCollapsed}
                    duration={500}
                    collapsedHeight={0}
                  >
                    <CalendarDays
                      firstDate={today.format('YYYY-MM-DD')}
                      // lastDate={"2019-07-20"}
                      numberOfDays={30}
                      selectedDate={selectedDate.format('YYYY-MM-DD')}
                      disabledText="closed"
                      // width={380}
                      paginate={false}
                      disabledDates={DISABLED_DATES}
                      onDateSelect={date => this.changeSelectedDate(date)}
                      style={{ paddingTop: 2 }}
                      showArrows={Platform.OS === 'web'}
                      // showArrows
                      leftArrow={<Ionicons name="ios-arrow-back" size={26} color="#555" />}
                      rightArrow={<Ionicons name="ios-arrow-forward" size={26} color="#555" />}
                    />
                  </Collapsible>
                </View>
                <View style={styles.itemSelect}>
                  <View style={styles.itemSelectText}>
                    <Text style={styles.fontLeft}>Time</Text>
                    <TouchableOpacity
                      style={styles.touchableSelect}
                      onPress={() => this.showTimePickerModal()}
                    >
                      <Text style={styles.fontRight}>{selectedTime}</Text>
                      <this.downArrow />
                    </TouchableOpacity>
                  </View>

                  <Collapsible
                    collapsed={timePickerCollapsed}
                    duration={500}
                    collapsedHeight={0}
                  >
                    <TimePicker
                      updateTime={this.changeSelectedTime}
                      selectedTime={selectedTime}
                    />
                  </Collapsible>

                  <DateTimePicker
                    isVisible={isTimePickerModalVisible}
                    onConfirm={this.handleTimePicked}
                    onCancel={this.hideTimePickerModal}
                    mode="time"
                    date={moment(selectedTime, 'HH:mm').toDate()}
                    minuteInterval={15}
                    // titleIOS="Select Time"
                    // timePickerModeAndroid="spinner"
                  />
                </View>
                <View style={styles.itemSelect}>
                  <View style={[styles.itemSelectText]}>
                    <Text style={styles.fontLeft}>Duration</Text>
                    <CountIncreaser
                      initialIndex={5}
                      optionsArray={durationOptions}
                      updateValueFunc={value => this.updateDurationValue(value)}
                    />
                  </View>
                </View>
                <View style={[styles.itemSelect, { borderBottomWidth: 0 }]}>
                  <View style={styles.itemSelectText}>
                    <Text style={styles.fontLeft}>Persons</Text>
                    <CountIncreaser
                      initialIndex={1}
                      optionsArray={personsOptions}
                      updateValueFunc={value => this.updatePersonsCount(value)}
                    />
                  </View>
                </View>

                <View
                  style={[
                    styles.itemSelect,
                    {
                      borderBottomWidth: 0,
                      justifyContent: 'center',
                      flexDirection: 'row',
                    },
                  ]}
                >
                  <TouchableOpacity
                    style={[styles.topBarItem, {}]}
                    onPress={() => this.expandModal()}
                  >
                    <Text style={[styles.buttonText, {}]}>
                      Show Free Tables
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Animated.View>
            <View
              style={{
                alignSelf: 'stretch',
                flex: 1,
                margin: 25,
                marginTop: 12,
                backgroundColor: 'white',
              }}
            >
              {this.state.showAvailableTables ? (
                <AvailableTables
                  loadingAvailableTables={loadingAvailableTables}
                  isExpanding={isExpanding}
                  showDetails={value => this.showDetails(value)}
                  availableTables={availableTables}
                  renderTables={renderTables}
                  time={selectedTime}
                  endTime={endTime}
                />
              ) : null}
            </View>
            <Animated.View
              style={{
                opacity: confirmReservationOpacity,
                width: confirmReservationWidth,
                marginTop: 0,
                height: 500,
              }}
            >
              <View style={{ paddingHorizontal: 25, flex: 1 }}>
                <ConfirmReservation
                  jwtToken={jwtToken}
                  restaurantId={restaurantId}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  persons={selectedPersons}
                  tableId={tableId}
                  closeModal={this.closeMe}
                />
              </View>
            </Animated.View>
          </View>
        </Animated.View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  touchableSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    paddingRight: 12,
    // backgroundColor: "red"
  },
  itemSelect: {
    borderColor: '#ddd',
    justifyContent: 'center',
    // backgroundColor: "blue"
    paddingHorizontal: 16,
  },
  itemSelectText: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingLeft: 12,
    height: 58,
  },
  topBarItem: {
    alignSelf: 'center',
    backgroundColor: Colors.lightBlue,
    height: 43,
    width: '102%',
    marginTop: 20,
    borderRadius: 4,
    // borderWidth: 1,
    borderColor: '#aaa',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    shadowOpacity: 0.15,
    shadowRadius: 4,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 4,
  },
  buttonText: {
    fontSize: 17,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
    paddingHorizontal: 18,
  },
  fontLeft: { fontSize: 18, fontWeight: '500', color: '#444' },
  fontRight: {
    fontSize: 17,
    fontWeight: '400',
    color: '#222',
    marginLeft: 20,
  },
});
