import MovableReservationContainer from './MovableReservationContainer';
import SingleReservation from './SingleReservation';
import Colors from '../../constants/Colors';
import React, { useEffect, useState } from 'react';
import { useSharedValue } from 'react-native-reanimated';
import {
  addMinutes,
  differenceInMinutes,
  format,
  parseISO,
  setHours,
  setMinutes,
} from 'date-fns';

const BREAKING_ROW_HEIGHT = 36;
const MINUTES_PER_UNIT = 15;

const reservationColor = reservation => {
  let boxColor = 'rgba(246,70,54,0.8)';
  if (reservation.manual_entry || reservation.created_by_restaurant) {
    boxColor = 'rgba(48, 209, 88, 0.70)';
  }
  if (reservation.created_by_non_registered_customer) {
    boxColor = 'rgba(255,166,69,0.83)';
  }

  if (reservation.walk_in) {
    boxColor = 'rgba(248,155,218,0.9)';
  }

  if (reservation?.tables?.length > 1) {
    boxColor = 'rgba(23,144,250, 0.50)';
  }
  if (reservation.cancelled) {
    boxColor = 'rgba(182,189,199,0.5)';
  }
  return boxColor;
};

const computeReservationDuration = (
  reservationStartDatetime,
  reservationEndDatetime,
) => {
  const from = reservationStartDatetime;
  const to = reservationEndDatetime;
  return differenceInMinutes(to, from);
};

const computeMinutesFromStart = (reservationStartDatetime, firstHour) => {
  const to = reservationStartDatetime;
  const from = setMinutes(setHours(to, firstHour), 0);
  return differenceInMinutes(to, from);
};

const DrawReservation = ({
  reservation,
  tables,
  firstHour,
  lastHour,
  hourLength,
  rowHeight,
  showReservationDetailsModal,
  hideCancelled,
  editingReservations,
  setEditingReservations,
  onReservationEdit,
}) => {
  const [reservationStartDatetime, setReservationStartDatetime] = useState(
    parseISO(reservation.reservation_date),
  );
  const [reservationEndDatetime, setReservationEndDatetime] = useState(
    parseISO(reservation.reservation_date_end),
  );
  const [durationMinutes, setDurationMinutes] = useState(
    computeReservationDuration(
      reservationStartDatetime,
      reservationEndDatetime,
    ),
  );
  const [minutesFromFirstHour, setMinutesFromFirstHour] = useState(
    computeMinutesFromStart(reservationStartDatetime, firstHour),
  );

  const [updatedTables, setUpdatedTables] = useState([]);

  useEffect(() => {
    setDurationMinutes(
      computeReservationDuration(
        reservationStartDatetime,
        reservationEndDatetime,
      ),
    );
    setMinutesFromFirstHour(
      computeMinutesFromStart(reservationStartDatetime, firstHour),
    );
  }, [
    firstHour,
    lastHour,
    hourLength,
    rowHeight,
    reservationStartDatetime,
    reservationEndDatetime,
  ]);

  useEffect(() => {
    setReservationStartDatetime(parseISO(reservation.reservation_date));
    setReservationEndDatetime(parseISO(reservation.reservation_date_end));
  }, [reservation.reservation_date, reservation.reservation_date_end]);

  const x = useSharedValue(0);
  const width = useSharedValue(0);
  const numberOfTables = tables?.length;

  const onTimeChange = (minutesDt, durationMinutesDt) => {
    const newDateFrom = addMinutes(reservationStartDatetime, minutesDt);
    const newDateTo = addMinutes(
      reservationEndDatetime,
      minutesDt + durationMinutesDt,
    );
    const newDateFromString = format(newDateFrom, "yyyy-MM-dd'T'HH:mm:ss");
    const newDateToString = format(newDateTo, "yyyy-MM-dd'T'HH:mm:ss");
    onReservationEdit(newDateFromString, newDateToString, updatedTables);
  };

  const onTableChange = (prevTableIndex, tablesDt) => {
    const newTableIndex = prevTableIndex + tablesDt;
    const prevTable = tables[prevTableIndex];
    const updatedTable = tables[newTableIndex];

    const existingIndex = updatedTables.findIndex(tableUpdate => {
      return tableUpdate.prevTable.id === prevTable.id;
    });
    const update = {
      prevTable,
      updatedTable,
    };
    if (existingIndex === -1) {
      setUpdatedTables([...updatedTables, update]);
      setTimeout(
        () => onReservationEdit(null, null, [...updatedTables, update]),
        300,
      );
    } else {
      const updatedTableEdit = Object.assign([...updatedTables], {
        [existingIndex]: update,
      });
      setUpdatedTables(updatedTableEdit);
      setTimeout(() => onReservationEdit(null, null, updatedTableEdit), 300);
    }
  };

  const indexes =
    reservation?.tables?.map(table =>
      tables.findIndex(x => x.id === table.id),
    ) || [];

  return indexes.map(tableIndex => {
    if (!(reservation.cancelled && hideCancelled)) {
      const boxColor = reservationColor(reservation);
      const note = reservation?.messages?.length > 0;

      return (
        <MovableReservationContainer
          onPress={() => showReservationDetailsModal(reservation)}
          key={`key:${tableIndex}${reservation.id}${hourLength}`}
          rowHeight={rowHeight}
          tableIndex={tableIndex}
          numberOfTables={numberOfTables}
          numberOfHours={lastHour - firstHour}
          minuteWidth={hourLength / 60}
          editingReservations={editingReservations}
          setEditingReservations={setEditingReservations}
          durationMinutes={durationMinutes}
          minutesFromFirstHour={minutesFromFirstHour}
          x={x}
          width={width}
          onTimeChange={onTimeChange}
          onTableChange={tablesDt => onTableChange(tableIndex, tablesDt)}
        >
          <SingleReservation
            dateFrom={reservation.reservation_date}
            dateTo={reservation.reservation_date_end}
            showTime={rowHeight > BREAKING_ROW_HEIGHT}
            fullname={reservation?.customer.fullname}
            walkIn={reservation?.walk_in}
            boxColor={boxColor}
            persons={reservation?.persons}
            note={note}
            textColor={Colors.darkest}
            textColor2={Colors.darker}
            textColor3={'#3f505b'}
          />
        </MovableReservationContainer>
      );
    }
  });
};

export default DrawReservation;
