import React, { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import SideMenuWrapper from './SideMenuWrapper';
import Colors from '../../constants/Colors';
import Globals from '../../constants/Globals';
import SettingsStyle from '../../styles/SettingsStyle';

import OpenHoursDaySelect from '../../components/OpenHoursDaySelect';
import { GlobalContext } from '../../GlobalContext';
import OpenHoursStyle from '../../styles/OpenHoursStyle';
import OpenHoursServices from '../../services/OpenHoursServices';
import Button from '../../components/Button';
import SegmentedControl from '@react-native-community/segmented-control';

const updateOpenHours = async (days, OpenHoursName, setId, global) => {
  const { showSnackbarMessage } = global;

  const data = {
    days,
    OpenHoursName,
    id: setId,
  };

  const result = await OpenHoursServices.updateOpenHoursSet(
    null,
    null,
    data,
    setId,
  );
  if (result.ok) {
    // Alert.alert('Saved');
    showSnackbarMessage(`Open hours were updated`);
  } else {
    console.log(result);
    showSnackbarMessage(`Something went wrong`);
    // Alert.alert('Something went wrong', result.message);
  }
};

const OpenHoursSettings = () => {
  const global = useContext(GlobalContext);
  const { defaultOpenHours } = global;

  const [days, setDays] = useState(defaultOpenHours?.days);
  const [type, setType] = useState(0);

  useEffect(() => {
    setDays(defaultOpenHours?.days);
  }, [defaultOpenHours]);

  const dayUpdate = props => {
    // console.log(props);
    const { day, openTime, closeTime, closed, holidays } = props;
    setDays(prevState => {
      let updatedDay = {};
      if (holidays === true) {
        updatedDay = {
          [day]: {
            ...prevState[day],
            open_time_holidays: openTime,
            close_time_holidays: closeTime,
            closed_holidays: closed,
          },
        };
      } else {
        updatedDay = {
          [day]: {
            ...prevState[day],
            open_time: openTime,
            close_time: closeTime,
            closed,
          },
        };
      }

      // console.log(updatedDay);
      return { ...prevState, ...updatedDay };
    });
  };

  const save = () => {
    // console.log(defaultOpenHours.id);
    updateOpenHours(
      days,
      defaultOpenHours.name,
      defaultOpenHours.id,
      global,
    ).then(() => global.updateOpenHours());
  };

  return (
    <SideMenuWrapper route={'openHours'} title={'Open Hours'}>
      <View style={SettingsStyle.container}>
        {/*<Text style={SettingsStyle.SettingsTitle}>Open Hours</Text>*/}
        <View
          style={{ alignItems: 'center', paddingBottom: 30, paddingTop: 0 }}
        >
          <SegmentedControl
            values={['Default', 'On Holidays']}
            selectedIndex={type}
            // selectedIndex={0}
            onChange={value => setType(value.nativeEvent.selectedSegmentIndex)}
            appearance={'light'}
            // backgroundColor={Platform.OS === 'web' ? false : false}
            style={{ width: '100%' }}
            tintColor={Colors.lightBlue}
            fontStyle={{ color: Colors.darker }}
            activeFontStyle={{ color: 'white' }}
          />
        </View>
        {days &&
          Globals.DAYS.map(day => {
            return (
              <View key={day.name} style={OpenHoursStyle.fullRow}>
                <Text style={OpenHoursStyle.dayText}>{day.name}</Text>
                {type === 0 ? (
                  <OpenHoursDaySelect
                    key={type}
                    selectedOpenTime={days[day.shortName]?.open_time}
                    selectedCloseTime={days[day.shortName]?.close_time}
                    selectedClosed={days[day.shortName]?.closed}
                    onChange={value =>
                      dayUpdate({
                        day: day.shortName,
                        holidays: false,
                        ...value,
                      })
                    }
                  />
                ) : (
                  <OpenHoursDaySelect
                    key={type}
                    selectedOpenTime={days[day.shortName]?.open_time_holidays}
                    selectedCloseTime={days[day.shortName]?.close_time_holidays}
                    selectedClosed={days[day.shortName]?.closed_holidays}
                    onChange={value =>
                      dayUpdate({
                        day: day.shortName,
                        holidays: true,
                        ...value,
                      })
                    }
                  />
                )}
              </View>
            );
          })}

        <Button
          onPress={save}
          text={'Save Changes'}
          style={{ marginTop: 30 }}
          color={Colors.confirmGreen}
          active
        />
      </View>
    </SideMenuWrapper>
  );
};

export default OpenHoursSettings;
