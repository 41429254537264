import React, { useContext, useState } from 'react';
import {
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../../../constants/Colors';

import ModalStyle from '../../../styles/ModalStyle';
import Buttons from '../../../styles/Buttons';
import { GlobalContext } from '../../../GlobalContext';
import ModalWrapper from '../components/ModalWrapper';
import Collapsible from '../../../components/Collapsible';
import TableServices from '../../../services/TablesServices';
import AreYouSureWrapper from '../../../components/AreYouSureWrapper';
import { Ionicons } from '@expo/vector-icons';

const Row = ({ children, high, last }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: high ? 150 : 50,
        // borderBottomWidth: last ? 0 : 1,
        // borderBottomColor: Colors.lightererer,
      }}
    >
      {children}
    </View>
  );
};

const RenderOpenHoursOptions = ({ openHours, changeSelectedOpenHours }) => {
  if (!openHours) {
    return <View />;
  }
  const sets = openHours?.map((set, index) => {
    return (
      <TouchableOpacity
        onPress={() => changeSelectedOpenHours(set.id, set.name)}
        key={index}
        style={ModalStyle.selectOptionRow}
      >
        <Text style={{ fontSize: 17 }}>{set.name}</Text>
        <Text style={ModalStyle.selectText}>Select</Text>
      </TouchableOpacity>
    );
  });
  return <View style={{ paddingBottom: 20, paddingLeft: 6 }}>{sets}</View>;
};

const AddEditGroupModal = ({
  visible,
  close,
  editGroup,
  editGroupId,
  editGroupName,
  editOpenHoursId,
  editOpenHoursName,
  editPrefix,
}) => {
  const global = useContext(GlobalContext);
  const { openHours } = global;

  const [name, setName] = useState(editGroup ? editGroupName : '');
  const [openHoursId, setOpenHoursId] = useState(
    editGroup ? editOpenHoursId : null,
  );
  const [openHoursName, setOpenHoursName] = useState(
    editGroup ? editOpenHoursName : null,
  );

  const [prefix, setPrefix] = useState(editGroup ? editPrefix : null);

  const addGroup = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      showSnackbarMessage,
      updateTables,
      updateRooms,
    } = global;

    const data = { name, open_hours_set_id: openHoursId, prefix };

    TableServices.createTableGroup(jwtToken, restaurantId, data).then(
      result => {
        if (result.ok) {
          showSnackbarMessage(`Table group ${name} was created.`);
          updateTableGroups();
          updateTables();
          updateRooms();
          close();
        } else {
          showSnackbarMessage(`Something went wrong`);
        }
      },
    );
  };

  const updateGroup = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      showSnackbarMessage,
      updateTables,
      updateRooms,
    } = global;

    const data = {
      name,
      prefix,
      open_hours_set_id: openHoursId,
    };

    TableServices.editTableGroup(
      jwtToken,
      restaurantId,
      data,
      editGroupId,
    ).then(result => {
      if (result.ok) {
        showSnackbarMessage(`Table group ${name} was updated.`);
        updateTableGroups();
        updateTables();
        updateRooms();
        close();
      } else {
        showSnackbarMessage(`Something went wrong`);
      }
    });
  };

  const deleteGroup = () => {
    const {
      jwtToken,
      updateTableGroups,
      showSnackbarMessage,
      updateTables,
    } = global;

    TableServices.removeGroup(jwtToken, editGroupId).then(result => {
      if (result.ok) {
        showSnackbarMessage(`Table group ${name} was removed.`);
        updateTableGroups();
        updateTables();
        close();
      } else {
        showSnackbarMessage(`Something went wrong`);
      }
    });
  };

  const changeSelectedOpenHours = (id, newName) => {
    setOpenHoursId(id);
    setOpenHoursName(newName);
  };

  return (
    <ModalWrapper
      onClose={close}
      title={editGroup ? 'Edit Tables Group' : 'New Tables Group'}
      isVisible={visible}
      disableScroll
    >
      <Row>
        <Text style={ModalStyle.fontLeft}>Name</Text>
        <View style={ModalStyle.touchableSelect}>
          <TextInput
            style={[
              ModalStyle.fontRight,
              ModalStyle.inputRight,
              { color: '#222', paddingVertical: 4 },
            ]}
            placeholderTextColor={Colors.placeholder}
            onChangeText={value => setName(value)}
            placeholder="Table group 1"
            value={name}
          />
        </View>
      </Row>

      <Row>
        <Text style={ModalStyle.fontLeft}>Group Prefix</Text>
        <View style={ModalStyle.touchableSelect}>
          <TextInput
            style={[
              ModalStyle.fontRight,
              ModalStyle.inputRight,
              { color: '#222', paddingVertical: 4 },
            ]}
            placeholderTextColor={Colors.placeholder}
            onChangeText={value => setPrefix(value)}
            placeholder="eg. F"
            value={prefix}
          />
        </View>
      </Row>

      <Row last>
        <Text style={ModalStyle.fontLeft}>Open Hours</Text>
        <TouchableOpacity onPress={() => changeSelectedOpenHours(null, '')}>
          <Text
            style={[
              ModalStyle.fontRight,
              !openHoursName && { color: Colors.lighter },
            ]}
          >
            {' '}
            {openHoursName || 'open hours'}
          </Text>
        </TouchableOpacity>
      </Row>

      <Collapsible collapsed={!!openHoursId} duration={500} collapsedHeight={0}>
        <Row high last>
          <ScrollView style={{ height: '100%' }}>
            <RenderOpenHoursOptions
              openHours={openHours}
              changeSelectedOpenHours={changeSelectedOpenHours}
            />
          </ScrollView>
        </Row>
      </Collapsible>
      {editGroup ? (
        <View style={Buttons.container}>
          <AreYouSureWrapper
            style={[Buttons.box]}
            onConfirm={() => deleteGroup()}
            title={'Remove this group'}
            confirmText={'Remove'}
            cancelText={'Cancel'}
            message={'Are you sure you want to remove this group?'}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Ionicons name="trash-bin-outline" size={20} color="red" />
              <Text style={[Buttons.text, { color: 'red' }]}>Remove Group</Text>
            </View>
          </AreYouSureWrapper>
          <View style={{ width: 20 }} />
          <TouchableOpacity style={[Buttons.big]} onPress={() => updateGroup()}>
            <Text style={Buttons.text}>Save</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={Buttons.container}>
          <TouchableOpacity
            style={[Buttons.big, Buttons.green]}
            onPress={() => addGroup()}
          >
            <Text style={Buttons.text}>Add Group</Text>
          </TouchableOpacity>
        </View>
      )}
    </ModalWrapper>
  );
};
export default AddEditGroupModal;
