import React from 'react';
import {
  Alert,
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';

import ModalStyle from '../../../styles/ModalStyle';
import Buttons from '../../../styles/Buttons';
import CountIncreaser from '../../../components/CountIncreaser';
import LayoutServices from '../../../services/LayoutServices';
import { withGlobalContext } from '../../../GlobalContext';
import { SafeAreaView } from 'react-native-safe-area-context';
import Table from '../../Spaces/RoomsComponents/Table';

const CHAIR_WIDTH = 50;

class NewModelModal extends React.Component {
  constructor(props) {
    // const { navigation } = props;

    super(props);
    this.state = {
      name: null,
      isRound: false,
      isVisible: true,
      chairsTop: 2,
      chairsBottom: 2,
      chairsLeft: 0,
      chairsRight: 0,
      chairsTotal: 20,
      length: 130,
      width: 70,
      lengthTemp: 130,
      widthTemp: 70,
    };
  }

  closeModal = force => {
    setTimeout(() => {
      // this.props.navigation.goBack(null);
    }, 300);
    // this.setState({ isVisible: false });
  };

  isRoundSwitch = () => {
    const { isRound } = this.state;
    this.setState({
      isRound: !isRound,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isVisible && !this.props.isVisible) {
      this.setState({
        name: null,
        isRound: false,
        isVisible: true,
        chairsTop: 2,
        chairsBottom: 2,
        chairsLeft: 0,
        chairsRight: 0,
        chairsTotal: 20,
        length: 130,
        width: 70,
        lengthTemp: 130,
        widthTemp: 70,
      });
    }
  }

  async createNewModel() {
    const {
      name,
      isRound,
      width,
      length,
      chairsTop,
      chairsBottom,
      chairsLeft,
      chairsRight,
      chairsTotal,
    } = this.state;

    const data = {};
    data.name = name;

    data.dimensions = {};
    data.dimensions.length = length;
    data.dimensions.width = width;
    data.dimensions.round = isRound;

    data.chairs = {};
    data.chairs.top = chairsTop;
    data.chairs.bottom = chairsBottom;
    data.chairs.left = chairsLeft;
    data.chairs.right = chairsRight;

    const isCircle = width === length && isRound;

    data.chairs.count = isCircle
      ? chairsTotal
      : chairsTop + chairsBottom + chairsLeft + chairsRight;

    const result = await LayoutServices.createTableModel(data);
    if (result.ok) {
      this.props.global.updateModels();
      this.props.closeModal();
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  updateChairsCount = (value, state) => {
    Keyboard.dismiss();
    this.setState({ [state]: value });
  };

  RenderTableConfigView = () => {
    const {
      chairsTop,
      chairsBottom,
      chairsLeft,
      chairsRight,
      isRound,
      width,
      length,
      chairsTotal,
    } = this.state;

    const chairsOptions = [];
    for (let i = 0; i < 50; i += 1) {
      chairsOptions[i] = {
        label: i,
        key: i,
        value: i,
      };
    }

    const isCircle = width === length && isRound;

    const chairsCount = isCircle
      ? chairsTotal
      : chairsTop + chairsBottom + chairsLeft + chairsRight;
    // const ROW_HEIGHT = 220;
    const MIN_DIM = Math.min(
      Dimensions.get('window').width,
      Dimensions.get('window').height,
    );
    // const ROW_HEIGHT = MIN_DIM < 500 ? 200 : 200;
    const ROW_HEIGHT = Math.min(200, MIN_DIM * 0.35);
    let scaleFactor = ROW_HEIGHT / Math.max(width, length);
    scaleFactor *= width === length ? 1 : 1;

    const table = {
      chairs: {
        count: chairsCount,
        top: chairsTop,
        bottom: chairsBottom,
        left: chairsLeft,
        right: chairsRight,
      },
      dimensions: {
        length,
        width,
        round: isRound,
      },
    };

    if (Math.min(length, width) < 35) {
      return null;
    }

    return (
      <View
        style={{
          marginTop: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ height: 50, justifyContent: 'center' }}>
          {!isCircle ? (
            <CountIncreaser
              key="top"
              initialIndex={chairsTop}
              optionsArray={chairsOptions.slice(
                0,
                Math.floor(length / CHAIR_WIDTH) + 1,
              )}
              updateValueFunc={value =>
                this.updateChairsCount(value, 'chairsTop')
              }
              circleBorder={0}
              valueWidth={50}
              iconSize={24}
              maxIndex={Math.floor(length / CHAIR_WIDTH)}
            />
          ) : (
            <CountIncreaser
              key="topCircle"
              initialIndex={chairsTotal}
              optionsArray={chairsOptions.slice(
                0,
                Math.floor((length * 3.14) / CHAIR_WIDTH) + 1,
              )}
              updateValueFunc={value =>
                this.updateChairsCount(value, 'chairsTotal')
              }
              maxIndex={Math.floor((length * 3.14) / CHAIR_WIDTH)}
              circleBorder={0}
              valueWidth={50}
              iconSize={24}
            />
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View style={{ justifyContent: 'center' }}>
            {!isCircle ? (
              <CountIncreaser
                initialIndex={chairsLeft}
                vertical
                optionsArray={chairsOptions.slice(
                  0,
                  Math.floor(width / CHAIR_WIDTH) + 1,
                )}
                updateValueFunc={value =>
                  this.updateChairsCount(value, 'chairsLeft')
                }
                circleBorder={-1}
                valueWidth={30}
                iconSize={24}
                maxIndex={Math.floor(width / CHAIR_WIDTH)}
              />
            ) : null}
          </View>
          <View style={{ padding: 0, overflow: 'hidden' }}>
            <Table
              // scaleFactor={scaleFactor}
              maxWidth={ROW_HEIGHT}
              maxHeight={ROW_HEIGHT}
              tableWidth={table.dimensions.width}
              tableLength={table.dimensions.length}
              isRound={table.dimensions.round}
              chairsTop={table.chairs.top}
              chairsBottom={table.chairs.bottom}
              chairsLeft={table.chairs.left}
              chairsRight={table.chairs.right}
              chairsCount={table.chairs.count}
              tableNumber={table.table_number}
            />
          </View>
          <View style={{ justifyContent: 'center' }}>
            {!isCircle ? (
              <CountIncreaser
                initialIndex={chairsRight}
                vertical
                optionsArray={chairsOptions.slice(
                  0,
                  Math.floor(width / CHAIR_WIDTH) + 1,
                )}
                updateValueFunc={value =>
                  this.updateChairsCount(value, 'chairsRight')
                }
                maxIndex={Math.floor(width / CHAIR_WIDTH)}
                circleBorder={-1}
                valueWidth={30}
                iconSize={24}
              />
            ) : null}
          </View>
        </View>
        <View style={{ justifyContent: 'center' }}>
          {!isCircle ? (
            <CountIncreaser
              key={1}
              initialIndex={chairsBottom}
              optionsArray={chairsOptions.slice(
                0,
                Math.floor(length / CHAIR_WIDTH) + 1,
              )}
              updateValueFunc={value =>
                this.updateChairsCount(value, 'chairsBottom')
              }
              maxIndex={Math.floor(length / CHAIR_WIDTH)}
              circleBorder={0}
              valueWidth={50}
              iconSize={24}
            />
          ) : null}
        </View>
      </View>
    );
  };

  setNumber = stringNumber => {
    const int = parseInt(stringNumber);

    return isNaN(int) ? 0 : int;
  };

  confirmDimensions = () => {
    const { widthTemp, lengthTemp } = this.state;

    this.setState({
      width: Math.max(this.setNumber(widthTemp), CHAIR_WIDTH),
      length: Math.max(this.setNumber(lengthTemp), CHAIR_WIDTH),
      lengthTemp: Math.max(this.setNumber(lengthTemp), CHAIR_WIDTH),
      widthTemp: Math.max(this.setNumber(widthTemp), CHAIR_WIDTH),
    });
  };

  render() {
    const {
      name,
      isRound,
      // isVisible,
      chairsTop,
      chairsBottom,
      chairsLeft,
      chairsRight,
      chairsTotal,
      width,
      length,
    } = this.state;

    const { isVisible, closeModal, smallDevice } = this.props;

    // const { isRoundSwitch, closeModal } = this;
    const { isRoundSwitch } = this;
    const isCircle = width === length && isRound;
    const chairsCount = isCircle
      ? chairsTotal
      : chairsTop + chairsBottom + chairsLeft + chairsRight;

    // const { deviceType } = this.props.global;
    const isVertical =
      Dimensions.get('window').width < Dimensions.get('window').height;
    const minDim = Math.min(
      Dimensions.get('window').width,
      Dimensions.get('window').height,
    );
    let wh_w;
    let wh_h;
    if (isVertical) {
      wh_w = Math.min(420, Dimensions.get('window').width);
      wh_h = Math.min(420, Dimensions.get('window').height / 2) - 80;
    } else {
      wh_w = Math.min(420, Dimensions.get('window').width / 2) - 30;
      wh_h = Math.min(420, Dimensions.get('window').height) - 80;
    }
    const wh = Math.min(420, minDim);

    const dismissKeyboard = () => {
      if (Platform.OS !== 'web') {
        Keyboard.dismiss();
      }
    };

    return (
      <Modal
        style={{
          margin: 0,
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
        animationType={'fade'}
        transparent
        visible={isVisible}
        onRequestClose={() => closeModal()}
        supportedOrientations={['portrait', 'landscape']}
      >
        <TouchableWithoutFeedback onPress={dismissKeyboard}>
          <View
            style={{
              flex: 1,
              backgroundColor: 'rgba(0,0,0, 0.4)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
              enabled
            >
              <View
                style={[
                  ModalStyle.modalContainerSplit,
                  // { flex: 1 },
                  // smallDevice && {
                  //   height: '100%',
                  //   width: '100%',
                  //   backgroundColor: 'green',
                  // },
                ]}
              >
                <SafeAreaView
                  edges={smallDevice ? ['top'] : []}
                  // forceInset={{
                  //   top: smallDevice ? 'always' : 'never',
                  //   left: 'never',
                  //   right: 'never',
                  //   bottom: 'never',
                  // }}
                >
                  <View style={[ModalStyle.headerBar, { height: 60 }]}>
                    <View style={ModalStyle.headerNavigation} />
                    <Text style={ModalStyle.headerTitle}>New Model</Text>
                    <TouchableOpacity
                      style={ModalStyle.headerNavigation}
                      onPress={() => closeModal()}
                    >
                      <Ionicons name="md-close" size={29} color="#ccc" />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      marginHorizontal: 0,
                      // flex: 1,
                      flexDirection: isVertical ? 'column' : 'row',
                      // flexWrap: 'wrap',
                      // alignSelf: 'center',
                      // maxWidth: wh + 50,
                      // width: '100%',
                      maxHeight: '100%',
                      // width: wh,
                      // alignContent: 'flex-start',
                      // alignItems: 'center',
                      // alignContent: 'center',
                      // justifyContent: 'center',
                      // alignItems: 'flex-start',
                    }}
                  >
                    <View
                      style={{
                        // backgroundColor: 'red',
                        // flex: 7,
                        // marginHorizontal: 36,

                        alignItems: 'center',
                        justifyContent: isVertical ? 'center' : 'flex-start',
                        // minWidth: 400,
                        // flexBasis: 300,
                        // flexGrow: 1,
                        width: wh_w,
                        height: wh_h,
                        // alignContent: 'stretch',
                      }}
                    >
                      <this.RenderTableConfigView />
                    </View>
                    {/*<View*/}
                    {/*  style={{*/}
                    {/*    width: 1,*/}
                    {/*    flexDirection: 'row',*/}
                    {/*    // flexShrink: 1,*/}
                    {/*    backgroundColor: Colors.lighterer,*/}
                    {/*    marginVertical: 30,*/}
                    {/*    // borderRadius: 10,*/}
                    {/*  }}*/}
                    {/*/>*/}
                    <View
                      style={{
                        paddingRight: 36,
                        paddingLeft: isVertical ? 36 : 0,
                        // flexShrink: 1,
                        // flexBasis: 500,
                        // flexDirection: 'row',
                        // width: 400,
                        // minWidth: 300,
                        width: wh_w,
                        // height: wh_h,
                        // height: wh_h,
                        justifyContent: 'center',
                        // alignContent: 'stretch',
                      }}
                    >
                      <View style={ModalStyle.itemSelect}>
                        <View style={ModalStyle.itemSelectText}>
                          <Text style={ModalStyle.fontLeft}>Name</Text>
                          <View style={ModalStyle.touchableSelect}>
                            <TextInput
                              style={[
                                ModalStyle.fontRight,
                                ModalStyle.inputRight,
                                { color: '#222' },
                              ]}
                              multiline={false}
                              placeholderTextColor={Colors.placeholder}
                              onChangeText={text =>
                                this.setState({ name: text })
                              }
                              placeholder="Model name"
                              value={name}
                              autoCorrect={false}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={ModalStyle.itemSelect}>
                        <View style={ModalStyle.itemSelectText}>
                          <Text style={ModalStyle.fontLeft}>
                            Size{' '}
                            <Text
                              style={{ color: Colors.lighter, fontSize: 16 }}
                            >
                              (cm)
                            </Text>
                          </Text>
                          <View style={ModalStyle.touchableSelect}>
                            <View style={ModalStyle.editableTextContainer}>
                              <TextInput
                                style={[ModalStyle.editableText]}
                                placeholderTextColor={Colors.placeholder}
                                // onFocus={() => this.setState({ lengthTemp: '' })}
                                selectTextOnFocus
                                onChangeText={text =>
                                  this.setState({ lengthTemp: text })
                                }
                                onEndEditing={this.confirmDimensions}
                                onBlur={this.confirmDimensions}
                                maxLength={3}
                                multiline={false}
                                keyboardType="number-pad"
                                value={String(this.state.lengthTemp)}
                                autoCorrect={false}
                              />
                            </View>
                            <Text
                              style={[
                                ModalStyle.inputRight,
                                {
                                  marginHorizontal: 12,
                                  color: Colors.dark,
                                  fontWeight: '500',
                                },
                              ]}
                            >
                              x
                            </Text>
                            <View style={ModalStyle.editableTextContainer}>
                              <TextInput
                                style={ModalStyle.editableText}
                                placeholderTextColor={Colors.placeholder}
                                // onFocus={() => this.setState({ widthTemp: '' })}
                                selectTextOnFocus
                                onChangeText={text =>
                                  this.setState({ widthTemp: text })
                                }
                                onBlur={this.confirmDimensions}
                                onEndEditing={this.confirmDimensions}
                                maxLengt={4}
                                keyboardType="number-pad"
                                value={String(this.state.widthTemp)}
                                autoCorrect={false}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={ModalStyle.itemSelect}>
                        <View style={ModalStyle.itemSelectText}>
                          <Text style={ModalStyle.fontLeft}>Round</Text>
                          <View style={ModalStyle.touchableSelect}>
                            <Switch
                              onValueChange={isRoundSwitch}
                              value={isRound}
                              // trackColor={{ true: Colors.darkBlue }}
                            />
                          </View>
                        </View>
                      </View>
                      <View style={ModalStyle.itemSelect}>
                        <View style={ModalStyle.itemSelectText}>
                          <Text style={ModalStyle.fontLeft}>Chairs</Text>
                          <View style={ModalStyle.touchableSelect}>
                            <Text
                              style={[
                                ModalStyle.fontRight,
                                ModalStyle.inputRight,
                                { color: '#222' },
                              ]}
                            >
                              {chairsCount}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View style={[Buttons.container, { paddingBottom: 12 }]}>
                        <TouchableOpacity
                          style={[Buttons.big, Buttons.green]}
                          onPress={() => this.createNewModel()}
                        >
                          <Text style={Buttons.text}>Create Model</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </SafeAreaView>
              </View>
            </KeyboardAvoidingView>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
}

export default withGlobalContext(NewModelModal);
