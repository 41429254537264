import React, { memo } from 'react';
import { Animated, Platform, StyleSheet, View } from 'react-native';

const CHAIR_WIDTH = 38;

// const tableColorGreen = 'hsl(135,76%,55%)';
// const chairColorGreen = 'hsl(135,55%,45%)';

// const tableColor = 'hsl(200,100%,60%)';
// const chairColor = 'hsl(200,70%,48%)';

const tableColor = 'hsl(208,7%,55%)';
const chairColor = 'hsl(208,7%,40%)';
const tableColorDisabled = 'hsl(0,0%,82%)';
const chairColorDisabled = 'hsl(0,0%,73%)';

// const chairColor = 'hsl(150,44%,38%)';
// const tableColor = 'hsl(150,44%,47%)';
//
// const tableColorDisabled = 'hsl(145,22%,78%)';
// const chairColorDisabled = 'hsl(145,22%, 68%)';

const tableColorSelected = 'hsl(213,90%,65%)';
const chairColorSelected = 'hsl(213,80%,48%)';

const RenderChairs = ({ count, chairWidth, radius, color }) => {
  const chairs = [];

  const angleInterval = 360 / count;
  for (let i = 0; i < count; i++) {
    const rotateDegrees = `${(i * angleInterval).toString()}deg`;
    chairs.push(
      <View
        key={rotateDegrees + i}
        style={[
          {
            position: 'absolute',
            width: radius,
            height: radius,
            alignItems: 'center',
            transform: [{ rotate: rotateDegrees }],
          },
          // Style.shadow,
        ]}
      >
        <View
          style={{
            width: chairWidth,
            height: chairWidth * 1,
            backgroundColor: color,
            // borderRadius: 40,
            borderRadius: 100,
            transform: [
              { perspective: 400 },
              { rotateX: '-62deg' },
              { translateY: -26 },
            ],
          }}
        />
      </View>,
    );
  }

  return chairs;
};

const RenderChairsNormal = ({
  count,
  chairWidth,
  side,
  color,
  tableHeight,
  tableWidth,
  p2,
}) => {
  const chairs = [];

  for (let i = 0; i < count; i++) {
    if (!p2) {
      chairs.push(
        <View
          key={i}
          style={[
            { backgroundColor: color },
            {
              height: chairWidth + 30,
              width: chairWidth,
              borderRadius: 30,
              zIndex: 0,
              transform: [
                { perspective: 800 },
                { translateY: -24 },
                // { rotateZ: i % 2 === 0 ? '-4deg' : '4deg' },
                // { rotate: i === 0 ? '-18deg' : '0deg' },
                // { rotate: i === count - 1 ? '18deg' : '0deg' },
                { rotateX: '-80deg' },
                // { translateY: -36 },
              ],
            },
          ]}
        />,
      );
    } else {
      // chairs.push(
      //   <View
      //     key={i}
      //     style={[
      //       {
      //         backgroundColor:
      //           i === 1 && side === 'bottom'
      //             ? 'hsl(23,17%,65%)'
      //             : 'transparent',
      //       },
      //       // { backgroundColor: color },
      //       {
      //         height: 30,
      //         width: chairWidth,
      //         borderRadius: 4,
      //         // shadowOpacity: 0.4,
      //         transform: [
      //           { translateY: 6 },
      //           { perspective: 200 },
      //           { rotateX: '50deg' },
      //           { rotateZ: '180deg' },
      //         ],
      //       },
      //     ]}
      //   />,
      // );
    }
  }
  return (
    <View
      style={{
        width:
          side === 'left' || side === 'right'
            ? tableHeight + 20
            : tableWidth + 20,
        height:
          side === 'left' || side === 'right'
            ? tableWidth + 20
            : tableHeight + 20,
        position: 'absolute',
        // backgroundColor: 'rgba(0,0,0,0.4)',
        flexDirection: 'row',
        justifyContent: 'space-around',
        // justifyContent: 'space-between',
        paddingHorizontal: 16,
        transform: [
          {
            rotate: side === 'bottom' ? '180deg' : '0deg',
          },
          {
            rotate: side === 'right' ? '90deg' : '0deg',
          },
          {
            rotate: side === 'left' ? '270deg' : '0deg',
          },
        ],
      }}
    >
      {chairs}
    </View>
  );
};

const TableNormal = ({
  width,
  height,
  chairsTop,
  chairsBottom,
  chairsLeft,
  chairsRight,
  chairWidth,
  tableStyle,
  colorScheme,
  tableRadius,
  children,
}) => {
  return (
    <View
      style={{
        // flex: 1,
        // backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <RenderChairsNormal
        side={'top'}
        count={chairsTop}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <RenderChairsNormal
        side={'bottom'}
        count={chairsBottom}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <RenderChairsNormal
        side={'left'}
        count={chairsLeft}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <RenderChairsNormal
        side={'right'}
        count={chairsRight}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <RenderChairsNormal
        p2
        side={'top'}
        count={chairsTop}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <RenderChairsNormal
        p2
        side={'bottom'}
        count={chairsBottom}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <RenderChairsNormal
        p2
        side={'left'}
        count={chairsLeft}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <RenderChairsNormal
        p2
        side={'right'}
        count={chairsRight}
        chairWidth={chairWidth}
        color={colorScheme.chair}
        tableWidth={width}
        tableHeight={height}
      />

      <View
        style={[
          tableStyle.table,
          {
            // borderRadius: tableRadius,
            borderRadius: tableRadius + 2,
            width: width,
            height: height,
            shadowOpacity: Platform.OS === 'web' ? 0.15 : 0.3,
            shadowColor: 'hsl(150,44%,20%)',
            // zIndex: 10,
            shadowRadius: 1,
            shadowOffset: {
              height: 0,
              width: 0,
            },
            elevation: 2,
            zIndex: 2,
          },
          // Style.shadow,
        ]}
      >
        {children}
      </View>
    </View>
  );
};

const TableNormalMemo = TableNormal;

const TableCirular = ({
  chairsCount,
  width,
  chairWidth,
  colorScheme,
  tableStyle,
  tableRadius,
  children,
}) => {
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
      <RenderChairs
        count={chairsCount}
        radius={width + 10}
        rounded
        chairWidth={chairWidth}
        tableStyle={tableStyle}
        pointerEvents="box-none"
        color={colorScheme.chair}
      />

      <View
        style={[
          tableStyle.table,
          {
            borderRadius: tableRadius,
            width: width,
            height: width,
            shadowOpacity: Platform.OS === 'web' ? 0.15 : 0.3,
            shadowColor: 'hsl(150,44%,20%)',
            shadowRadius: 1,
            shadowOffset: {
              height: 0,
              width: 0,
            },
          },
        ]}
      >
        {children}
      </View>
    </View>
  );
};

const TableVanilla = ({
  tableWidth,
  tableLength,
  isRound,
  chairsTop,
  chairsBottom,
  chairsLeft,
  chairsRight,
  chairsCount,
  tableNumber,
  opacity = new Animated.Value(1),
  occupied = false,
  selected = false,
  children = null,
}) => {
  let colorScheme = { chair: chairColor, table: tableColor };

  if (selected) {
    colorScheme = { chair: chairColorSelected, table: tableColorSelected };
  } else if (occupied) {
    colorScheme = { chair: chairColorDisabled, table: tableColorDisabled };
  }

  let tableRadius = 2;
  const width = tableLength;
  const height = tableWidth;
  const chairWidth = CHAIR_WIDTH;

  if (isRound) {
    tableRadius = Math.min(width, height) / 2;
  }
  // console.log(chairs);

  const tableStyle = StyleSheet.create({
    topBottom: {
      // backgroundColor: 'red',
      height: chairWidth * 0,
      // marginHorizontal: chairWidth / 6,
      flexDirection: 'row',
      justifyContent: 'space-around',
      // marginHorizontal: width * 0.05,
    },
    leftRight: {
      // backgroundColor: 'blue',
      // width: chairWidth * 0.3,
      justifyContent: 'space-around',
    },
    middle: {
      flex: 1,
      flexDirection: 'row',
    },
    table: {
      // flex: 1,
      // width: '100%',
      // height: '100%',
      backgroundColor: colorScheme.table,
      // backgroundColor: 'rgba(136,140,143,0.42)',
      // margin: chairWidth * 0.11,
      justifyContent: 'center',
      alignItems: 'center',
    },
    tableNumberText: {
      fontSize: 4 + 20,
      color: 'rgba(255, 255, 255, 0.90)',
    },
    chairTopBottom: {
      width: chairWidth,
      borderRadius: 4,
    },
    chairLeftRight: {
      height: chairWidth,
      borderRadius: 4,
    },
  });

  return (
    <Animated.View
      style={[
        {
          width,
          height,
          opacity,
        },
      ]}
    >
      {isRound && width === height ? (
        <TableCirular
          width={width}
          tableStyle={tableStyle}
          tableNumber={tableNumber}
          chairsCount={chairsCount}
          tableRadius={tableRadius}
          colorScheme={colorScheme}
          chairWidth={chairWidth}
        >
          {children}
        </TableCirular>
      ) : (
        <TableNormalMemo
          width={width}
          height={height}
          chairsTop={chairsTop}
          chairsBottom={chairsBottom}
          chairsLeft={chairsLeft}
          chairsRight={chairsRight}
          chairWidth={chairWidth}
          tableStyle={tableStyle}
          colorScheme={colorScheme}
          tableRadius={tableRadius}
        >
          {children}
        </TableNormalMemo>
      )}
      {/*{isRound && width === height ? roundedTable : normalTable}*/}
    </Animated.View>
  );
};

export default TableVanilla;
