import GLOBAL from '../constants/Globals';
const TimelineServices = {
  async get_restaurants(jwt_token) {
    let url = GLOBAL.API_URL + '/auth/restaurants';
    try {
      let response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwt_token,
        }),
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let responseJson = await response.json();
      // console.log(responseJson);
      return responseJson;
    } catch (error) {
      console.error(error);
    }
    return true;
  },
};

export default TimelineServices;
