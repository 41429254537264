import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import Colors from '../../../constants/Colors';
import { withGlobalContext } from '../../../GlobalContext';
import Table from './Table';

const RoomStatic = ({
  room,
  roomContainer,
  occupiedTables,
  selectedTables,
  registerTableClick = () => null,
  enableSelection,
  backgroundColor,
}) => {
  return (
    <View
      style={[
        {
          backgroundColor: backgroundColor,
          width: roomContainer?.width,
          height: roomContainer?.height,
        },
      ]}
    >
      {roomContainer && room && (
        <View
          style={{
            position: 'absolute',
            width: room.dimensions.width,
            height: room.dimensions.height,
            borderWidth: 1,
            borderColor: Colors.lighterer,
            transform: [
              { translateX: -room.dimensions.width / 2 },
              { translateY: -room.dimensions.height / 2 },
              { scale: roomContainer.scaleFactor },
              { translateX: room.dimensions.width / 2 },
              { translateY: room.dimensions.height / 2 },
            ],
          }}
        >
          {room.tables.map((item, key) => {
            return (
              <TouchableOpacity
                onPress={() => registerTableClick(item)}
                disabled={!enableSelection}
                key={`x${item.position.x}y${item.position.y}id${item.table_id}`}
                style={{
                  position: 'absolute',

                  // backgroundColor: 'red',
                  // shadowOpacity: Platform.OS === 'web' ? 0.15 : 0.2,
                  // shadowRadius: 20,
                  // shadowOffset: {
                  //   height: 0,
                  //   width: 0,
                  // },
                  // elevation: 1,
                  transform: [
                    { translateX: item.position.x },
                    { translateY: item.position.y },

                    { rotate: item.position.angle + 'deg' },
                    // { skewX: '10deg' },
                  ],
                }}
              >
                <Table
                  key={`x${item.position.x}y${item.position.y}id${item.table_id}`}
                  occupied={occupiedTables?.includes(item.table_id)}
                  selected={selectedTables?.includes(item.table_id)}
                  textRotationAngle={item.position.angle}
                  tableWidth={item.dimensions.width}
                  tableLength={item.dimensions.length}
                  isRound={item.dimensions.round}
                  chairsTop={item.chairs.top}
                  chairsBottom={item.chairs.bottom}
                  chairsLeft={item.chairs.left}
                  chairsRight={item.chairs.right}
                  chairsCount={item.chairs.count}
                  tableNumber={item.table_number}
                  groupPrefix={item.group_prefix}
                  // timeLeftPercent={0.3}
                  // bottomText={'14:30'}
                />
              </TouchableOpacity>
            );
          })}
        </View>
      )}
    </View>
  );
};

export default withGlobalContext(RoomStatic);
