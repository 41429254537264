import React, { useContext, useEffect, useState } from 'react';
import { Text, TextInput, View } from 'react-native';
import { GlobalContext } from '../../GlobalContext';

import SideMenuWrapper from './SideMenuWrapper';
import SettingsStyle from '../../styles/SettingsStyle';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import SettingsServices from '../../services/SettingsServices';
import ImageUpload from '../../components/ImageUpload';
import AreYouSureWrapper from '../../components/AreYouSureWrapper';

const ProfileSettingsItem = props => {
  const { title, textInput, setTextInput, multiline, disableEditing } = props;
  return (
    <View style={{ marginBottom: 6 }}>
      <Text
        style={{
          fontSize: 12,
          fontWeight: '500',
          color: 'rgb(35,38,42)',
          marginBottom: 2,
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Text>
      <View style={SettingsStyle.settingsItemInputRow}>
        <TextInput
          style={{
            // borderWidth: 1,
            // borderColor: Colors.lighterer,
            color: !disableEditing ? Colors.darker : Colors.light,
            fontWeight: !disableEditing ? '400' : '600',
            backgroundColor: 'rgb(244,244,245)',
            borderRadius: 5,
            padding: 10,
            paddingTop: multiline ? 10 : undefined,
            paddingHorizontal: 10,
            flex: 1,
            fontSize: 14,
            height: multiline ? 100 : 38,
          }}
          // style={SettingsStyle.settingsItemTextInput}
          // placeholder=""
          multiline={!!multiline}
          onChangeText={text => setTextInput(text)}
          defaultValue={textInput}
          editable={!disableEditing}
        />
      </View>
    </View>
  );
};

const PhotosItem = props => {
  const { photos, title, updatePhotos } = props;

  const onPhotoUpload = imageName => {
    console.log('OK', imageName);
    SettingsServices.addPhoto(imageName)
      .then(() => {
        updatePhotos();
      })
      .catch(() => {});
  };

  const removePhoto = imageId => {
    SettingsServices.removePhoto(imageId)
      .then(() => {
        updatePhotos();
      })
      .catch(() => {});
  };

  return (
    <View style={{ marginBottom: 6 }}>
      <Text
        style={{
          fontSize: 12,
          fontWeight: '500',
          color: 'rgb(35,38,42)',
          marginBottom: 2,
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Text>
      <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
        {photos !== undefined &&
          photos.map((im, index) => {
            return (
              <AreYouSureWrapper
                key={im.url}
                title={'Remove photo'}
                confirmText={'Remove'}
                message={'Do you want to remove this photo?'}
                onConfirm={() => removePhoto(im.id)}
              >
                <ImageUpload
                  size={100}
                  defaultUrl={im.url}
                  key={im.url}
                  picker={false}
                  showEdit
                />
              </AreYouSureWrapper>
            );
          })}
        <ImageUpload size={100} onSuccessfulUpload={onPhotoUpload} />
      </View>
    </View>
  );
};

const updateInfo = async props => {
  const { global } = props;
  const { showSnackbarMessage } = global;
  const result = await SettingsServices.updateInfo(props);
  if (result.ok) {
    showSnackbarMessage(`Profile was updated`);
  } else {
    // Alert.alert('Something went wrong', result.message);
    showSnackbarMessage(`Something went wrong`);
  }
};

const ProfileSettings = () => {
  const global = useContext(GlobalContext);

  const {
    restaurantInfo,
    restaurantPhotos,
    updateRestaurantPhotos,
    updateRestaurantInfo,
  } = global;

  // if (restaurantInfo?.name == null) {
  //   global.updateRestaurantInfo();
  //   return <Text>Loading</Text>;
  // }

  const [restaurantId, setRestaurantId] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [restaurantCompany, setRestaurantCompany] = useState('');
  const [restaurantDescription, setRestaurantDescription] = useState('');
  const [restaurantUrl, setRestaurantUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [restaurantCity, setRestaurantCity] = useState('');
  const [restaurantCountry, setRestaurantCountry] = useState('');

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    updateRestaurantInfo();
    updateRestaurantPhotos();
  }, []);

  useEffect(() => {
    if (restaurantInfo) {
      const {
        name,
        company,
        description,
        website_url,
        phone,
        address,
        photos,
        restaurant_id,
      } = restaurantInfo;
      console.log(restaurant_id);
      const { street, city, zip, country } = address || {};
      setRestaurantId(`${restaurant_id}`);
      setRestaurantName(name);
      setRestaurantDescription(description);
      setRestaurantUrl(website_url);
      setPhoneNumber(phone);
      setStreetAddress(street);
      setZipCode(zip);
      setRestaurantCity(city);
      setRestaurantCountry(country);
    }
  }, [restaurantInfo]);

  useEffect(() => {
    setPhotos(restaurantPhotos);
  }, [restaurantPhotos]);

  const save = () => {
    const addressObject = {
      street: streetAddress,
      zip: zipCode,
      city: restaurantCity,
      country: restaurantCountry,
    };
    updateInfo({
      restaurantName,
      restaurantCompany,
      restaurantDescription,
      restaurantUrl,
      phoneNumber,
      address: addressObject,
      global,
    }).then(() => global.updateRestaurantInfo());
  };

  return (
    <SideMenuWrapper route="profile" title="Profile">
      <View style={SettingsStyle.container}>
        <ProfileSettingsItem
          title="Restaurant Id"
          textInput={restaurantId}
          setTextInput={setRestaurantId}
          disableEditing
        />
        <ProfileSettingsItem
          title="Restaurant Name"
          textInput={restaurantName}
          setTextInput={setRestaurantName}
        />
        <ProfileSettingsItem
          title="Description"
          textInput={restaurantDescription}
          setTextInput={setRestaurantDescription}
          multiline
        />
        <ProfileSettingsItem
          title="Website address"
          textInput={restaurantUrl}
          setTextInput={setRestaurantUrl}
        />
        <ProfileSettingsItem
          title="Phone number"
          textInput={phoneNumber}
          setTextInput={setPhoneNumber}
        />

        {/* <Text style={SettingsStyle.profileSubTitle}>Address</Text> */}
        <View style={{ height: 20 }} />

        <ProfileSettingsItem
          title="Street Address"
          textInput={streetAddress}
          setTextInput={setStreetAddress}
        />
        <ProfileSettingsItem
          title="Zip Code"
          textInput={zipCode}
          setTextInput={setZipCode}
        />
        <ProfileSettingsItem
          title="City"
          textInput={restaurantCity}
          setTextInput={setRestaurantCity}
        />
        <View style={{ height: 20 }} />

        <PhotosItem
          title="Photos"
          photos={photos}
          updatePhotos={updateRestaurantPhotos}
        />
        <Button
          onPress={save}
          text="Save Profile"
          style={{ marginTop: 12 }}
          color={Colors.confirmGreen}
          active
        />
      </View>
    </SideMenuWrapper>
  );
};
export default ProfileSettings;
