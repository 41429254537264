import { StyleSheet, Dimensions, Platform } from 'react-native';
import Colors from '../constants/Colors';

const GLOBAL = require('../constants/Globals');

const ModalStyle = StyleSheet.create({
  modalContainer: {
    width: 380,
    maxWidth: '94%',
    // maxHeight: '100%',
    // height: 600,
    // maxHeight: '80%',
    paddingBottom: 10,
    backgroundColor: Colors.lightBlueBackground,
    borderRadius: 8,
    alignSelf: 'center',
    // flex:1,
  },
  modalContainerFullScreen: {
    width: '100%',
    height: '100%',
    // paddingTop: 30,
    paddingBottom: 16,
    backgroundColor: Colors.lightBlueBackground,
    borderRadius: 0,
    alignSelf: 'center',
  },
  modalContainerSplit: {
    // minWidth: 780,
    // paddingBottom: 40,
    // backgroundColor: '#fff',
    borderRadius: 8,
    // alignSelf: 'center',
    backgroundColor: Colors.lightBackground,
  },
  modalContainerWide: {
    minWidth: 500,
  },
  modalContainerEdit: {
    flex: 1,
  },

  headerBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 70,
  },
  headerTitle: {
    alignSelf: 'center',
    fontSize: 20,
    fontWeight: '500',
    color: '#444',
    // marginTop: 20,
    // marginBottom: 25,
  },
  headerNavigation: {
    width: 80,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dimensionsInput: {
    backgroundColor: Colors.lighterer,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.darker,
    fontWeight: '500',
    fontSize: 16,
    borderRadius: 12,
    paddingHorizontal: 10,
    paddingVertical: 8,
    width: 67,
  },

  touchableSelect: {
    paddingRight: 0,
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    // backgroundColor: "red"
  },
  itemSelect: {
    justifyContent: 'center',
  },
  itemSelectText: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 48,
  },
  fontLeft: { fontSize: 17, fontWeight: '500', color: '#444' },
  fontRight: {
    fontSize: 17,
    fontWeight: '400',
    color: '#222',
    marginLeft: 20,
  },
  inputRight: {
    textAlign: 'right',
    fontSize: 18,
  },
  inputTime: {
    color: Colors.darker,
    fontSize: 18,
    width: 50,
    textAlign: 'center',
  },
  selectOptionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    // backgroundColor: 'blue',
    // borderBottomWidth: 0.5,
    // borderColor: Colors.lighter,
  },
  openHoursRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: 10,
    borderBottomWidth: 0.4,
    height: 52,
    borderColor: Colors.lighter,
  },
  selectText: {
    fontSize: 17,
    fontWeight: '400',
    color: Colors.lightBlue,
  },
  deleteText: {
    fontSize: 19,
    color: Colors.dangerRed,
    fontWeight: '400',
  },
  bottomButton: {
    alignSelf: 'center',
    // minHeight: 44,
    // marginTop: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: "yellow"
  },
  editableTextContainer: {
    backgroundColor: Colors.lighterer,
    // paddingVertical: 5,
    // paddingHorizontal: 14,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderColor: Colors.dark,
  },
  editableText: {
    maxWidth: Platform.OS == 'web' ? 72 : 80,
    textAlign: 'right',
    fontSize: 18,
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
});

export default ModalStyle;
