import { StyleSheet, Dimensions, Platform } from 'react-native';
import Colors from '../constants/Colors';

const SettingsStyle = StyleSheet.create({
  container: { paddingBottom: 30 },
  SettingsBooleanField: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  SettingsTitle: {
    fontSize: 20,
    fontWeight: '500',
    color: Colors.darkest,
    marginBottom: 20,
    textAlign: 'center',
  },
  SettingsItem: { marginBottom: 20 },
  SettingsItemTitle: {
    fontSize: 14,
    fontWeight: '700',
    color: Colors.darker,
    marginBottom: 10,
  },
  settingsItemInputRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  settingsItemTextInput: {
    width: 60,
    borderBottomColor: Colors.lighter,
    fontSize: 14,
    color: Colors.dark,
    fontWeight: '500',
    backgroundColor: Colors.lightererer,
    borderRadius: 7,
    paddingHorizontal: 4,
    // paddingVertical: 4,
    height: 26,
    textAlign: 'center',
  },
  settingsItemInputSubText: {
    fontSize: 14,
    paddingLeft: 12,
    paddingRight: 10,
    color: Colors.darker,
  },
  profileSubTitle: {
    fontSize: 22,
    color: Colors.darkest,
    fontWeight: '500',
    marginTop: 16,
    marginBottom: 16,
  },
});

export default SettingsStyle;
