/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Animated,
  Easing,
  PanResponder,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import Colors from '../constants/Colors';

export default class TimeRangeSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pinFromPosition: new Animated.Value(0),
      pinToPosition: new Animated.Value(0),
      scrollEnabled: true,
      scrollPosition: 0,
    };

    this._from = 0;
    this._to = 0;
    this._scrollOffset = 0;

    const { pinFromPosition, pinToPosition } = this.state;
    this.panResponderLeft = PanResponder.create({
      onStartShouldSetPanResponder: (event, gesture) => {
        event.preventDefault();
        return true;
      },
      onPanResponderGrant: () => {
        this.setState({
          scrollEnabled: false,
        });
        pinFromPosition.extractOffset();
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderMove: (event, gesture) => {
        this.setPosition('from', gesture, false);
      },
      onPanResponderRelease: (event, gesture) => {
        this.setState({
          scrollEnabled: true,
        });
        this.setPosition('from', gesture, true);
      },
    });

    this.panResponderRight = PanResponder.create({
      onStartShouldSetPanResponder: (event, gesture) => {
        event.preventDefault();
        return true;
      }, // onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        this.setState({
          scrollEnabled: false,
        });
        pinToPosition.extractOffset();
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderMove: (event, gesture) => {
        this.setPosition('to', gesture, false);
      },
      onPanResponderRelease: (event, gesture) => {
        this.setState({
          scrollEnabled: true,
        });
        this.setPosition('to', gesture, true);
      },
    });

    this.panResponderMiddle = PanResponder.create({
      onStartShouldSetPanResponder: (event, gesture) => {
        event.preventDefault();
        return true;
      }, // onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        this.setState({
          scrollEnabled: false,
        });
        pinToPosition.extractOffset();
        pinFromPosition.extractOffset();
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderMove: (event, gesture) => {
        pinToPosition.setValue(gesture.dx);
        pinFromPosition.setValue(gesture.dx);
      },
      onShouldBlockNativeResponder: () => false,
      onPanResponderRelease: (event, gesture) => {
        this.setPosition('center', gesture, true);
        this.setState({
          scrollEnabled: true,
        });
      },
    });
  }

  componentDidMount() {
    const { pinToPosition, pinFromPosition } = this.state;

    const { hourWidth } = this.props;

    const initialValueFrom = hourWidth;
    const initialValueTo = hourWidth * 3;

    pinFromPosition.setValue(initialValueFrom);
    pinToPosition.setValue(initialValueTo);

    this._from = initialValueFrom;
    this._to = initialValueTo;

    pinFromPosition.addListener(({ value }) => (this._from = value));
    pinToPosition.addListener(({ value }) => (this._to = value));
    this.computeTimeInterval();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.scrollPosition !== this.state.scrollPosition) {
      return false;
    }
    return true;
  }

  setPosition = (type, gesture, animate) => {
    const { pinFromPosition, pinToPosition } = this.state;

    const {
      rangeFrom,
      rangeTo,
      hourWidth,
      snapInterval,
      minRange,
    } = this.props;

    const snapWidth = hourWidth * (snapInterval / 60);
    const range = {
      from: moment(rangeFrom),
      to: moment(rangeTo),
    };

    const hours =
      moment()
        .range(range.from, range.to)
        .diff('hours') + 1;

    const max = hours * hourWidth;
    const min = hourWidth;
    const minIntervalWidth = hourWidth * (minRange / 60);

    if (!animate) {
      if (type === 'from' || type === 'center') {
        pinFromPosition.setValue(gesture.dx);
      }

      if (type === 'to' || type === 'center') {
        pinToPosition.setValue(gesture.dx);
      }
    } else {
      let newValueFrom = Math.round(this._from / snapWidth) * snapWidth;
      newValueFrom = Math.max(
        Math.min(newValueFrom, max - minIntervalWidth),
        min,
      );
      let newValueTo = Math.round(this._to / snapWidth) * snapWidth;
      newValueTo = Math.min(Math.max(newValueTo, min + minIntervalWidth), max);

      if (newValueFrom > newValueTo) {
        const temp = newValueFrom;
        newValueFrom = newValueTo;
        newValueTo = temp;
      }

      pinFromPosition.flattenOffset();
      Animated.timing(pinFromPosition, {
        toValue: newValueFrom,
        duration: 120,
        useNativeDriver: false,
        easing: Easing.in(Easing.elastic(0.5)),
      }).start();
      pinToPosition.flattenOffset();

      Animated.timing(pinToPosition, {
        toValue: newValueTo,
        duration: 120,
        useNativeDriver: false,
        easing: Easing.in(Easing.elastic(0.5)),
      }).start();
      setTimeout(() => {
        this.computeTimeInterval();
      }, 200);
    }
  };

  computeTimeInterval = () => {
    const { rangeFrom, hourWidth, onIntervalUpdate } = this.props;

    const timeFrom = moment(rangeFrom)
      .add(((this._from - hourWidth) / hourWidth) * 60, 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');

    const timeTo = moment(rangeFrom)
      .add(((this._to - hourWidth) / hourWidth) * 60, 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');

    onIntervalUpdate(timeFrom, timeTo);
  };

  render() {
    const { pinFromPosition, pinToPosition, scrollEnabled } = this.state;
    const {
      rangeFrom,
      rangeTo,
      hourWidth,
      snapInterval,
      showTicks,
      showControlls,
      height,
      fontSize,
    } = this.props;

    const range = {
      from: moment(rangeFrom),
      to: moment(rangeTo),
    };

    const diff = moment()
      .range(range.from, range.to)
      .diff('hours');

    const hoursArray = [];
    for (let i = range.from.hour() - 1; i <= range.from.hour() + diff; i += 1) {
      hoursArray.push(i % 24);
    }
    return (
      <View style={{ height: height, flexDirection: 'row' }}>
        {showControlls ? (
          <TouchableOpacity
            style={{
              // backgroundColor: 'rgba(9,125,255,0.9)',
              height: height,
              width: 46,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 5,
              marginHorizontal: 2,
            }}
            onPress={() => {
              const newPosition = Math.max(
                0,
                this.state.scrollPosition - hourWidth * 1.25,
              );
              this.scrollView.getNode().scrollTo({ x: newPosition });
              this.setState({
                scrollPosition: newPosition,
              });
            }}
          >
            <Ionicons name="chevron-back" size={23} color="rgba(9,125,255,1)" />
          </TouchableOpacity>
        ) : null}

        <Animated.ScrollView
          style={{
            height: height,
            flexDirection: 'row',
          }}
          ref={ref => (this.scrollView = ref)}
          horizontal
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={16}
          onScroll={event => {
            this._scrollOffset = event.nativeEvent.contentOffset.x;
            this.setState({
              scrollPosition: event.nativeEvent.contentOffset.x,
            });
          }}
          scrollEnabled={scrollEnabled}
        >
          {hoursArray.map((x, key) => {
            return (
              <View
                key={`${key}`}
                style={{
                  width: hourWidth,
                  justifyContent: 'flex-end',
                  flex: 1,
                }}
              >
                <View
                  style={{
                    width: 50,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: -25,
                    // backgroundColor:"red"
                  }}
                >
                  {key === 0 ? null : (
                    <Text
                      style={{ fontSize, color: '#0d1e41' }}
                      selectable={false}
                    >
                      {x}:00
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    height: 12,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    // backgroundColor:"red"
                  }}
                >
                  <View
                    style={{
                      width: 1,
                      height: key === 0 ? 0 : 9,
                      backgroundColor: '#989ea8',
                    }}
                  />

                  {showTicks
                    ? [...Array(60 / snapInterval - 1)].map((y, index) => {
                        return (
                          <View
                            key={`${index}`}
                            style={{
                              width: 1,
                              height: 5,
                              backgroundColor: '#d3d8de',
                              // marginLeft: 20
                            }}
                          />
                        );
                      })
                    : null}
                  <View />
                </View>
              </View>
            );
          })}
          <Animated.View
            {...this.panResponderMiddle.panHandlers}
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(9,125,255,0.08)',
              width: Animated.add(
                pinToPosition,
                Animated.multiply(pinFromPosition, new Animated.Value(-1)),
              ),
              height: height,
              left: Animated.add(0, pinFromPosition),
              marginHorizontal: 7,
            }}
          />
          <Animated.View
            style={[
              styles.indicatorContainer,
              { left: pinFromPosition, height: height },
            ]}
            {...this.panResponderLeft.panHandlers}
          >
            <View style={styles.indicator} />
          </Animated.View>

          <Animated.View
            style={[
              styles.indicatorContainer,
              { left: pinToPosition, height: height },
            ]}
            {...this.panResponderRight.panHandlers}
          >
            <View style={styles.indicator} />
          </Animated.View>
        </Animated.ScrollView>
        {showControlls ? (
          <TouchableOpacity
            style={{
              // backgroundColor: 'rgba(9,125,255,0.9)',
              // backgroundColor: '#555',
              height: height,
              width: 46,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 5,
              marginHorizontal: 2,
            }}
            onPress={() => {
              const newPosition = this.state.scrollPosition + hourWidth * 1.25;
              this.scrollView.getNode().scrollTo({ x: newPosition });
              this.setState({
                scrollPosition: newPosition,
              });
            }}
          >
            <Ionicons
              name="chevron-forward"
              size={23}
              color="rgba(9,125,255,1)"
            />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
}

TimeRangeSelector.defaultProps = {
  rangeFrom: '01-01-1970 08:00',
  rangeTo: '01-01-1970 20:00',
  hourWidth: 100,
  selectedFrom: '01-01-1970 07:00',
  selectedTo: '01-01-1970 09:00',
  snapInterval: 15,
  minRange: 30,
  showTicks: true,
  height: 35,
  fontSize: 14,
  onIntervalUpdate: () => {},
};

TimeRangeSelector.propTypes = {
  rangeFrom: PropTypes.string,
  rangeTo: PropTypes.string,
  hourWidth: PropTypes.number,
  selectedFrom: PropTypes.string,
  selectedTo: PropTypes.string,
  snapInterval: PropTypes.number,
  minRange: PropTypes.number,
  showTicks: PropTypes.bool,
  onIntervalUpdate: PropTypes.func,
  height: PropTypes.number,
  fontSize: PropTypes.number,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  indicatorContainer: {
    position: 'absolute',
    width: 34,
    // height: 38,
    marginLeft: -17,
    alignItems: 'center',
  },
  indicator: {
    width: 18,
    flex: 1,
    backgroundColor: 'rgba(9,125,255,0.65)',
    borderRadius: 2,
  },
});
