import React from 'react';
import {
  KeyboardAvoidingView,
  Modal,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import ModalStyle from '../../../styles/ModalStyle';
import { Portal } from 'react-native-paper';

const ModalWrapper = props => {
  const { children, onClose, isVisible, title, disableScroll } = props;
  return (
    <Modal
      // isVisible={isVisible}
      // backdropOpacity={0.65}
      // animationIn="fadeIn"
      // animationInTiming={200}
      // animationOut="fadeOut"
      // animationOutTiming={500}
      // onBackdropPress={() => onClose()}
      // coverScreen
      // scrollHorizontal
      // backdropTransitionOutTiming={0}
      // hideModalContentWhileAnimating
      // style={{ margin: 0, alignItems: 'center', justifyContent: 'center' }}
      style={{
        margin: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      statusBarTranslucent
      animationType={'fade'}
      transparent
      visible={isVisible}
      onRequestClose={() => onClose()}
      supportedOrientations={['portrait', 'landscape']}
    >
      <Portal.Host>
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0, 0.4)',
            // backgroundColor: 'red',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 30,
          }}
        >
          <KeyboardAvoidingView behavior={'padding'}>
            <View style={ModalStyle.modalContainer}>
              <View style={ModalStyle.headerBar}>
                <View style={ModalStyle.headerNavigation} />
                <Text style={ModalStyle.headerTitle}>{title}</Text>
                <TouchableOpacity
                  style={ModalStyle.headerNavigation}
                  onPress={() => onClose()}
                >
                  <Ionicons name="md-close" size={24} color="#ccc" />
                </TouchableOpacity>
              </View>
              <ScrollView
                style={{ paddingHorizontal: 25, maxHeight: '90%' }}
                scrollEnabled={!disableScroll}
                // contentContainerStyle={{ maxHeight: '90%' }}
              >
                {children}
              </ScrollView>
            </View>
          </KeyboardAvoidingView>
        </View>
      </Portal.Host>
    </Modal>
  );
};

export default ModalWrapper;
