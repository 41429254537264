import {
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import React, { useContext, useState } from 'react';
import Colors from '../../constants/Colors';
import Style from '../../constants/Style';
import GLOBAL from '../../constants/Globals';

import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { GlobalContext, withGlobalContext } from '../../GlobalContext';
import NavigationService from '../../services/NavigationService';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const MenuItem = props => {
  const {
    selected,
    text,
    icon,
    iconSelected,
    onPress,
    lastItem,
    logout,
  } = props;
  return (
    <TouchableOpacity
      style={[
        styles.menuItemContainer,
        lastItem && styles.menuItemContainerLast,
      ]}
      onPress={onPress}
    >
      <Ionicons
        name={selected ? iconSelected : icon}
        size={22}
        color={selected ? 'rgb(77,124,206)' : 'rgb(77,77,116)'}
        style={[styles.menuItemIcon, logout && { color: Colors.dangerRed }]}
      />
      <Text
        style={[
          styles.menuItemText,
          selected && styles.menuItemTextSelected,
          logout && { color: Colors.dangerRed },
        ]}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const SideMenuWrapper = props => {
  const global = useContext(GlobalContext);
  const { children, route, title, showMenuDefault } = props;

  const [showMenu, setShowMenu] = useState(showMenuDefault);

  const windowWidth = useWindowDimensions().width;

  const isNarrow = windowWidth < 800;

  const navigate = routeName => {
    if (route !== routeName) {
      NavigationService.navigate(routeName);
    }
    setShowMenu(false);
  };

  return (
    <View style={[styles.container, isNarrow && styles.containerMobile]}>
      <SafeAreaView
        style={styles.innerContainer}
        edges={['top', 'left', 'right']}
      >
        {isNarrow || <Text style={styles.h1}>{title}</Text>}

        <View
          style={[
            styles.centerContainer,
            isNarrow && styles.centerContainerMobile,
            !isNarrow && Style.shadowX,
          ]}
        >
          {(isNarrow && !showMenu) || (
            <View
              style={
                isNarrow ? styles.menuContainerMobile : styles.menuContainer
              }
            >
              <View>
                <MenuItem
                  text={'Reservations'}
                  icon={'bookmark-outline'}
                  iconSelected={'bookmark'}
                  onPress={() => navigate('reservations')}
                  selected={!isNarrow && route === 'reservations'}
                />
                <MenuItem
                  text={'Profile'}
                  icon={'earth-outline'}
                  iconSelected={'earth'}
                  selected={!isNarrow && route === 'profile'}
                  onPress={() => navigate('profile')}
                />
                <MenuItem
                  text={'Open Hours'}
                  icon={'time-outline'}
                  iconSelected={'time-outline'}
                  onPress={() => navigate('openHours')}
                  selected={!isNarrow && route === 'openHours'}
                />
                <MenuItem
                  text={'Closed Days'}
                  icon={'calendar-outline'}
                  iconSelected={'calendar-outline'}
                  selected={!isNarrow && route === 'holidays'}
                  onPress={() => navigate('holidays')}
                  lastItem
                />
              </View>

              <View>
                <MenuItem
                  text={'Privacy Policy'}
                  icon={'information-circle-outline'}
                  iconSelected={'information-circle'}
                  onPress={() => {
                    Linking.openURL(GLOBAL.PRIVACY_URL);
                  }}
                />
                <MenuItem
                  text={'Terms & Conditions'}
                  icon={'information-circle-outline'}
                  iconSelected={'information-circle'}
                  onPress={() => {
                    Linking.openURL(GLOBAL.TERMS_URL);
                  }}
                />
                <MenuItem
                  text={'Logout'}
                  icon={'log-out-outline'}
                  iconSelected={'log-out'}
                  onPress={() => global.logout()}
                  logout
                  lastItem
                />
              </View>
            </View>
          )}
          {(isNarrow && showMenu) || (
            <View
              style={{
                width: isNarrow ? '100%' : 500,
                // paddingHorizontal: isNarrow ? 16 : 0,
              }}
            >
              {isNarrow && (
                <View style={styles.phoneMenuBarContainer}>
                  <TouchableOpacity
                    style={[
                      styles.phoneMenuBarItem,
                      {
                        marginLeft: -15,
                        paddingLeft: 10,
                        // backgroundColor: 'red',
                        height: 44,
                      },
                    ]}
                    onPress={() => setShowMenu(true)}
                  >
                    <Ionicons
                      name={'chevron-back'}
                      size={26}
                      color={Colors.darker}
                    />
                  </TouchableOpacity>
                  <Text style={styles.phoneMenuBarTitle}>{title}</Text>
                  <View style={styles.phoneMenuBarItem} />
                </View>
              )}
              <KeyboardAwareScrollView
                style={{
                  paddingTop: isNarrow ? 12 : 25,
                  paddingHorizontal: isNarrow ? 20 : 30,
                }}
              >
                {children}
              </KeyboardAwareScrollView>
            </View>
          )}
        </View>
      </SafeAreaView>
    </View>
  );
};

export default withGlobalContext(SideMenuWrapper);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(247,248,250)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerMobile: {
    backgroundColor: Colors.white,
  },
  innerContainer: {
    flex: 1,
    // minWidth: 800,
  },
  h1: {
    fontWeight: '600',
    fontSize: 30,
    marginTop: 30,
    marginBottom: 30,
    color: 'rgb(35,38,42)',
  },
  centerContainer: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: 'white',
    // backgroundColor: 'rgb(32, 93, 152)',
    borderRadius: 10,
    marginBottom: 30,
  },
  leftContainerStyle: {
    backgroundColor: 'rgb(32, 93, 152)',
  },
  centerContainerMobile: {
    borderRadius: 0,
    marginBottom: 0,
  },
  menuContainer: {
    // borderRightColor: Colors.lightBackground,
    // borderRightColor: 'rgb(32, 93, 152)',
    // borderRightWidth: 2,
    // backgroundColor: 'rgb(32, 93, 152)',
    overflow: 'hidden',

    paddingVertical: 6,
    justifyContent: 'space-between',
  },
  menuContainerMobile: {
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: 10,
  },
  menuItemContainer: {
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 10,
    marginHorizontal: 28,
    paddingVertical: 18,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightererer,
  },

  menuItemContainerLast: {
    borderBottomWidth: 0,
  },

  menuItemText: {
    fontSize: 16,
    // color: 'rgb(68,70,90)',
    color: 'rgb(13,13,19)',
    fontWeight: '500',
    paddingLeft: 24,
  },
  menuItemTextSelected: {
    color: 'rgb(22,22,31)',
    fontWeight: '600',
  },
  menuItemIcon: {
    height: 24,
    width: 22,
  },
  phoneMenuBarContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    marginHorizontal: 20,
    // paddingBottom: 10,
    height: 42,
    borderColor: Colors.lightererer,
  },
  phoneMenuBarItem: {
    width: 60,
    // paddingLeft: 12,
    justifyContent: 'center',
  },
  phoneMenuBarTitle: {
    fontSize: 17,
    fontWeight: '500',
    color: Colors.black,
  },
});
