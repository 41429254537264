import React from 'react';
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  Alert,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { TextInputMask } from 'react-native-masked-text';
import moment from 'moment';

import ReservationServices from '../../services/ReservationsServices';

const ICON_DARK = '#555';

const userIcon = (
  <Ionicons name="md-person" size={21} color={ICON_DARK} style={{ marginTop: 2 }} />
);

const timeIcon = (
  <Ionicons name="md-time" size={21} color={ICON_DARK} style={{ marginTop: 6 }} />
);
const phoneIcon = (
  <Ionicons name="ios-phone-portrait" size={23} color={ICON_DARK} />
);
const calendarIcon = (
  <Ionicons
    name="ios-calendar"
    size={21}
    color={ICON_DARK}
    style={{ marginTop: 6 }}
  />
);

export default class ConfirmReservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
    };
  }

  async submitReservation() {
    const {
      jwtToken,
      restaurantId,
      dateFrom,
      dateTo,
      persons,
      tableId,
      closeModal,
    } = this.props;
    const { name, phone } = this.state;

    const data = {};
    data.customer = { fullname: name, phone };
    data.restaurant_id = restaurantId;
    data.tables = [tableId];
    data.date_from = dateFrom.toISOString();
    data.date_to = dateTo.toISOString();
    data.persons = persons;

    const result = await ReservationServices.submitReservation(jwtToken, data);
    if (result.ok) {
      closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  render() {
    const { dateFrom, dateTo, persons } = this.props;

    if (!dateFrom) {
      return null;
    }

    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={{ flex: 1, justifyContent: 'flex-start', height: '100%' }}>
          <View
            style={{
              alignItems: 'center',
              marginTop: 40,
              marginBottom: 40,
            }}
          >
            <Text style={{ fontSize: 23, paddingTop: 0 }}>
              Reservation Overview
            </Text>
          </View>
          <View>
            <View style={styles.info_line}>
              <Text style={styles.info_left}>Name </Text>
              <TextInput
                style={[styles.info_right, { color: '#222' }]}
                onChangeText={name => this.setState({ name })}
                placeholder="Enter Full Name"
                value={this.state.name}
                selectTextOnFocus
                autoCapitalize="words"
                autoCorrect={false}
              />
            </View>
            <View style={styles.info_line}>
              <Text style={styles.info_left}>Phone</Text>
              <TextInputMask
                type="custom"
                options={{
                  mask: '999 999 999',
                }}
                style={[styles.info_right, { color: '#222' }]}
                onChangeText={phone => this.setState({ phone })}
                placeholder="(optional)"
                value={this.state.phone}
                selectTextOnFocus
                autoCorrect={false}
                textContentType="telephoneNumber"
                keyboardType="phone-pad"
              />
            </View>
            <View style={styles.info_line}>
              <Text style={styles.info_left}>Date </Text>
              <Text style={styles.info_right}>
                {' '}
                {dateFrom.format('DD. MMMM YYYY')}
                {'  '}
                {calendarIcon}
              </Text>
            </View>
            <View style={styles.info_line}>
              <Text style={styles.info_left}>Time </Text>
              <Text style={styles.info_right}>
                {dateFrom.format('HH:mm')} - {dateTo.format('HH:mm')}
                {'  '}
                {timeIcon}
              </Text>
            </View>

            <View style={[styles.info_line, { borderBottomWidth: 0 }]}>
              <Text style={styles.info_left}>Persons </Text>
              <Text style={styles.info_right}>
                {persons}
                {'  '}
                {userIcon}
              </Text>
            </View>
          </View>

          <TouchableOpacity
            style={styles.reservationContainer}
            onPress={() => this.submitReservation()}
          >
            <Text style={styles.reservationButtonText}>
              Confirm Reservation
            </Text>
          </TouchableOpacity>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  info_left: {
    fontSize: 18,
    fontWeight: '500',
    marginTop: 16,
    marginLeft: 12,
    flex: 2,
    flexDirection: 'row',
  },
  info_right: {
    marginRight: 12,
    fontSize: 18,
    fontWeight: '400',
    marginTop: 16,
    flex: 4,
    textAlign: 'right',
  },
  info_line: {
    flexDirection: 'row',
    borderBottomWidth: 0.75,
    borderBottomColor: '#ddd',
    paddingBottom: 12,
    marginHorizontal: 24,
  },

  reservationContainer: {
    margin: 40,
    marginHorizontal: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: 46,
    backgroundColor: '#23d160',
    // borderWidth: 1.3,
    borderColor: '#00f',
    // paddingVertical: 12,
    borderRadius: 45,
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: {
      height: 3,
      width: 3,
    },
    elevation: 2,
  },
  reservationButtonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 18,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginLeft: 12,
    marginRight: 0,
  },
});
