import React from 'react';
import {
  Alert,
  Keyboard,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  Animated,
  Switch,
} from 'react-native';

import Slider from '@react-native-community/slider';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import CountIncreaser from '../../../components/CountIncreaser';
import Colors from '../../../constants/Colors';

import ModalStyle from '../../../styles/ModalStyle';
import Buttons from '../../../styles/Buttons';
import TableServices from '../../../services/TablesServices';

export default class OptionsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backdropOpacity: new Animated.Value(1),
      modalOpacity: new Animated.Value(1),
    };
  }

  closeModal = force => {
    const { hideModal } = this.props;
    hideModal(force);
  };

  changeBackdrop = (value, value2) => {
    console.log('wht', value);
    Animated.timing(this.state.backdropOpacity, {
      toValue: value,
      duration: 300,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.state.modalOpacity, {
      toValue: value2,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  updateLength = value => {
    this.changeBackdrop(0.2, 0.99);
    this.props.updateHourLength(value);
  };
  updateHeight = value => {
    this.changeBackdrop(0.2, 0.99);

    this.props.updateRowHeight(value);
  };

  render() {
    const { isVisible, portrait } = this.props;
    const { closeModal } = this;

    return (
      <Modal
        isVisible={isVisible}
        // backdropOpacity={this.state.backdropOpacity}
        customBackdrop={
          <TouchableWithoutFeedback
            style={{ flex: 1 }}
            onPress={this.closeModal}
          >
            <Animated.View
              style={{
                flex: 1,
                backgroundColor: 'black',
                opacity: this.state.backdropOpacity,
              }}
            />
          </TouchableWithoutFeedback>
        }
        animationIn="fadeIn"
        animationInTiming={100}
        animationOut="fadeOut"
        animationOutTiming={300}
        // onBackdropPress={this.closeModal}
        coverScreen
        // scrollHorizontal
        backdropTransitionOutTiming={0}
        // hideModalContentWhileAnimating
      >
        <Animated.View
          style={[
            ModalStyle.modalContainer,
            {
              // margin: 20,
              // marginLeft: 0,
              width: portrait ? '100%' : 420,
              height: 225,
              // flex: 1,
              opacity: this.state.modalOpacity,
            },
          ]}
        >
          <View style={ModalStyle.headerBar}>
            <View style={ModalStyle.headerNavigation} />
            <Text style={ModalStyle.headerTitle}>Options</Text>
            <TouchableOpacity
              style={ModalStyle.headerNavigation}
              onPress={() => closeModal()}
            >
              <Ionicons name="md-close" size={29} color="#ccc" />
            </TouchableOpacity>
          </View>
          <View style={{ marginHorizontal: 20, flex: 1 }}>
            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Width</Text>
                <View style={ModalStyle.touchableSelect}>
                  <View
                    style={{
                      width: 15,
                      height: 5,
                      borderRadius: 2,
                      backgroundColor: Colors.light,
                    }}
                  />
                  <Slider
                    style={{ width: 130, height: 30, marginHorizontal: 12 }}
                    minimumValue={26}
                    maximumValue={252}
                    step={24}
                    value={this.props.hourLength}
                    onValueChange={value => this.updateLength(value)}
                    onSlidingComplete={value => this.changeBackdrop(1, 1)}
                  />
                  <View
                    style={{
                      width: 32,
                      height: 5,
                      borderRadius: 2,
                      backgroundColor: Colors.light,
                    }}
                  />
                </View>
              </View>
            </View>

            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Height</Text>
                <View style={ModalStyle.touchableSelect}>
                  <Ionicons
                    name="reorder-four-outline"
                    size={24}
                    color={Colors.light}
                    style={{ height: 24, width: 24, textAlign: 'right' }}
                  />
                  <Slider
                    style={{ width: 130, height: 30, marginHorizontal: 12 }}
                    value={this.props.rowHeight}
                    minimumValue={26}
                    maximumValue={80}
                    step={6}
                    onValueChange={value => this.updateHeight(value)}
                    onSlidingComplete={value => this.changeBackdrop(1, 1)}
                  />
                  <Ionicons
                    name="reorder-two"
                    size={30}
                    color={Colors.light}
                    style={{ height: 30, width: 30, textAlign: 'center' }}
                  />
                </View>
              </View>
            </View>
            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Show Cancelled</Text>
                <View style={ModalStyle.touchableSelect}>
                  <Switch
                    onValueChange={this.props.hideCancelledSwitch}
                    value={!this.props.hideCancelled}
                    // trackColor={{ true: Colors.lightBlue }}
                  />
                </View>
              </View>
            </View>
          </View>
        </Animated.View>
      </Modal>
    );
  }
}
