import React from 'react';
import { Ionicons } from '@expo/vector-icons';

import Colors from '../constants/Colors';

export default function TabBarIcon(props) {
  const { name, size, focused, color } = props;
  return (
    <Ionicons
      name={name}
      size={size}
      style={{ marginBottom: -3 }}
      color={focused ? color : Colors.tabIconDefault}
    />
  );
}
