const tintColor = '#2f95dc';

const Colors = {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: 'red',
  // tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  lightBlue: '#1a87f5',
  darkBlue: '#007aff',
  yellow: '#ffd60a',
  confirmGreen: '#23d160',
  someGreen: '#3ddc65',
  lightGreen: '#30d158',
  dangerRed: '#ff453a',
  pastelRed: '#ff5047',
  // dark: '#555',
  dark: '#363d4d',
  // dark: '#ff0000',
  // darker: '#444',
  darker: '#282f34',
  darkest: '#121928',
  medium: '#666',
  // light: '#888',
  light: '#798896',
  placeholder: '#ccc',
  disabled: '#ccc',
  disabledLighter: '#e4e4e4',
  lighter: '#ccc',
  lighter2: '#a2acb4',
  // lighterer: '#e9e9e9',
  lighterer: '#e2e5e8',
  lightererer: '#eef2f5',
  // lightererer: '#f0f0f0',

  lightBorder: '#ccc',
  // lightest: '#fafafa',
  lightest: '#fbfbfb',
  twitterBlue: '#38A1F3',
  // lightBackground: '#f8f8f8',
  lightBackground: '#f7f7f8',
  white: 'white',
  black: 'black',
  lightBlueBackground: '#F8F9FB',
  darkBlueBackground: '#f5f6f8',
};

export default Colors;
