import React, { useContext, useState } from 'react';
import {
  Animated,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import moment from 'moment';

import { TextInputMask } from 'react-native-masked-text';
import CountIncreaser from '../../../components/CountIncreaser';
import Colors from '../../../constants/Colors';
import Buttons from '../../../styles/Buttons';
// import Collapsible from 'react-native-collapsible';
import { GlobalContext } from '../../../GlobalContext';
import ReservationServices from '../../../services/ReservationsServices';
import Collapsible from '../../../components/Collapsible';
import Style from '../../../constants/Style';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const NewMultitableReservation = props => {
  const {
    dateFrom,
    dateTo,
    selectedTables,
    panHandlers,
    vertical,
    expand,
  } = props;
  const global = useContext(GlobalContext);

  const [selectedPersons, setSelectedPersons] = useState(1);
  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const [phone, setPhone] = useState('');
  const [isNoteCollapsed, setIsNoteCollapsed] = useState(true);

  const updatePersonsCount = value => {
    setSelectedPersons(value);
  };

  const switchCollapse = () => {
    expand();
    setIsNoteCollapsed(prevState => !prevState);
  };

  const submitReservation = () => {
    const { restaurantId, jwtToken, showSnackbarMessage } = global;

    const { onSuccess } = props;

    const data = {};
    data.customer = { fullname: name, phone };
    data.restaurant_id = restaurantId;
    if (selectedTables.length === 0) {
      showSnackbarMessage(
        `Error: Please select at least one table for reservation.`,
      );
      return null;
    }
    if (name == null || name === '') {
      showSnackbarMessage(`Error: Please enter name`);
      return null;
    }
    data.tables = selectedTables.map(table => {
      return table.table_id;
    });
    data.date_from = dateFrom;
    data.date_to = dateTo;
    data.persons = selectedPersons;
    data.note = note;

    ReservationServices.submitReservation(jwtToken, data).then(result => {
      if (result.ok) {
        onSuccess();
      } else {
        showSnackbarMessage(`Something went wrong: ${result.message}`);
      }
    });
  };

  const chairsMax = selectedTables.reduce(
    (accumulator, current) => accumulator + current.chairs.count,
    0,
  );

  const selectedTablesX =
    selectedTables.length > 0 ? selectedTables : [{ table_number: 'Ø' }];

  const selectedTableNumbers = selectedTablesX.map(table => {
    return (
      <View
        key={table.table_number}
        style={{
          backgroundColor: Colors.lighterer,
          borderRadius: 5,
          paddingVertical: 5,
          paddingHorizontal: 8,
          marginHorizontal: 2,
        }}
      >
        <Text style={{ color: Colors.dark, fontWeight: '600', fontSize: 15 }}>
          {table.table_number}
        </Text>
      </View>
    );
  });

  const chairsOptions = [];
  chairsOptions[0] = {
    label: 1,
    key: 1,
    value: 1,
  };

  for (let i = -1; i < chairsMax; i += 1) {
    chairsOptions[i] = {
      label: i + 1,
      key: i + 1,
      value: i + 1,
    };
  }

  return (
    <Animated.View
      style={[
        {
          flex: 1,
          paddingLeft: 20,
          backgroundColor: 'white',
          borderTopLeftRadius: vertical ? 8 : 0,
          borderTopRightRadius: vertical ? 8 : 0,
          paddingTop: vertical ? 2 : 0,
        },
        Style.shadowDown,
      ]}
    >
      {vertical && (
        <View
          style={{
            position: 'absolute',
            top: 6,
            left: 0,
            right: 0,
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: 38,
              height: 5,
              backgroundColor: '#eee',
              borderRadius: 30,
            }}
          />
        </View>
      )}
      <Animated.View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 50,
          borderBottomWidth: 0.5,
          borderColor: Colors.lighterer,
          paddingLeft: 0,
          // paddingRight: 26,
          marginRight: 26,
        }}
        {...panHandlers}
      >
        <Animated.Text
          style={{
            color: Colors.dark,
            fontSize: 15,
            fontWeight: '500',
          }}
          numberOfLines={1}
          selectable={false}
        >
          NEW RESERVATION
        </Animated.Text>
        <Text> </Text>
      </Animated.View>
      <KeyboardAwareScrollView
        style={{ paddingLeft: 0, paddingRight: 20, flex: 1 }}
        viewIsInsideTabBar
        extraHeight={vertical ? 270 : 200}
        onKeyboardWillShow={frames => {
          expand();
        }}
      >
        <View style={styles.info_line}>
          <Text style={styles.info_left}>Date</Text>
          <Text style={styles.info_right}>
            {moment(dateFrom).format('D. MMMM YYYY')}
          </Text>
        </View>
        <View style={styles.info_line}>
          <Text style={styles.info_left}>Time</Text>
          <Text style={styles.info_right}>
            {moment(dateFrom).format('HH:mm')}
            <Text style={{ color: '#ccc', fontWeight: '600' }}> - </Text>
            {moment(dateTo).format('HH:mm')}
          </Text>
        </View>

        <View style={[styles.info_line, { borderBottomWidth: 0 }]}>
          <Text style={styles.info_left}>Tables</Text>
          <View style={{ flexDirection: 'row', marginTop: 18 }}>
            {selectedTableNumbers}
          </View>
        </View>
        <View style={styles.info_line}>
          <Text style={styles.info_left}>Name </Text>
          <TextInput
            style={[styles.info_right]}
            onChangeText={name => setName(name)}
            placeholder="Fullname"
            placeholderTextColor={Colors.placeholder}
            value={name}
            autoCapitalize="words"
            autoCorrect={false}
          />
        </View>
        <View style={styles.info_line}>
          <Text style={styles.info_left}>Phone</Text>
          <TextInputMask
            type="custom"
            options={{
              mask: '999 999 999',
            }}
            style={[styles.info_right]}
            onChangeText={phone => setPhone(phone)}
            placeholder="Optional"
            placeholderTextColor={Colors.placeholder}
            value={phone}
            selectTextOnFocus
            autoCorrect={false}
            textContentType="telephoneNumber"
            keyboardType="phone-pad"
          />
        </View>
        <View style={[styles.info_line, { borderBottomWidth: 0 }]}>
          <Text style={styles.info_left}>Persons </Text>
          <View style={styles.info_right}>
            <CountIncreaser
              initialIndex={Math.max(chairsMax - 1, 0)}
              optionsArray={chairsOptions}
              updateValueFunc={value => updatePersonsCount(value)}
              circleBorder={0}
              textColor="#000"
              iconSize={24}
              addIcon="ios-add"
              removeIcon="ios-remove"
              valueWidth={50}
            />
          </View>
        </View>

        <View style={[styles.info_line, { borderBottomWidth: 0 }]}>
          <Text style={styles.info_left}>Note</Text>
          <TouchableOpacity onPress={() => switchCollapse()}>
            <Text style={[styles.info_right, { color: Colors.lightBlue }]}>
              {isNoteCollapsed ? 'add a note' : null}
            </Text>
          </TouchableOpacity>
        </View>
        <Collapsible
          collapsed={isNoteCollapsed}
          duration={500}
          collapsedHeight={0}
          style={{ flex: 1 }}
        >
          <View
            style={[
              {
                borderWidth: 0,
                paddingLeft: 6,
                paddingRight: 6,
                alignItems: null,
                marginTop: 6,
              },
            ]}
          >
            <TextInput
              multiline
              placeholder="Enter additional informations..."
              onChangeText={text => setNote(text)}
              value={note}
              style={{
                marginVertical: 10,
                // borderWidth: 1,
                fontSize: 16,
                // fontWeight: fontWeight,
                height: 100,
                // flex: 1,
                color: Colors.dark,
              }}
            />
          </View>
        </Collapsible>

        <View
          style={[
            Buttons.container,
            { marginTop: 20, paddingHorizontal: 7, marginBottom: 12 },
          ]}
        >
          <TouchableOpacity
            style={[Buttons.big, Buttons.green]}
            onPress={() => submitReservation()}
          >
            <Text style={Buttons.text}>Confirm reservation</Text>
          </TouchableOpacity>
        </View>
        {/*<View style={{ height: 200 }} />*/}
      </KeyboardAwareScrollView>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  info_left: {
    fontSize: 16,
    fontWeight: '500',
    marginTop: 16,
    // marginLeft: 12,
    flex: 2,
    flexDirection: 'row',
    // backgroundColor:"blue",
  },
  info_right: {
    // backgroundColor:"yellow",
    fontSize: 16,
    fontWeight: '400',
    marginTop: 16,
    flex: 4,
    textAlign: 'right',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    color: Colors.darker,
  },
  info_line: {
    paddingLeft: 6,
    paddingRight: 6,

    flexDirection: 'row',
    // borderBottomWidth: 0.75,
    borderBottomColor: '#ddd',
    // paddingBottom: 12,
    alignItems: 'center',
    height: 45,
    // backgroundColor:"green",
  },

  reservationContainer: {
    margin: 40,
    marginHorizontal: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: 46,
    // backgroundColor: '#23d160',
    // borderWidth: 1.3,
    borderColor: '#00f',
    // paddingVertical: 12,
    borderRadius: 45,
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: {
      height: 3,
      width: 3,
    },
    elevation: 2,
  },
  reservationButtonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 16,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
  },
  listItem: {
    borderBottomWidth: 1,
    // borderBottomColor: '#ddd',
    marginLeft: 12,
    marginRight: 0,
  },
});

// const NewMultitableReservation2 = withGlobalContext(NewMultitableReservation);
// NewMultitableReservation2.navigationOptions = {
//   header: null,
// };
export default NewMultitableReservation;
