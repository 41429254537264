import React, { useContext, useState } from 'react';
import {
  Alert,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import CountIncreaser from '../../../components/CountIncreaser';
import Colors from '../../../constants/Colors';

import ModalStyle from '../../../styles/ModalStyle';
import Buttons from '../../../styles/Buttons';
import { GlobalContext } from '../../../GlobalContext';
import ModalWrapper from '../components/ModalWrapper';
import Collapsible from '../../../components/Collapsible';
import TableServices from '../../../services/TablesServices';
import AreYouSureWrapper from '../../../components/AreYouSureWrapper';
import { Ionicons } from '@expo/vector-icons';
import Table from '../../Spaces/RoomsComponents/Table';

const Row = ({ children, high, last }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: high ? 150 : 50,
        // borderBottomWidth: last ? 0 : 1,
        // borderBottomColor: Colors.lightererer,
      }}
    >
      {children}
    </View>
  );
};

const RenderModels = props => {
  const { models, chairs, changeModelId, selectedModelId } = props;
  return models.map((model, key) => {
    if (model?.chairs?.count === chairs) {
      return (
        <TouchableOpacity
          onPress={() => changeModelId(model.id)}
          key={key}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 150,
            height: 150,
            // backgroundColor: key == 1 && Colors.lighterer,
            borderRadius: 10,
          }}
        >
          <Table
            isRound={model.dimensions.round}
            maxWidth={120}
            maxHeight={120}
            selected={model.id === selectedModelId}
            tableWidth={model.dimensions.width}
            tableLength={model.dimensions.length}
            chairsTop={model.chairs.top}
            chairsBottom={model.chairs.bottom}
            chairsLeft={model.chairs.left}
            chairsRight={model.chairs.right}
            chairsCount={model.chairs.count}
            bottomText={model.name}
          />
        </TouchableOpacity>
      );
    }
  });
};

const AddEditTableModal = props => {
  const { visible, close, groupName, groupId, editTable } = props;
  const global = useContext(GlobalContext);
  const { models } = global;

  const [tableNumber, setTableNumber] = useState(
    editTable?.table_number || null,
  );
  const [chairs, setChairs] = useState(editTable ? editTable?.chairs : 4);
  const [selectedModelId, setSelectedModelId] = useState(
    editTable ? editTable?.table_model?.id : null,
  );

  const chairsOptions = [];
  for (let i = 0; i < 50; i += 1) {
    chairsOptions[i] = {
      label: i + 1,
      key: i + 1,
      value: i + 1,
    };
  }

  const addTable = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      updateTables,
      showSnackbarMessage,
    } = global;

    const data = {};
    data.tableNumber = tableNumber;
    data.selectedGroupId = groupId;
    data.modelId = selectedModelId;
    data.chairs = chairs;

    TableServices.addNewTable(jwtToken, restaurantId, data).then(result => {
      if (result.ok) {
        showSnackbarMessage(`Table ${tableNumber} was added.`);
        updateTableGroups();
        updateTables();
        close();
      } else {
        Alert.alert('Something went wrong', result.message);
      }
    });
  };

  const update = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      updateTables,
      showSnackbarMessage,
    } = global;

    const data = {};
    data.tableNumber = tableNumber;
    data.selectedGroupId = groupId;
    data.modelId = selectedModelId;
    data.chairs = chairs;

    TableServices.updateTable(jwtToken, restaurantId, data, editTable.id).then(
      result => {
        if (result.ok) {
          showSnackbarMessage(`Table ${tableNumber} was updated.`);
          updateTableGroups();
          updateTables();
          close();
        } else {
          Alert.alert('Something went wrong', result.message);
        }
      },
    );
  };

  const deleteTable = () => {
    const {
      jwtToken,
      updateTableGroups,
      updateTables,
      showSnackbarMessage,
    } = global;

    TableServices.removeTable(jwtToken, editTable.id).then(result => {
      if (result.ok) {
        showSnackbarMessage(`Table ${tableNumber} was deleted.`);
        updateTableGroups();
        updateTables();
        close();
      } else {
        Alert.alert('Something went wrong', result.message);
      }
    });
  };

  const changeModelId = id => {
    setSelectedModelId(id);
  };

  const setChair = count => {
    setSelectedModelId(null);
    setChairs(count);
  };

  const selectedModel = models.find(x => x.id === selectedModelId);

  const [modalVisible, setModalVisible] = useState(true);

  return (
    <ModalWrapper
      onClose={close}
      title={editTable ? 'Edit Table' : 'New Table'}
      isVisible={visible && modalVisible}
    >
      <Row>
        <Text style={ModalStyle.fontLeft}>Group</Text>
        <Text style={[ModalStyle.fontRight, { color: Colors.darker }]}>
          {groupName || ''}
        </Text>
      </Row>

      <Row>
        <Text style={ModalStyle.fontLeft}>Number</Text>
        <View style={ModalStyle.touchableSelect}>
          <TextInput
            keyboardType="numeric"
            style={[
              ModalStyle.fontRight,
              ModalStyle.inputRight,
              { color: '#222', paddingVertical: 4 },
            ]}
            placeholderTextColor={Colors.placeholder}
            onChangeText={value => {
              const number = parseInt(value, 10);
              setTableNumber(Number.isNaN(number) ? null : number);
            }}
            placeholder="ex. 42"
            value={tableNumber?.toString()}
          />
        </View>
      </Row>

      <Row>
        <Text style={ModalStyle.fontLeft}>Chairs</Text>
        <CountIncreaser
          initialIndex={Math.max(chairs - 1, 0)}
          optionsArray={chairsOptions}
          updateValueFunc={value => setChair(value)}
          circleBorder={0}
          valueWidth={50}
          iconSize={24}
        />
      </Row>

      <Row last>
        <Text style={ModalStyle.fontLeft}>Model</Text>
        {selectedModel ? (
          <TouchableOpacity onPress={() => setSelectedModelId(null)}>
            <Table
              maxWidth={70}
              maxHeight={48}
              tableWidth={selectedModel?.dimensions?.width}
              tableLength={selectedModel?.dimensions?.length}
              isRound={selectedModel?.dimensions?.round}
              chairsTop={selectedModel.chairs.top}
              chairsBottom={selectedModel.chairs.bottom}
              chairsLeft={selectedModel.chairs.left}
              chairsRight={selectedModel.chairs.right}
              chairsCount={selectedModel.chairs.count}
              tableNumber={selectedModel.table_number}
            />
          </TouchableOpacity>
        ) : (
          <Text style={[ModalStyle.fontRight, { color: Colors.lighter }]}>
            Select
          </Text>
        )}
      </Row>

      <Collapsible
        collapsed={!!selectedModelId}
        duration={500}
        collapsedHeight={0}
      >
        <Row high last>
          <ScrollView horizontal>
            <RenderModels
              models={models}
              chairs={chairs}
              selectedModelId={selectedModelId}
              changeModelId={changeModelId}
            />
          </ScrollView>
        </Row>
      </Collapsible>
      {editTable ? (
        <View style={Buttons.container}>
          <AreYouSureWrapper
            style={[Buttons.box]}
            onConfirm={() => deleteTable()}
            title={'Remove this table'}
            confirmText={'Remove'}
            cancelText={'Cancel'}
            message={'Are you sure you want to remove this table?'}
            // onPress={() => setModalVisible(false)}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Ionicons name="trash-bin-outline" size={20} color="red" />
              <Text style={[Buttons.text, { color: 'red' }]}>Remove table</Text>
            </View>
          </AreYouSureWrapper>
          <View style={{ width: 20 }} />
          <TouchableOpacity style={[Buttons.big]} onPress={() => update()}>
            <Text style={Buttons.text}>Save</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={Buttons.container}>
          <TouchableOpacity
            style={[Buttons.big, Buttons.green]}
            onPress={() => addTable()}
          >
            <Text style={Buttons.text}>Add Table</Text>
          </TouchableOpacity>
        </View>
      )}
    </ModalWrapper>
  );
};
export default AddEditTableModal;
