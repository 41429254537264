import React, { useContext, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import moment from 'moment';
import { GlobalContext } from '../../GlobalContext';

import SideMenuWrapper from './SideMenuWrapper';
import SettingsStyle from '../../styles/SettingsStyle';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { Calendar } from 'react-native-calendars';
import OpenHoursServices from '../../services/OpenHoursServices';
import { Ionicons } from '@expo/vector-icons';

const GLOBAL = require('../../constants/Globals');

const updateClosedDays = async (days, global) => {
  const { showSnackbarMessage } = global;
  const result = await OpenHoursServices.updateClosedDays(days);
  if (result.ok) {
    // Alert.alert('Saved');
    showSnackbarMessage(`Closed days were updated`);
  } else {
    showSnackbarMessage(`Something went wrong`);
    // Alert.alert('Something went wrong', result.message);
  }
};

const HolidaysSettings = () => {
  const global = useContext(GlobalContext);
  const { closedDays } = global;

  const [dates, setDates] = useState({ dates: new Set(closedDays) });
  const [dateRanges, setDateRanges] = useState([]);
  const [formattedDates, setFormattedDates] = useState({});

  useEffect(() => {
    setDateRanges(toDateRange(dates.dates));
  }, [dates]);

  useEffect(() => {
    setFormattedDates(toCalendarFormat(dateRanges));
  }, [dateRanges]);

  useEffect(() => {
    setDates({ dates: new Set(closedDays) });
  }, [closedDays]);

  const save = () => {
    updateClosedDays(Array.from(dates.dates), global).then(() =>
      global.updateClosedDays(),
    );
  };

  const addRemoveDate = props => {
    const { dateString } = props;
    setDates(prevState => {
      if (prevState.dates.has(dateString)) {
        prevState.dates.delete(dateString);
        return { ...prevState };
      } else {
        prevState.dates.add(dateString);
        return { ...prevState };
      }
    });
  };

  const toCalendarFormat = arr => {
    // const arr = toDateRange(dateSet);
    const datesObject = {};

    const calStyle = {
      selected: true,
      color: '#FF605C',
      textColor: 'rgb(92,3,1)',
    };

    for (let i = 0; i < arr.length; i++) {
      const sequenceLen = arr[i].length;
      if (sequenceLen === 1) {
        datesObject[arr[i][0]] = {
          ...calStyle,
          endingDay: true,
          startingDay: true,
        };
      } else {
        datesObject[arr[i][0]] = {
          ...calStyle,
          startingDay: true,
        };

        datesObject[arr[i][sequenceLen - 1]] = {
          ...calStyle,
          endingDay: true,
        };

        for (let j = 1; j < arr[i].length - 1; j++) {
          datesObject[arr[i][j]] = { ...calStyle };
        }
      }
    }

    return datesObject;
  };

  const arrow = direction => {
    let name = 'chevron-forward';
    if (direction === 'left') {
      name = 'chevron-back';
    }
    return (
      <Ionicons
        name={name}
        size={22}
        color={Colors.darkBlue}
        style={{ marginTop: Platform.OS === 'ios' ? 2 : 0 }}
      />
    );
  };

  const toDateRange = dateSet => {
    const dateArray = Array.from(dateSet);
    dateArray.sort();
    const momentDates = dateArray.map(x => {
      return moment(x);
    });

    const sequenceBreak = (a, b) => b.diff(a, 'days') > 1;

    const ranges = [];
    let i = 0;
    while (i < momentDates.length) {
      let j = i;
      while (
        j < momentDates.length - 1 &&
        !sequenceBreak(momentDates[j], momentDates[j + 1])
      ) {
        j = j + 1;
      }

      ranges.push(dateArray.slice(i, j + 1));
      i = j + 1;
    }
    return ranges;
  };

  return (
    <SideMenuWrapper route={'holidays'} title={'Closed Days'}>
      <View style={[SettingsStyle.container, { marginTop: -10 }]}>
        <View style={{ marginHorizontal: -20, height: 360 }}>
          <Calendar
            renderArrow={arrow}
            current={moment(new Date()).toISOString('yyyy-MM-dd')}
            onDayPress={addRemoveDate}
            firstDay={1}
            pagingEnabled
            markingType={'period'}
            onPressArrowLeft={subtractMonth => subtractMonth()}
            onPressArrowRight={addMonth => addMonth()}
            // enableSwipeMonths
            markedDates={formattedDates}
          />
        </View>
        <Button
          onPress={save}
          text={'Save Changes'}
          style={{ marginTop: 20 }}
          color={Colors.confirmGreen}
          active
        />
      </View>
    </SideMenuWrapper>
  );
};
export default HolidaysSettings;
