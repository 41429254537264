import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import { Animated, Text, View } from 'react-native';
import React, { useContext, useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import ReservationOverviewModal from '../../Timeline/modals/ReservationOverviewModal';
import ReservationItem from '../../../components/ReservationItem';
import SwipeItemMenu from '../../../components/SwipeItemMenu';
import { ScrollView } from 'react-native-gesture-handler';
import ReservationServices from '../../../services/ReservationsServices';
import { GlobalContext } from '../../../GlobalContext';

const ListReservations = ({
  reservationsOnInterval,
  panHandlers,
  vertical,
}) => {
  const global = useContext(GlobalContext);
  const { getReservations, selectedDate, showAlert } = global;

  const [selectedReservation, setSelectedReservation] = useState(false);

  const cancelReservation = reservationId => {
    ReservationServices.cancelReservation(reservationId).then(res => {
      if (res.ok) {
        getReservations(selectedDate.format('YYYY-MM-DD'), true);
        showAlert('Reservation was cancelled.', 'info');
      } else {
        showAlert('Something went wrong.', 'error');
      }
    });
  };

  return (
    <Animated.View
      style={[
        {
          backgroundColor: Colors.white,
          flex: 1,
          borderTopLeftRadius: vertical ? 8 : 0,
          borderTopRightRadius: vertical ? 8 : 0,
          paddingTop: vertical ? 2 : 0,
        },
        Style.shadowDown,
      ]}
    >
      {vertical && (
        <View
          style={{
            position: 'absolute',
            top: 6,
            left: 0,
            right: 0,
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: 38,
              height: 5,
              backgroundColor: '#eee',
              borderRadius: 30,
            }}
          />
        </View>
      )}
      <Animated.View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 50,
          borderColor: Colors.lighterer,
          paddingLeft: 0,
        }}
        {...panHandlers}
      >
        <Animated.Text
          style={{
            color: Colors.darkest,
            fontSize: 16,
            fontWeight: '500',
            paddingTop: 5,
            paddingHorizontal: 20,
          }}
          numberOfLines={1}
          selectable={false}
        >
          Reservations
        </Animated.Text>
        <Text> </Text>
      </Animated.View>
      {reservationsOnInterval.length === 0 ? (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: 200,
          }}
        >
          <Ionicons
            name={'md-calendar'}
            size={130}
            style={{ color: '#eee' }}
            selectable={false}
          />
        </View>
      ) : null}
      <ScrollView style={{ paddingHorizontal: 0 }}>
        {reservationsOnInterval.map((reservation, key) => {
          let reservationType = 'online';
          if (reservation.created_by_restaurant || reservation.manual_entry) {
            reservationType = 'createdByRestaurant';
          }
          if (reservation?.tables.length > 1) {
            reservationType = 'multiTable';
          }
          if (reservation.created_by_non_registered_customer) {
            reservationType = 'webForm';
          }
          if (reservation.walk_in) {
            reservationType = 'walkIn';
          }

          if (reservation.cancelled) {
            reservationType = 'cancelled';
          }
          return (
            <View key={`${key}`}>
              <SwipeItemMenu
                type={reservationType}
                onPressDetails={() => setSelectedReservation(reservation)}
                onPressCancel={() => cancelReservation(reservation.id)}
              >
                <ReservationItem
                  key={`${key}`}
                  dateFrom={reservation.reservation_date}
                  dateTo={reservation.reservation_date_end}
                  dateCreated={reservation.date_create}
                  name={reservation.customer?.fullname}
                  persons={reservation.persons}
                  multiTable={reservation?.tables.length > 1}
                  cancelled={reservation.cancelled}
                  createdByRestaurant={
                    reservation.created_by_restaurant ||
                    reservation.manual_entry
                  }
                  webForm={reservation.created_by_non_registered_customer}
                  walkIn={reservation.walk_in}
                  messages={reservation?.messages}
                  hideIcons
                  hideMessages
                  showMessagesTopRight
                  // onPress={() => setSelectedReservation(reservation)}
                  disableOnPress
                  style={{
                    borderRadius: 0,
                    shadowOpacity: 0.0,
                    borderLeftWidth: 0,
                    paddingLeft: 16,
                    paddingRight: 20,
                    paddingBottom: 10,
                    paddingTop: 8,
                    elevation: 0,
                    marginHorizontal: 0,
                    marginLeft: 0,
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                  hideDate
                  showTables
                  tables={reservation.tables}
                />
              </SwipeItemMenu>

              <View
                style={{
                  height: 1,
                  backgroundColor: '#f1f4f6',
                }}
              />
            </View>
          );
        })}
      </ScrollView>
      <ReservationOverviewModal
        isVisible={!!selectedReservation}
        onBackDropPress={() => setSelectedReservation(null)}
        onSwipeComplete={() => setSelectedReservation(null)}
        selectedReservation={selectedReservation}
      />
    </Animated.View>
  );
};

export default ListReservations;
