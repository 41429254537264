import React from 'react';
import {
  ActivityIndicator,
  Alert,
  Animated,
  Dimensions,
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import _ from 'lodash';

import Colors from '../../../constants/Colors';
import TableDraggable from './TableDraggable';
import { withGlobalContext } from '../../../GlobalContext';
import Table from './Table';
import LayoutServices from '../../../services/LayoutServices';
import AreYouSureWrapper from '../../../components/AreYouSureWrapper';
import { SafeAreaView } from 'react-native-safe-area-context';

class RoomEdit extends React.Component {
  static navigationOptions = {
    header: null,
    swipeEnabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      roomContainer: false,
      removeAreaOpacity: new Animated.Value(0.1), // init opacity 0
      editAreaWidth: new Animated.Value(50), // init opacity 0
      tables: [],
      tablesInRoom: [],
      saving: false,
    };
  }

  componentDidMount() {
    const { navigation, global } = this.props;
    const room = navigation.getParam('room', { tables: [] });
    this.setState({
      tables: [...global.tables],
      room,
      tablesInRoom: [...(room?.tables || [])],
    });
  }

  switchRemoveArea = value => {
    const { removeAreaOpacity, editAreaWidth, roomContainer } = this.state;

    let newOpacity = 0.05;
    let newWidth = 40;
    let bounciness = 8;
    let speed = 6;
    if (value === 'show') {
      newOpacity = 0.8;
      newWidth = (1 * roomContainer.fullWidth) / 3.3;
      bounciness = 2;
      speed = 14;
    }

    Animated.spring(removeAreaOpacity, {
      toValue: newOpacity,
      bounciness,
      speed,
      useNativeDriver: false,
    }).start();
    Animated.spring(editAreaWidth, {
      toValue: newWidth,
      bounciness,
      speed,
      useNativeDriver: false,
    }).start();
  };

  renderTableOption = ({ item }) => {
    if (item.table_model.id == null) {
      return null;
    }
    const { table_number } = item;
    if (item.room_id) {
      return null;
    }

    return (
      <TouchableOpacity
        style={[styles.tableListContainer]}
        onPress={() => this.addTableToRoom(item)}
      >
        <View style={styles.tableListSubContainer} pointerEvents="none">
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: 120,
              height: 120,
              // flex: 1,
              flexDirection: 'row',
            }}
          >
            <Table
              maxWidth={120}
              maxHeight={120}
              tableWidth={item.table_model.dimensions.width}
              tableLength={item.table_model.dimensions.length}
              isRound={item.table_model.dimensions.round}
              chairsTop={item.table_model.chairs.top}
              chairsBottom={item.table_model.chairs.bottom}
              chairsLeft={item.table_model.chairs.left}
              chairsRight={item.table_model.chairs.right}
              chairsCount={item.table_model.chairs.count}
              tableNumber={table_number}
              groupPrefix={item.group_prefix}
            />
          </View>
        </View>
        <View style={{ margin: 15, alignItems: 'center' }}>
          <Text style={{ color: Colors.dark, fontSize: 13, fontWeight: '500' }}>
            {item.table_group_name} | {item.table_model?.dimensions?.width} x{' '}
            {item.table_model?.dimensions?.length}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  addTableToRoom = table => {
    const { tablesInRoom, room } = this.state;

    // table object for room
    const newTableInRoom = {
      dimensions: {
        ...table.table_model.dimensions,
      },
      position: {
        x: 30,
        y: 30,
        angle: 0,
      },
      chairs: {
        ...table.table_model.chairs,
      },
      table_id: table.id,
      table_number: table.table_number,
    };

    this.setState({
      tablesInRoom: [...tablesInRoom, newTableInRoom],
    });
    this.changeRoomId(table.id, room.id);
  };

  changeRoomId = (tableId, roomId) => {
    const { tables } = this.state;
    //
    // tables array without "table"
    const newTablesArray = tables.filter(obj => {
      return obj.id !== tableId;
    });

    // new object of "table" with updated room_id
    const tableUpdate = { ...tables.find(x => x.id === tableId) };
    tableUpdate.room_id = roomId;

    // updated table added back to array
    newTablesArray.push(tableUpdate);

    this.setState({
      tables: _.orderBy(newTablesArray, ['id']),
    });
  };

  removeTableFromRoom = tableId => {
    const { tablesInRoom } = this.state;
    const newArray = tablesInRoom.filter(obj => {
      return obj.table_id !== tableId;
    });

    this.setState({
      tablesInRoom: newArray,
    });
    this.changeRoomId(tableId, null);
  };

  updateTablePosition = (tableId, newPosition) => {
    const { tablesInRoom } = this.state;

    const table = { ...tablesInRoom.find(x => x.table_id === tableId) };
    table.position = newPosition;

    const newArray = tablesInRoom.filter(obj => {
      return obj.table_id !== tableId;
    });
    newArray.push(table);

    this.setState({
      tablesInRoom: newArray,
    });
  };

  measureViewOut = () => {
    const { navigation } = this.props;
    const room = navigation.getParam('room', 'NO-ID');
    const { dimensions } = room;

    this.markerOut.measure((x, y, width, height) => {
      let k = (width - 40) / dimensions?.width;
      if (k * dimensions?.height >= height - 20) {
        k = (height - 20) / dimensions?.height;
      }

      this.setState({
        roomContainer: {
          width: k * dimensions?.width,
          height: k * dimensions?.height,
          fullWidth: width,
          scaleFactor: k,
          x,
          y,
        },
      });
    });
  };

  removeRoom = () => {
    this.deleteRoom().then(() => {});
  };

  async deleteRoom() {
    const { room } = this.state;
    const { navigation, global } = this.props;
    const { showSnackbarMessage } = global;

    const result = await LayoutServices.deleteRoom(room.id);
    if (result.ok) {
      showSnackbarMessage(`Space was removed.`);
      global.updateRooms();
      global.updateTables();
      navigation.goBack(null);
    } else {
      showSnackbarMessage(`Something went wrong`);
      // Alert.alert('Something went wrong', result.message);
    }
  }

  async saveLayout() {
    const { room, tablesInRoom } = this.state;

    const data = {};
    data.room_id = room.id;

    data.tables = tablesInRoom.map(obj => {
      return { table_id: obj.table_id, position: obj.position };
    });

    this.setState({
      saving: true,
    });

    const result = await LayoutServices.updateRoomLayout(data);
    if (result.ok) {
      const { global } = this.props;
      global.updateRooms();
      global.updateTables();
    } else {
      Alert.alert('Something went wrong', result.message);
    }
    if (result) {
      setTimeout(() => {
        this.setState({
          saving: false,
        });
      }, 700);
    }
  }

  render() {
    const { navigation } = this.props;
    const room = navigation.getParam('room', 'NO-ID');
    const { dimensions } = room;

    const {
      removeAreaOpacity,
      tables,
      editAreaWidth,
      saving: saving1,
      roomContainer,
      saving,
      tablesInRoom,
    } = this.state;

    const saveStyle = {
      maxHeight: removeAreaOpacity.interpolate({
        inputRange: [0, 1],
        outputRange: [100, 0],
      }),
      maxWidth: removeAreaOpacity.interpolate({
        inputRange: [0, 1],
        outputRange: [200, 0],
      }),
      opacity: removeAreaOpacity.interpolate({
        inputRange: [0, 1],
        outputRange: saving ? [0.75, -5] : [1, -1],
      }),
    };

    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: Colors.lightBackground,
          paddingTop: Platform.OS === 'web' ? 10 : 0,
        }}
        edges={['top']}
      >
        <View style={[styles.container]}>
          <View
            style={{
              flex: 1,
              flexShrink: 1,
            }}
          >
            <Animated.View style={[styles.barStyle]}>
              <TouchableOpacity
                onPress={() => navigation.goBack(null)}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingHorizontal: 10,
                }}
              >
                <Ionicons
                  name="chevron-back"
                  size={28}
                  color={Colors.lightBlue}
                  style={{
                    width: 28,
                    height: 28,
                    alignSelf: 'center',
                  }}
                  selectable={false}
                />
              </TouchableOpacity>
              <Animated.View style={[styles.barItemMain]} pointerEvents="none">
                <Text style={styles.title} selectable={false} numberOfLines={1}>
                  {room?.name}
                </Text>
                <Text
                  style={styles.subTitle}
                  selectable={false}
                  numberOfLines={1}
                >
                  {dimensions?.width} x {dimensions?.height}
                </Text>
              </Animated.View>

              <Animated.View
                style={[
                  styles.barItemStyle,
                  styles.barItemButton,
                  {
                    // marginVertical: 5,
                    marginRight: 5,
                    backgroundColor: Colors.lightGreen,
                  },
                  saveStyle,
                ]}
              >
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginHorizontal: 14,
                    flex: 1,
                  }}
                  disabled={saving1}
                  onPress={() => this.saveLayout()}
                >
                  {saving1 ? (
                    <ActivityIndicator size="small" color="#fff" />
                  ) : (
                    <Ionicons
                      name="save"
                      size={16}
                      color={Colors.lightBlueBackground}
                      style={{ width: 16, height: 16, alignSelf: 'center' }}
                      selectable={false}
                    />
                  )}

                  <Text
                    style={{
                      color: Colors.white,
                      fontSize: 13,
                      fontWeight: '600',
                      marginLeft: 10,
                    }}
                    selectable={false}
                  >
                    {saving1 ? 'Saving...' : 'Save'}
                  </Text>
                </TouchableOpacity>
              </Animated.View>

              <Animated.View
                style={[
                  styles.barItemStyle,
                  styles.barItemButton,
                  {
                    // marginVertical: 5,
                    marginRight: 12,
                    backgroundColor: Colors.dangerRed,
                  },
                  saveStyle,
                ]}
              >
                <AreYouSureWrapper
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}
                  disabled={saving1}
                  onConfirm={() => this.removeRoom()}
                  title="Delete this space"
                  confirmText="Delete"
                  cancelText="Cancel"
                  message="Are you sure you want to delete this space?"
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      marginHorizontal: 14,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Ionicons
                      name="trash"
                      size={16}
                      color={Colors.lightBlueBackground}
                      style={{ width: 16, height: 16, alignSelf: 'center' }}
                      selectable={false}
                    />
                    <Text
                      style={{
                        color: Colors.white,
                        fontSize: 13,
                        fontWeight: '600',
                        marginLeft: 7,
                      }}
                      selectable={false}
                    >
                      Space
                    </Text>
                  </View>
                </AreYouSureWrapper>
              </Animated.View>

              <Animated.View
                style={[
                  styles.barItemStyle,
                  {
                    opacity: removeAreaOpacity,
                    width: editAreaWidth,
                    // height: 50,
                  },
                ]}
                pointerEvents="none"
              >
                <Ionicons
                  name="refresh"
                  size={25}
                  color={Colors.lightBlue}
                  style={{ width: 25, height: 25 }}
                  selectable={false}
                />
              </Animated.View>
              <Animated.View
                pointerEvents="none"
                style={[
                  styles.barItemStyle,
                  {
                    opacity: removeAreaOpacity,
                    width: editAreaWidth,
                    // height: 50,
                  },
                ]}
              >
                <Ionicons
                  name="close"
                  size={30}
                  color={Colors.dangerRed}
                  style={{ width: 30, height: 30, alignSelf: 'center' }}
                  selectable={false}
                />
              </Animated.View>
            </Animated.View>

            <View
              ref={ref => {
                this.markerOut = ref;
              }}
              onLayout={() => {
                if (this.markerOut) {
                  this.measureViewOut();
                }
              }}
              style={[
                {
                  backgroundColor: Colors.lightBackground,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  flex: 1,
                  // alignSelf: 'stretch',
                  // justifyContent:"center",
                  paddingTop: 4,
                  alignItems: 'center',
                },
              ]}
            >
              {roomContainer ? (
                <View
                  style={[
                    {
                      width: roomContainer.width,
                      height: roomContainer.height,
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      // backgroundColor: 'red',
                      borderWidth: 0.75,
                      borderStyle: 'dashed',
                      borderColor: Colors.light,
                    },
                  ]}
                >
                  {tablesInRoom.map(item => (
                    <TableDraggable
                      // key={`x${item.position.x}y${item.position.y}x${item.position.x}y${item.position.y}id${item.table_id}container${roomContainer?.width}${roomContainer?.height}${roomContainer?.scaleFactor}`}
                      key={`id${item.table_id}container${roomContainer?.width}${roomContainer?.height}${roomContainer?.scaleFactor}`}
                      // key={`id${item.table_id}`}
                      roomContainer={roomContainer}
                      table={item}
                      removeTableFromRoom={this.removeTableFromRoom}
                      switchRemoveArea={this.switchRemoveArea}
                      scaleFactor={roomContainer.scaleFactor}
                      updateTablePosition={this.updateTablePosition}
                    />
                  ))}
                  {/*<this.renderTables />*/}
                </View>
              ) : null}
            </View>
          </View>
          {Dimensions.get('window').width > 500 && (
            <SafeAreaView
              style={{ width: 240, height: '100%' }}
              edges={['right']}
            >
              <View
                style={{
                  height: 48,
                  justifyContent: 'center',
                  // alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontWeight: '500',
                    fontSize: 16,
                    marginTop: 8,
                    color: Colors.darker,
                  }}
                  selectable={false}
                >
                  Available Tables
                </Text>
                <Text
                  style={{ color: Colors.light, marginTop: 2 }}
                  selectable={false}
                  numberOfLines={1}
                >
                  Click on table to add it to space
                </Text>
              </View>
              <FlatList
                data={tables}
                renderItem={this.renderTableOption}
                keyExtractor={item => ` ${item.id}`}
              />
            </SafeAreaView>
          )}
        </View>
        {Dimensions.get('window').width <= 500 && (
          <Text style={{ padding: 20 }}>
            Rotate the screen to add tables to the room
          </Text>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  tableListContainer: {
    backgroundColor: '#efefef',
    marginRight: 20,
    marginTop: 10,
    borderRadius: 8,
  },
  tableListSubContainer: {
    flex: 1,
    margin: 20,
    marginBottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableStyle: {
    width: 80,
    height: 50,
    backgroundColor: Colors.dark,
    borderRadius: 2,
  },
  barStyle: {
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 20,
    marginTop: 5,
    marginBottom: 5,
    height: 44,
    borderBottomColor: '#ddd',
  },
  barItemMain: {
    justifyContent: 'center',
    marginRight: 10,
    borderRadius: 5,
    flex: 1,
  },
  barItemStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    textAlign: 'center',
    backgroundColor: '#efefef',
    marginLeft: 8,
  },
  barItemButton: {
    marginVertical: 4,
  },

  container: {
    flex: 1,
    flexDirection: 'row',
    // flexWrap: 'wrap',
  },

  title: {
    fontSize: 19,
    color: Colors.darker,
    fontWeight: '500',
  },
  subTitle: {
    fontSize: 14,
    color: Colors.light,
  },
});
export default withGlobalContext(RoomEdit);
