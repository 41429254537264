import React, { useContext, useEffect, useState } from 'react';
import { Switch, Text, TextInput, View } from 'react-native';
import SideMenuWrapper from './SideMenuWrapper';
import Colors from '../../constants/Colors';
import SettingsStyle from '../../styles/SettingsStyle';
import Button from '../../components/Button';
import { GlobalContext } from '../../GlobalContext';
import SettingsServices from '../../services/SettingsServices';

const SettingsItem = props => {
  const { title, textInput, setTextInput, inputSubText } = props;
  return (
    <View style={SettingsStyle.SettingsItem}>
      <Text style={SettingsStyle.SettingsItemTitle}>{title}</Text>
      <View style={SettingsStyle.settingsItemInputRow}>
        <TextInput
          style={SettingsStyle.settingsItemTextInput}
          // placeholder=""
          onChangeText={text => setTextInput(text)}
          defaultValue={textInput}
        />
        <Text style={SettingsStyle.settingsItemInputSubText}>
          {inputSubText}
        </Text>
      </View>
    </View>
  );
};

const updateSettings = async props => {
  const { global } = props;
  const { showSnackbarMessage } = global;

  const result = await SettingsServices.updateReservationsSettings(props);
  if (result.ok) {
    // Alert.alert('Saved');
    showSnackbarMessage(`Settings were saved`);
  } else {
    showSnackbarMessage(`Something went wrong`);
    // Alert.alert('Something went wrong', result.message);
  }
};

const ReservationSettings = () => {
  const global = useContext(GlobalContext);

  const { reservationsSettings } = global;

  const [acceptReservations, setAcceptReservations] = useState(false);

  const [requireConfirmation, setRequireConfirmation] = useState(false);

  const [reservationDuration, setReservationDuration] = useState('');
  const [daysInAdvance, setDaysInAdvance] = useState('');
  const [gap, setGap] = useState('');
  const [minMinutesBefore, setMinMinutesBefore] = useState('');

  useEffect(() => {
    global.updateReservationsSettings();
  }, []);

  useEffect(() => {
    if (reservationsSettings) {
      const {
        accept_reservations,
        require_confirmation,
        default_duration_minutes,
        max_days_in_advance,
        gap_minutes,
        min_minutes_before,
      } = reservationsSettings;

      setAcceptReservations(accept_reservations);
      setRequireConfirmation(require_confirmation);
      setReservationDuration(default_duration_minutes);
      setDaysInAdvance(max_days_in_advance);
      setGap(gap_minutes);
      setMinMinutesBefore(min_minutes_before);
    }
  }, [reservationsSettings]);

  const toggleAcceptReservationsSwitch = () =>
    setAcceptReservations(previousState => !previousState);

  const toggleRequireConfirmationSwitch = () =>
    setRequireConfirmation(previousState => !previousState);

  const save = () => {
    updateSettings({
      acceptReservations,
      reservationDuration: parseInt(reservationDuration),
      daysInAdvance: parseInt(daysInAdvance),
      gap: parseInt(gap),
      minMinutesBefore: parseInt(minMinutesBefore),
      global,
    }).then(() => global.updateReservationsSettings());
  };

  return (
    <SideMenuWrapper
      route={'reservations'}
      title={'Reservations'}
      showMenuDefault
    >
      <View style={SettingsStyle.container}>
        <Text style={SettingsStyle.SettingsItemTitle}>
          Accept reservations from app
        </Text>
        <View style={SettingsStyle.SettingsBooleanField}>
          <Switch
            onValueChange={toggleAcceptReservationsSwitch}
            value={acceptReservations}
          />
          <Text style={SettingsStyle.settingsItemInputSubText}>
            {acceptReservations
              ? 'Users can book a table in your restaurant'
              : "Users can't book a table in your restaurant"}
          </Text>
        </View>

        {/*<Text style={SettingsStyle.SettingsItemTitle}>*/}
        {/*  Require Confirmation*/}
        {/*</Text>*/}
        {/*<View style={SettingsStyle.SettingsBooleanField}>*/}
        {/*  <Switch*/}
        {/*    onValueChange={toggleRequireConfirmationSwitch}*/}
        {/*    value={requireConfirmation}*/}
        {/*  />*/}
        {/*  <Text style={SettingsStyle.settingsItemInputSubText}>*/}
        {/*    {requireConfirmation*/}
        {/*      ? 'You will need to confirm reservations made by users'*/}
        {/*      : 'Reservations from Reetab app are approved automatically'}*/}
        {/*  </Text>*/}
        {/*</View>*/}

        <SettingsItem
          title={'Default duration of reservation'}
          textInput={reservationDuration?.toString()}
          setTextInput={setReservationDuration}
          inputSubText={'minutes'}
        />
        <SettingsItem
          title={'Accept reservations in advance for'}
          textInput={daysInAdvance?.toString()}
          setTextInput={setDaysInAdvance}
          inputSubText={'days'}
        />

        <SettingsItem
          title={'Miminum gap between two consecutive reservations'}
          textInput={gap?.toString()}
          setTextInput={setGap}
          inputSubText={'minutes'}
        />
        <SettingsItem
          title={"Don't accept reservations starting sooner than in"}
          textInput={minMinutesBefore?.toString()}
          setTextInput={setMinMinutesBefore}
          inputSubText={'minutes'}
        />
        <Button
          onPress={save}
          text={'Save Settings'}
          style={{ marginTop: 10 }}
          color={Colors.confirmGreen}
          active
        />
      </View>
    </SideMenuWrapper>
  );
};
export default ReservationSettings;
