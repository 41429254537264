import TableVanilla from './TableVanilla';
import { Animated, Text, View } from 'react-native';
import React, { ReactChildren, useEffect, useState } from 'react';
import Svg, { Circle } from 'react-native-svg';

interface TableProps {
  tableWidth: number;
  tableLength: number;
  isRound: boolean;
  chairsTop: number;
  chairsBottom: number;
  chairsLeft: number;
  chairsRight: number;
  chairsCount: number;
  occupied?: boolean;
  selected?: boolean;
  animatedTextAngle?: Animated.Value;
  opacity?: number | Animated.Value;
  textRotationAngle?: number;
  tableNumber?: number | string | null;
  groupPrefix?: string | null;
  maxWidth?: number;
  maxHeight?: number;
  timeLeftPercent?: number;
  bottomText?: string;
  children?: ReactChildren;
}

const CircleTimeLeft = ({ percent }) => {
  const r = 6;
  const r2 = 10;
  const perimeter = 2 * Math.PI * r;
  // const perimeter2 = 2 * Math.PI * r2;
  return (
    <View
      style={{
        height: r + r2,
        width: r + r2,
        // borderRadius: 40,
        // overflow: 'hidden',
        // backgroundColor: 'red',
        // transform: [{ rotate: '-90deg' }, { rotateX: '180deg' }],
      }}
    >
      <Svg color={'blue'} fill={'transparent'}>
        <Circle
          cx={r + (r2 - r) / 2}
          cy={r + (r2 - r) / 2}
          r={r}
          // stroke={'rgba(255,255,255,1)'}
          stroke={'rgba(0,0,0,0.3)'}
          strokeWidth={r2 - r}
          // strokeWidth={1}
          strokeDasharray={perimeter}
          strokeDashoffset={perimeter * percent}
        />
        {/*<Circle*/}
        {/*  cx={2 * r}*/}
        {/*  cy={2 * r}*/}
        {/*  r={r2}*/}
        {/*  // stroke={'rgb(255,42,42)'}*/}
        {/*  stroke={'rgba(0,0,0,0.3)'}*/}
        {/*  strokeWidth={2 * r2}*/}
        {/*  strokeWidth={r2}*/}
        {/*  strokeDasharray={perimeter2}*/}
        {/*  strokeDashoffset={perimeter2 * percent}*/}
        {/*/>*/}
      </Svg>
    </View>
  );
};

const BottomText = ({ text }) => {
  return (
    <View
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 4,
        marginBottom: 0,
      }}
    >
      <Text
        numberOfLines={1}
        adjustsFontSizeToFit
        style={{
          color: 'white',
          fontSize: 14,
          fontWeight: '700',
        }}
      >
        {text}
      </Text>
    </View>
  );
};

const TableNumber = ({
  tableNumber,
  groupPrefix,
  animatedTextAngle,
  textRotationAngle,
  size,
}: any) => {
  return (
    <Animated.Text
      selectable={false}
      adjustsFontSizeToFit
      numberOfLines={1}
      style={[
        {
          fontSize: size,
          color: 'rgba(255,255,255,0.9)',
          fontWeight: '500',
          paddingHorizontal: 4,
          paddingVertical: 2,
        },
        animatedTextAngle && {
          transform: [
            {
              rotate: animatedTextAngle.interpolate({
                inputRange: [0, 360],
                outputRange: ['0deg', '-360deg'],
              }),
            },
          ],
        },
        textRotationAngle && {
          transform: [
            {
              rotate: `${-textRotationAngle}deg`,
            },
          ],
        },
      ]}
    >
      {groupPrefix ? `${groupPrefix}` : ''}

      {tableNumber}
    </Animated.Text>
  );
};

const Table = ({
  tableNumber,
  groupPrefix,
  maxWidth,
  maxHeight,
  tableLength,
  tableWidth,
  chairsCount,
  chairsRight,
  chairsLeft,
  chairsTop,
  chairsBottom,
  selected,
  occupied,
  isRound,
  opacity,
  textRotationAngle,
  animatedTextAngle,
  timeLeftPercent,
  bottomText,
  children,
}: TableProps) => {
  // const is90deg = (textRotationAngle + 90) % 180 === 0;
  // const is45deg = (textRotationAngle + 45) % 90 === 0;

  const [scale, setScale] = useState(1);
  const [is90deg, setIs90deg] = useState(false);
  const [is45deg, setIs45deg] = useState(false);

  useEffect(() => {
    if (maxWidth && maxHeight) {
      const scaleW = maxWidth / (tableLength + 20);
      const scaleH = maxHeight / (tableWidth + 20);
      setScale(Math.min(scaleW, scaleH));
    }
  }, [maxWidth, maxHeight, tableWidth, tableLength]);

  useEffect(() => {
    setIs90deg((textRotationAngle + 90) % 180 === 0);
    setIs45deg((textRotationAngle + 45) % 90 === 0);
  }, [textRotationAngle]);

  return (
    <Animated.View
      style={[
        {
          opacity: opacity,
          justifyContent: 'center',
          alignItems: 'center',
          width: maxWidth,
          height: maxHeight,
        },
        scale !== 1 && {
          transform: [{ scale: scale }],
        },
      ]}
    >
      <TableVanilla
        selected={selected}
        chairsBottom={chairsBottom}
        chairsTop={chairsTop}
        chairsLeft={chairsLeft}
        chairsRight={chairsRight}
        chairsCount={chairsCount}
        tableWidth={tableWidth}
        tableLength={tableLength}
        occupied={occupied}
        isRound={isRound}
      >
        <View
          style={{
            width: '100%',
            height: '100%',
            // backgroundColor: 'red',
            justifyContent: 'center',
            // position: 'absolute',
            // transform: [{ rotate: `${-item.position.angle}deg` }],
          }}
        >
          <View
            style={[
              {
                height: tableWidth,
                width: tableLength,
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
              },
              is90deg && {
                height: tableLength,
                width: tableWidth,
              },
              {
                transform: [
                  {
                    translateX: is90deg ? (tableLength - tableWidth) / 2 : 0,
                  },
                  {
                    rotate: textRotationAngle
                      ? `${-textRotationAngle}deg`
                      : '0deg',
                  },
                  { scale: is45deg ? 1 / 1.2 : 1 },
                  // { scale: 0.9 },
                ],
              },
            ]}
          >
            <View
              style={{
                // flex: 1,
                // width: '100%',
                // flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                transform: [
                  { scale: is45deg ? 1.2 : 1 },
                  // { scale: maxDim ? 1 / scale : 1 },
                ],
              }}
            >
              <View
                style={[
                  {
                    flexDirection: tableWidth <= tableLength ? 'row' : 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                  is90deg && {
                    flexDirection: tableLength <= tableWidth ? 'row' : 'column',
                  },
                  is45deg && {
                    flexDirection: 'row',
                  },
                ]}
              >
                {children}
                {timeLeftPercent && (
                  <CircleTimeLeft percent={timeLeftPercent} />
                )}
                {tableNumber && (
                  <TableNumber
                    tableNumber={tableNumber}
                    groupPrefix={groupPrefix}
                    animatedTextAngle={animatedTextAngle}
                    size={timeLeftPercent || bottomText ? 21 : 26}
                    // textRotationAngle={textRotationAngle}
                  />
                )}
              </View>
              {bottomText && <BottomText text={bottomText} />}
            </View>
          </View>
        </View>
      </TableVanilla>
    </Animated.View>
  );
};

export default Table;
