import React from 'react';
import deviceStorage from './services/DeviceStorage';

import TableServices from './services/TablesServices';
import LayoutServices from './services/LayoutServices';
import OpenHoursServices from './services/OpenHoursServices';
import ReservationServices from './services/ReservationsServices';
import moment from 'moment';
import * as Device from 'expo-device';
import { DeviceType } from 'expo-device';

import NavigationService from './services/NavigationService';
import TimelineServices from './services/TimelineServices';
import SettingsServices from './services/SettingsServices';
import { DefaultTheme } from 'react-native-paper';
import Colors from './constants/Colors';
import { RESERVATIONS_UPDATE_INTERVAL_SECONDS } from './constants/Globals';
import SnackbarNotification from './components/SnackbarNotification';

export const GlobalContext = React.createContext();

export class GlobalContextProvider extends React.Component {
  state = {
    jwtToken: null,
    restaurantId: null,
    restaurants: null,
    tableGroups: [],
    tables: [],
    models: [],
    openHours: [],
    defaultOpenHours: null,
    rooms: [],
    reservations: {},
    selectedDate: moment(Date.now()),
    firstHour: 0,
    lastHour: 23,
    deviceType: null,
    reservationsSettings: null,
    restaurantInfo: null,
    restaurantPhotos: [],
    closedDays: [],
    snackbarVisible: false,
    snackbarMessage: '',
    snackbarType: undefined,
    snackbarBottomMargin: 75,
    dialogVisible: false,
    dialogMessage: '',
  };

  /* Reservations structure
  reservations: {
    "2019-12-10": {
      "updated": "2019-12-01 12:30:15",
      "updating": true,
      "reservations": [
        {
          "id": 415,
          table: {...},
        },
        { ... }, ... ],
    },
    "2019-12-11": {...},
    ...
  }
  */

  async componentDidMount() {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    this.setState({
      restaurantId: restaurantId ? parseInt(restaurantId) : null,
      jwtToken,
    });

    this.getDeviceType();

    if (jwtToken) {
      if (restaurantId) {
        this.updateModels();
        this.updateTables();
        this.updateTableGroups();
        this.updateOpenHours();
        this.updateRooms();
        this.getReservations(this.state.selectedDate.format('YYYY-MM-DD'));
        this.updateReservationsSettings();
        this.updateRestaurantInfo();
        this.updateClosedDays();

        this.interval = setInterval(
          () => this.periodicReservationsUpdate(),
          1000,
        );
      } else {
        const restaurants = await TimelineServices.get_restaurants(jwtToken);
        this.setState({ restaurants });
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { jwtToken, restaurantId } = this.state;
    if (!prevState.jwtToken && jwtToken) {
      const restaurants = await TimelineServices.get_restaurants(jwtToken);
      this.setState({ restaurants });
    }

    if (!prevState.restaurantId && restaurantId) {
      this.updateModels();
      this.updateTables();
      this.updateTableGroups();
      this.updateOpenHours();
      this.updateRooms();
      this.updateReservationsSettings();
      this.updateRestaurantInfo();
      this.updateClosedDays();
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  periodicReservationsUpdate = () => {
    const { selectedDate, restaurantId, jwtToken } = this.state;
    if (restaurantId && jwtToken) {
      this.getReservations(selectedDate.format('YYYY-MM-DD'));
    }
  };

  updateReservationsOnSelectedDate = async () => {
    const { selectedDate, restaurantId, jwtToken } = this.state;
    if (restaurantId && jwtToken) {
      const xx = await this.getReservations(
        selectedDate.format('YYYY-MM-DD'),
        true,
      );
      return xx;
    }
    return;
  };

  logout = () => {
    this.setState({
      jwtToken: null,
      restaurantId: null,
      restaurants: null,
      tableGroups: [],
      tables: [],
      models: [],
      openHours: [],
      defaultOpenHours: null,
      rooms: [],
      reservations: {},
      selectedDate: moment(Date.now()),
      deviceType: null,
      reservationsSettings: null,
      restaurantInfo: null,
      restaurantPhotos: [],
      closedDays: [],
      snackbarVisible: false,
      snackbarMessage: '',
      dialogVisible: false,
      dialogMessage: '',
    });
    deviceStorage.deleteConfig().then();
    NavigationService.navigate('login');
  };

  updateJwt = jwtToken => {
    this.setState({
      jwtToken,
    });
  };

  changeRestaurantId = restaurantId => {
    this.setState({
      restaurantId,
    });
  };

  updateFirstLastHour = () => {
    const { defaultOpenHours, selectedDate } = this.state;

    const newDate = moment(selectedDate, 'YYYY-MM-DD');
    const selectedWeekDay = newDate.format('ddd').toLowerCase();
    if (
      defaultOpenHours &&
      defaultOpenHours.days &&
      defaultOpenHours.days[selectedWeekDay]
    ) {
      const openHours = defaultOpenHours.days[selectedWeekDay];
      const openTime = openHours.open_time;
      const closeTime = openHours.close_time;
      const firstHour = parseInt(openTime.substring(0, 2), 10);
      const lastHour = parseInt(closeTime.substring(0, 2), 10);
      this.setState({ firstHour, lastHour });
    }
  };

  updateSelectedDate = date => {
    const { selectedDate, defaultOpenHours } = this.state;
    const newDate = moment(date, 'YYYY-MM-DD');

    if (date !== selectedDate.format('YYYY-MM-DD')) {
      this.setState({ selectedDate: newDate }, () =>
        this.updateFirstLastHour(),
      );
    }
    this.getReservations(date);
  };

  updateModels = async () => {
    const { jwtToken, restaurantId } = this.state;
    const models = await LayoutServices.getTableModels2(jwtToken, restaurantId);
    this.setState({
      models,
    });
  };

  updateReservationsSettings = async () => {
    const { jwtToken, restaurantId } = this.state;
    const reservationsSettings = await SettingsServices.getReservationsSettings(
      jwtToken,
      restaurantId,
    );
    this.setState({
      reservationsSettings,
    });
  };

  updateRestaurantInfo = async () => {
    const { jwtToken, restaurantId } = this.state;
    const restaurantInfo = await SettingsServices.getInfo(
      jwtToken,
      restaurantId,
    );
    this.setState({
      restaurantInfo,
    });
  };

  updateRestaurantPhotos = async () => {
    const { jwtToken, restaurantId } = this.state;
    const restaurantPhotos = await SettingsServices.getPhotos(
      jwtToken,
      restaurantId,
    );
    this.setState({
      restaurantPhotos,
    });
  };

  updateClosedDays = async () => {
    const { jwtToken, restaurantId } = this.state;
    const closedDays = await OpenHoursServices.getClosedDays(
      jwtToken,
      restaurantId,
    );

    const daysArray = closedDays?.closed_days?.map(a => a.date);

    this.setState({
      closedDays: daysArray,
    });
  };

  updateRooms = async () => {
    const { jwtToken, restaurantId } = this.state;
    const rooms = await LayoutServices.getRooms2(jwtToken, restaurantId);
    this.setState({
      rooms,
    });
  };

  getDeviceType = async () => {
    const type = await Device.getDeviceTypeAsync();
    let deviceType = 'tablet';
    if (type === DeviceType.PHONE) {
      deviceType = 'phone';
    } else if (type === DeviceType.DESKTOP) {
      deviceType = 'web';
    }

    this.setState({
      deviceType,
    });
  };

  updateOpenHours = async () => {
    const { jwtToken, restaurantId } = this.state;
    const openHours = await OpenHoursServices.getOpenHours(
      jwtToken,
      restaurantId,
    );
    const defaultOpenHours = await OpenHoursServices.getDefaultOpenHours(
      jwtToken,
      restaurantId,
    );
    this.setState(
      {
        openHours,
        defaultOpenHours,
      },
      () => this.updateFirstLastHour(),
    );
  };

  updateTables = async () => {
    const { jwtToken, restaurantId } = this.state;
    const tables = await TableServices.getTables2(jwtToken, restaurantId);
    this.setState({
      tables,
    });
  };

  updateTableGroups = async () => {
    const { jwtToken, restaurantId } = this.state;
    const tableGroups = await TableServices.getTableGroups2(
      jwtToken,
      restaurantId,
    );
    this.setState({
      tableGroups,
    });
  };

  getReservations = async (date, force) => {
    const { jwtToken, restaurantId, reservations } = this.state;

    if (reservations[date]) {
      const now = moment(new Date());
      const lastUpdated = moment(reservations[date].updated);
      const timeDelta = moment.duration(now.diff(lastUpdated));

      /* if reservations on date was updated in last * seconds and
         and force update is not requested, ignore update  */
      if (
        timeDelta.asSeconds() < RESERVATIONS_UPDATE_INTERVAL_SECONDS &&
        !force
      ) {
        return;
      }
    }
    const updatedReservationsObj = {
      updating: false,
      updated: moment(new Date()).format(),
      reservations: [],
    };

    try {
      const updatedReservations = await ReservationServices.getReservations2(
        jwtToken,
        restaurantId,
        date,
      );

      updatedReservationsObj.reservations = updatedReservations;
    } catch (error) {
      console.log(error);
    }

    this.setState({
      reservations: {
        ...reservations,
        [date]: updatedReservationsObj,
      },
    });
  };

  showSnackbarMessage = (message, bottomMargin = 75) => {
    this.setState({
      snackbarVisible: true,
      snackbarMessage: message,
      snackbarBottomMargin: bottomMargin,
      snackbarType: undefined,
    });

    setTimeout(() => {
      this.setState({
        snackbarBottomMargin: 75,
      });
    }, 6000);
  };

  showAlert = (text, type) => {
    this.setState({
      snackbarVisible: true,
      snackbarMessage: text,
      snackbarType: type,
    });
  };

  onDismissSnackBar = () => {
    this.setState({
      snackbarVisible: false,
      snackbarMessage: '',
    });
  };

  render() {
    const { children } = this.props;
    const { snackbarVisible, snackbarMessage, snackbarType } = this.state;

    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          updateModels: this.updateModels,
          updateTableGroups: this.updateTableGroups,
          updateTables: this.updateTables,
          updateOpenHours: this.updateOpenHours,
          updateRooms: this.updateRooms,
          updateReservationsSettings: this.updateReservationsSettings,
          updateRestaurantInfo: this.updateRestaurantInfo,
          updateRestaurantPhotos: this.updateRestaurantPhotos,
          updateClosedDays: this.updateClosedDays,
          getReservations: this.getReservations,
          logout: this.logout,
          updateJwt: this.updateJwt,
          changeRestaurantId: this.changeRestaurantId,
          updateSelectedDate: this.updateSelectedDate,
          showSnackbarMessage: this.showSnackbarMessage,
          showAlert: this.showAlert,
          showDialog: this.showDialog,
          updateReservationsOnSelectedDate: this
            .updateReservationsOnSelectedDate,
        }}
      >
        {children}
        <SnackbarNotification
          isVisible={snackbarVisible}
          message={snackbarMessage}
          onDismiss={this.onDismissSnackBar}
          type={snackbarType}
        />
        {/*<Snackbar*/}
        {/*  visible={snackbarVisible}*/}
        {/*  onDismiss={this.onDismissSnackBar}*/}
        {/*  duration={5500}*/}
        {/*  theme={theme}*/}
        {/*  wrapperStyle={{*/}
        {/*    marginBottom: snackbarBottomMargin,*/}
        {/*    alignItems: 'flex-end',*/}
        {/*  }}*/}
        {/*  style={{*/}
        {/*    width: Dimensions.get('screen').width > 800 ? 400 : null,*/}
        {/*    marginRight: Dimensions.get('screen').width > 800 ? 10 : null,*/}
        {/*    // backgroundColor: 'rgb(145, 222, 173)',*/}
        {/*    // backgroundColor: Colors.black,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Text*/}
        {/*    style={{*/}
        {/*      // color: 'rgb(19,98,64)',*/}
        {/*      lineHeight: 22,*/}
        {/*      color: Colors.lightest,*/}
        {/*      fontSize: 14,*/}
        {/*      fontWeight: '400',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {snackbarMessage}*/}
        {/*  </Text>*/}
        {/*</Snackbar>*/}
      </GlobalContext.Provider>
    );
  }
}

// create the consumer as higher order component
export const withGlobalContext = ChildComponent => props => (
  <GlobalContext.Consumer>
    {context => <ChildComponent {...props} global={context} />}
  </GlobalContext.Consumer>
);
