import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import moment from 'moment';
import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';

const leftArrow = (
  <Ionicons
    name="chevron-forward"
    size={22}
    color={Colors.darkBlue}
    style={{ marginTop: Platform.OS === 'ios' ? 2 : 0 }}
  />
);

const rightArrow = (
  <Ionicons
    name="chevron-back"
    size={22}
    color={Colors.darkBlue}
    style={{ marginTop: Platform.OS === 'ios' ? 2 : 0 }}
  />
);

const styles = StyleSheet.create({
  topBarItem: {
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    shadowOpacity: Platform.OS === 'web' ? 0.25 : 0.13,
    shadowRadius: 3,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 4,
  },
});

const DateSelectItem = ({
  selectedDate,
  plusMinusDate,
  changeSelectedDate,
  showCalendarModal,
  size,
}) => {
  return (
    <View
      style={[
        styles.topBarItem,
        {
          width: size === 'small' ? 220 : 260,
          height: size === 'small' ? 35 : 42,
        },
      ]}
    >
      <TouchableOpacity
        onPress={() => plusMinusDate('subtract')}
        style={{
          paddingHorizontal: 10,
          borderRightWidth: 0,
          borderColor: '#aaa',
          height: size === 'small' ? 35 : 42,
          justifyContent: 'center',
        }}
      >
        {rightArrow}
      </TouchableOpacity>

      <TouchableOpacity
        // onPress={size !== 'small' ? () => showCalendarModal() : null}
        onPress={() => showCalendarModal()}
        onLongPress={() =>
          changeSelectedDate(moment(new Date()).format('YYYY-MM-DD'))
        }
        delayLongPress={300}
        style={{
          // flex: 1,
          height: '100%',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        // disabled={size === 'small'}
      >
        {size !== 'small' && (
          <Text
            style={{
              fontSize: 16,
              fontWeight: '500',
              color: Colors.dark,
              alignSelf: 'center',
            }}
          >
            {selectedDate.calendar(null, {
              sameDay: '[Today]',
              nextDay: 'dddd',
              nextWeek: 'dddd',
              lastDay: 'dddd',
              lastWeek: 'dddd',
              sameElse: 'dddd',
            })}
          </Text>
        )}

        <Text
          style={{
            fontSize: 15,
            fontWeight: size === 'small' ? '500' : '400',
            color: size === 'small' ? Colors.dark : Colors.light,
            alignSelf: 'center',
          }}
        >
          {size === 'small' && selectedDate.format('dddd, D. MMM')}
          {size !== 'small' && selectedDate.format('D. MMMM YYYY')}
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => plusMinusDate('add')}
        style={{
          paddingHorizontal: 10,
          // borderRightWidth: 0,
          borderColor: '#aaa',
          // width: 50,
          height: size === 'small' ? 35 : 42,
          // backgroundColor:'red',
          justifyContent: 'center',
        }}
      >
        {leftArrow}
      </TouchableOpacity>
    </View>
  );
};

export default DateSelectItem;
