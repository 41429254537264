import moment from 'moment';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import Colors from '../constants/Colors';

const ReservationItem = ({
  dateFrom,
  dateTo,
  dateCreated,
  name,
  persons,
  createdByRestaurant,
  multiTable,
  cancelled,
  onPress,
  messages,
  webForm,
  walkIn,
  style,
  hideDate,
  showStatus,
  showTables,
  tables,
  hideMessages,
  hideIcons,
  showTimeAgo,
  showMessagesTopRight,
  disableOnPress,
}: any) => {
  const reservationDateFormatted = moment(dateFrom).format('DD. MMMM YYYY');
  const reservationTimeFromFormatted = moment(dateFrom).format('HH:mm');
  const reservationTimeToFormatted = moment(dateTo).format('HH:mm');
  const dateTimeCreatedAgo = moment.utc(dateCreated).fromNow();
  const past = moment(dateTo).isBefore(moment());
  const reservationDateIn = moment(dateFrom).fromNow();
  return (
    <TouchableOpacity
      style={[
        styles.item,
        createdByRestaurant && styles.itemByRestaurant,
        multiTable && styles.itemMultiTable,
        webForm && styles.itemWebForm,
        walkIn && styles.itemWalkIn,
        cancelled && styles.itemCancelled,
        style,
      ]}
      onPress={onPress}
      disabled={disableOnPress}
    >
      <View style={{ flexShrink: 1 }}>
        <Text style={[styles.itemTextSpecial]}>
          {name}
          <Text
            style={{
              fontWeight: Platform.OS === 'ios' ? '400' : '400',
              fontStyle: 'italic',
            }}
          >
            {walkIn && !name ? 'walk-in' : ''}
          </Text>
        </Text>
        {!hideDate && (
          <Text style={[styles.itemText]}>
            {!hideIcons ? (
              <Ionicons
                name={'calendar-outline'}
                size={13}
                // color={'#aaa'}
                style={styles.iconStyle}
              />
            ) : (
              ''
            )}
            {!hideIcons ? '  ' : ''}
            {reservationDateFormatted}
          </Text>
        )}
        <Text style={[styles.itemText]}>
          {!hideIcons ? (
            <Ionicons
              name={'time-outline'}
              size={13}
              // color={'#aaa'}
              style={styles.iconStyle}
            />
          ) : (
            ''
          )}
          {!hideIcons ? '  ' : ''}
          {reservationTimeFromFormatted} - {reservationTimeToFormatted}
        </Text>

        <Text style={[styles.itemText]}>
          {!hideIcons ? (
            <Ionicons
              name={'person-outline'}
              size={13}
              style={styles.iconStyle}
            />
          ) : (
            ''
          )}
          {!hideIcons ? '  ' : ''}
          {persons}
          {!hideIcons ? '' : ' guest'}
          {hideIcons && persons !== 1 ? 's' : ''}
        </Text>
        {!hideMessages && (
          <Text
            style={[
              styles.itemText,
              messages.length === 0 && { fontStyle: 'italic', color: '#bbb' },
            ]}
          >
            <Ionicons
              name={'chatbox-outline'}
              size={13}
              style={[
                styles.iconStyle,
                messages.length === 0 && { color: '#bbb' },
              ]}
            />
            {'  '}
            {messages.length === 0 ? 'no messages' : messages.length}
          </Text>
        )}
        {/*<View style={{ height: 12 }} />*/}
      </View>
      <View style={styles.itemRightContainer}>
        {showTimeAgo && (
          <Text style={styles.itemTextDate}>{dateTimeCreatedAgo}</Text>
        )}
        {showMessagesTopRight && (
          <Text
            style={[
              styles.itemTextMessageIcon,
              messages.length === 0
                ? { color: '#e9ebee' }
                : { color: '#768896' },
            ]}
          >
            <Ionicons
              name={messages.length === 0 ? 'chatbox' : 'chatbox'}
              size={13}
            />
            {/*{' '}{messages.length}*/}
          </Text>
        )}
        {showStatus && (
          <Text
            style={[
              styles.itemTextStaringIn,
              past && styles.itemTextPast,
              cancelled && styles.itemTextCanceled,
            ]}
          >
            {cancelled && 'Canceled'}
            {!cancelled && past && 'Completed'}
            {!cancelled && !past && `starting ${reservationDateIn}`}
          </Text>
        )}
        {showTables && (
          <Text style={[styles.itemTextTables]}>
            {tables.map(table => table.table_number).join(', ')}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    marginHorizontal: 7,
    overflow: 'visible',
    zIndex: 10,
    marginTop: 5,
    marginBottom: 5,
    paddingHorizontal: 14,
    paddingTop: 10,
    paddingBottom: 10,
    shadowOpacity: Platform.OS === 'web' ? 0.15 : 0.08,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    borderLeftWidth: 4,
    borderColor: '#fc6363',
    shadowRadius: 5,
    elevation: 5,
    borderRadius: 2,
    // borderTopRightRadius: 0,
    // borderBottomRightRadius: 0,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemByRestaurant: {
    borderColor: '#56c45f',
  },
  itemOnline: {
    // borderColor: '#fc6363',
  },
  itemWebForm: {
    borderColor: '#ffac4e',
  },
  itemWalkIn: {
    borderColor: 'rgb(248,155,218)',
  },
  itemCancelled: {
    borderColor: '#c3c8cb',
  },
  itemMultiTable: {
    borderColor: '#529be5',
  },
  itemText: {
    fontSize: 14,
    paddingTop: 2,
    color: '#3d4c54',
  },
  itemTextSpecial: {
    color: '#0d2944',
    fontWeight: 'bold',
    // textTransform: 'uppercase',
    paddingBottom: 1,
    fontSize: 14,
  },
  itemTextDate: {
    color: '#bec1c5',
  },
  itemTextMessageIcon: {
    marginTop: 2,
    fontSize: 13,
  },
  itemTexOnline: {
    color: Colors.dangerRed,
    paddingTop: 5,
    // textTransform: 'uppercase',
  },
  itemTextStaringIn: {
    color: '#29c550',
    // color: '#40732e',
    fontWeight: '600',
    textTransform: 'uppercase',
    // textTransform: 'capitalize',
    fontSize: 12,
    marginTop: 7,
  },
  itemTextTables: {
    color: '#32394f',
    // fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: 20,
    // marginTop: 7,
  },
  itemTextPast: {
    color: '#575a65',
  },
  itemTextCanceled: {
    color: '#ccc',
  },
  itemRightContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  iconStyle: {
    color: '#233a4b',
  },
  dotContainer: {
    flexDirection: 'row',
    marginTop: 6,
    height: 6,
    // marginLeft: 1,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: '#f54759',
    marginRight: 4,
  },
  dotNote: {
    backgroundColor: '#fdde41',
  },
});
export default ReservationItem;
