import { Platform, Text, View } from 'react-native';
import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { format, parseISO } from 'date-fns';

const SingleReservation = ({
  fullname,
  boxColor,
  boxBorderColor,
  textColor,
  textColor2,
  textColor3,
  persons,
  note,
  walkIn,
  dateFrom,
  dateTo,
  showTime,
}) => {
  const fromTimeString = format(parseISO(dateFrom), 'HH:mm');
  const toTimeString = format(parseISO(dateTo), 'HH:mm');

  return (
    <View
      style={{
        borderTopColor: boxBorderColor,
        // margin: 0.75,
        borderTopWidth: boxBorderColor ? 4 : 0,
        // paddingTop: !boxBorderColor ? 4 : 0,
        backgroundColor: boxColor,
        borderRadius: 2,
        flex: 1,
        flexDirection: 'row',
        paddingHorizontal: 7,
        paddingVertical: Platform.OS === 'android' ? 4 : 6,
      }}
    >
      <View style={{ flex: 2 }}>
        <Text
          style={{
            fontSize: 12,
            fontWeight: Platform.OS === 'ios' ? '600' : '700',
            color: textColor,
          }}
          numberOfLines={1}
        >
          {fullname}
          {fullname && ' '}
          <Text
            style={{
              fontWeight: Platform.OS === 'ios' ? '400' : '400',
              fontStyle: 'italic',
            }}
          >
            {walkIn ? 'walk-in' : ''}
          </Text>
        </Text>
        {showTime && (
          <Text
            style={{
              fontSize: 11,
              color: textColor,
              // marginTop: -2,
              marginTop: Platform.OS === 'ios' ? 0 : -2,
            }}
            numberOfLines={1}
          >
            {fromTimeString}
            {toTimeString ? ' - ' : null}
            {toTimeString}
          </Text>
        )}
      </View>
      <View
        style={{
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            color: 'blue',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Text
            style={{
              color: textColor3,
              fontSize: 13,
              fontWeight: 'bold',
              paddingHorizontal: 1,
            }}
          >
            {persons}
          </Text>
          <Ionicons
            name="person"
            size={11}
            color={textColor3}
            style={{ paddingTop: 0.5 }}
          />
          {note && (
            <Ionicons
              name="chatbox"
              size={11}
              color={textColor3}
              style={{ paddingTop: 0.5, paddingHorizontal: 2 }}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default SingleReservation;
