import React from 'react';
import {
  Alert,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';

const FloatButton = props => {
  const { onPress } = props;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          backgroundColor: 'rgba(35,209,96,1)',
          height: 46,
          width: 46,
          borderRadius: 60,
          position: 'absolute',
          bottom: 25,
          right: 36,
          zIndex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          shadowOpacity: 0.4,
          shadowColor: '#0bb133',
          shadowRadius: 4,
          shadowOffset: {
            height: 3,
            width: 3,
          },
          elevation: 4,
          alignContent: 'center',
          textAlign: 'center',
        },
      ]}
    >
      <Ionicons
        name="add-outline"
        size={23}
        color={Colors.white}
        style={{ width: 22, height: 23, textAlign: 'center' }}
      />
    </TouchableOpacity>
  );
};

export default FloatButton;
