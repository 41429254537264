import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSequence,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import {
  PanGestureHandler,
  State,
  TapGestureHandler,
} from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import AreYouSureWrapper from './AreYouSureWrapper';

const expandedWidth = 20 + 72 * 2;

const MenuItem = ({ text, icon, onPress, requireConfirmation }: any) => {
  const opacity = useSharedValue('rgba(0, 0, 0, 0.30)');

  const animatePressIn = () => {
    opacity.value = withTiming('rgba(0, 0, 0, 0.60)', { duration: 300 });
  };

  const animatePressOut = () => {
    opacity.value = withDelay(
      1000,
      withTiming('rgba(0, 0, 0, 0.30)', { duration: 500 }),
    );
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: opacity.value,
    };
  });

  return (
    <AreYouSureWrapper
      style={{
        flex: 1,
      }}
      onConfirm={onPress}
      title={'Cancel reservation'}
      confirmText={'Yes'}
      cancelText={'No'}
      message={'Do you want to cancel this reservation?'}
      disabled={!requireConfirmation}
    >
      <TapGestureHandler
        onHandlerStateChange={({ nativeEvent }) => {
          if (nativeEvent.state === State.BEGAN) {
            animatePressIn();
          }
          if (
            nativeEvent.state === State.END ||
            nativeEvent.state === State.FAILED
          ) {
            animatePressOut();
          }
          if (nativeEvent.state === State.ACTIVE && !requireConfirmation) {
            onPress();
          }
        }}
      >
        <Animated.View
          style={[
            {
              marginHorizontal: 2,
              alignItems: 'center',
              justifyContent: text ? 'flex-end' : 'center',
              paddingBottom: 6,
              paddingTop: 8,
              borderRadius: 3,
              height: 56,
            },
            animatedStyle,
          ]}
        >
          <Text
            style={{
              color: 'white',
              fontWeight: '600',
            }}
          >
            {icon}
          </Text>
          {text && (
            <Text
              style={{
                marginTop: 5,
                fontSize: 11,
                color: 'white',
                fontWeight: '600',
              }}
            >
              {text}
            </Text>
          )}
        </Animated.View>
      </TapGestureHandler>
    </AreYouSureWrapper>
  );
};

interface SwipeItemMenuInterface {
  type?:
    | 'multiTable'
    | 'cancelled'
    | 'createdByRestaurant'
    | 'webForm'
    | 'online'
    | 'walkIn';
  children: any;
  onPressEdit?: () => null;
  onPressMove?: () => null;
  onPressCancel?: () => null;
  onPressDetails?: () => null;
}

const SwipeItemMenu = ({
  type,
  children,
  onPressEdit = () => null,
  onPressMove = () => null,
  onPressCancel = () => null,
  onPressDetails = () => null,
}: SwipeItemMenuInterface) => {
  const x = useSharedValue(0);

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.start = x.value;
    },
    onActive: (event, ctx) => {
      x.value = ctx.start + event.translationX;
    },
    onEnd: (event, ctx) => {
      let newValue = 0;
      if (event.translationX > 30) {
        newValue = expandedWidth;
      }

      x.value = withSpring(newValue, {
        damping: 30,
        stiffness: 300,
      });
    },
  });

  const animatedStyleMenuContainer = useAnimatedStyle(() => {
    const width = interpolate(
      x.value,
      [expandedWidth - 30, expandedWidth, expandedWidth + 30],
      [expandedWidth - 18, expandedWidth, expandedWidth + 30],
      {
        extrapolateLeft: Extrapolate.CLAMP,
        extrapolateRight: Extrapolate.EXTEND,
      },
    );
    return {
      width,
    };
  });

  const animatedStyleContainer = useAnimatedStyle(() => {
    const shadowOpacity = interpolate(
      x.value,
      [5, 50],
      [0, 1],
      Extrapolate.CLAMP,
    );

    const translateX = interpolate(
      x.value,
      [-200, 0, expandedWidth],
      [-8, 5, expandedWidth + 5],
      {
        extrapolateLeft: Extrapolate.CLAMP,
        extrapolateRight: Extrapolate.EXTEND,
      },
    );
    return {
      shadowOpacity,
      transform: [
        {
          translateX,
        },
      ],
    };
  });

  const onItemPress = callback => {
    callback();
    x.value = withDelay(
      0,
      withSequence(
        withTiming(expandedWidth + 12, { duration: 300 }),
        withTiming(0, {
          duration: 500,
          easing: Easing.bezier(0.76, 0, 0.24, 1),
        }),
      ),
    );
  };

  const onChildrenTap = () => {
    let newValue = 0;
    if (x.value < 30) {
      newValue = expandedWidth;
    }

    x.value = withTiming(newValue, {
      duration: 400,
    });
  };

  return (
    <PanGestureHandler
      onGestureEvent={gestureHandler}
      activeOffsetX={[-10, 10]}
    >
      <Animated.View
        style={[
          styles.container,
          type === 'multiTable' && styles.itemMultiTable,
          type === 'cancelled' && styles.itemCancelled,
          type === 'createdByRestaurant' && styles.itemByRestaurant,
          type === 'webForm' && styles.itemWebForm,
          type === 'walkIn' && styles.itemWalkIn,
          type === 'online' && styles.itemOnline,
        ]}
      >
        <View
          style={{
            backgroundColor: '#ebecf3',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: 8,
          }}
        />
        <Animated.View style={[styles.menuContainer]}>
          <Animated.View
            style={[styles.menuInnerContainer, animatedStyleMenuContainer]}
          >
            <MenuItem
              text={'Details'}
              icon={<Ionicons name={'book'} size={17} />}
              onPress={() => onItemPress(() => setTimeout(onPressDetails, 500))}
            />
            {/*<MenuItem*/}
            {/*  text={'Edit'}*/}
            {/*  icon={<Ionicons name={'pencil'} size={17} />}*/}
            {/*  onPress={() => onItemPress(onPressEdit)}*/}
            {/*/>*/}
            {/*<MenuItem*/}
            {/*  text={'Move'}*/}
            {/*  icon={<Ionicons name={'move'} size={17} />}*/}
            {/*  onPress={() => onItemPress(onPressMove)}*/}
            {/*/>*/}
            <MenuItem
              text={'Cancel'}
              icon={<Ionicons name={'trash-bin'} size={17} />}
              onPress={() => onItemPress(onPressCancel)}
              requireConfirmation
            />
          </Animated.View>
        </Animated.View>
        <Animated.View
          style={[styles.childrenContainer, animatedStyleContainer]}
        >
          <TapGestureHandler
            onHandlerStateChange={({ nativeEvent }) => {
              if (nativeEvent.state === State.ACTIVE) {
                onChildrenTap();
              }
            }}
          >
            <Animated.View>{children}</Animated.View>
          </TapGestureHandler>
        </Animated.View>
      </Animated.View>
    </PanGestureHandler>
  );
};

export default SwipeItemMenu;
const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    backgroundColor: '#ebecf3',
    width: '100%',
  },
  childrenContainer: {
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    overflow: 'visible',
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 0,
  },
  menuInnerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    paddingLeft: 5 + 6,
    paddingRight: 0 + 6,
  },
  itemByRestaurant: {
    backgroundColor: 'rgba(86,196,95,0.8)',
  },
  itemOnline: {
    backgroundColor: 'rgba(250,65,65,0.8)',
  },
  itemWebForm: {
    backgroundColor: 'rgba(255,115,54,0.7)',
  },
  itemWalkIn: {
    backgroundColor: 'rgba(248,155,218,0.9)',
  },
  itemCancelled: {
    backgroundColor: '#c3c8cb',
  },
  itemMultiTable: {
    backgroundColor: 'rgba(82,155,229,0.7)',
  },
});
