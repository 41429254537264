import React from 'react';
import { Picker, View } from 'react-native';
import moment from 'moment';

Array.range = (start, end, inteval) =>
  Array.from(
    { length: Math.ceil((end - start) / inteval) },
    (v, k) => k * inteval + start,
  );

export default class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHours: 12,
      selectedMinutes: 0,
    };
  }

  updateHours = hours => {
    const { selectedMinutes } = this.state;
    const newTime = moment(`${hours}:${selectedMinutes}`, 'HH:m');
    this.setState({
      selectedHours: hours,
    });
    const { updateTime } = this.props;
    updateTime(newTime.format('HH:mm'));
  };

  updateMinutes = minutes => {
    const { selectedHours } = this.state;
    const newTime = moment(`${selectedHours}:${minutes}`, 'HH:m');

    this.setState({
      selectedMinutes: minutes,
    });
    const { updateTime } = this.props;
    updateTime(newTime.format('HH:mm'));
  };

  render() {
    const { selectedHours, selectedMinutes } = this.state;

    const hours = Array.range(0, 24, 1).map(i => {
      return (
        <Picker.Item label={i.toString().padStart(2, '0')} value={i} key={i} />
      );
    });

    const minutes = Array.range(0, 60, 15).map(i => {
      return (
        <Picker.Item label={i.toString().padStart(2, '0')} value={i} key={i} />
      );
    });

    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: 200,
        }}
      >
        <Picker
          selectedValue={selectedHours}
          style={{ flex: 1 }}
          onValueChange={(itemValue, itemIndex) => this.updateHours(itemValue)}
          mode="modal"
        >
          {hours}
        </Picker>
        <Picker
          selectedValue={selectedMinutes}
          style={{ flex: 1 }}
          onValueChange={(itemValue, itemIndex) =>
            this.updateMinutes(itemValue)
          }
          mode="modal"
        >
          {minutes}
        </Picker>
      </View>
    );
  }
}
