import React from 'react';
import {
  Animated,
  Dimensions,
  Image,
  PanResponder,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { Ionicons } from '@expo/vector-icons';
import { withGlobalContext } from '../../GlobalContext';
import TimelineHeadStyle from '../../styles/TimelineHeadStyle';

import CalendarModal from '../Timeline/modals/CalendarModal';
import Colors from '../../constants/Colors';
import DateSelectItem from '../../components/timelineComponents/DateSelectItem';
import TimeRangeSelector from '../../components/TimeRangeSelector';
import Style from '../../constants/Style';
import RoomStatic from './RoomsComponents/RoomStatic';
import Buttons from '../../styles/Buttons';
import ListReservations from './RoomsComponents/ListReservations';
import NewMultitableReservation from './RoomsComponents/NewMultitableReservation';

const moment = extendMoment(Moment);

class Spaces2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCalendarVisible: false,
      selectedRoomId: null,
      viewDimensions: { height: 100, width: 100 },
      timeFrom: '1970-01-01 11:00',
      timeTo: '1970-01-01 13:15',
      roomSelectMenuAnimated: new Animated.Value(1),
      showRoomSelectMenu: true,
      showNewReservation: false,
      selectedTables: new Set(),
      animating: false,
    };
  }

  reservationsHeight = new Animated.Value(300);

  panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => {
      console.log('okk....');
      return true;
    },
    onPanResponderGrant: () => {
      this.reservationsHeight.setOffset(this.reservationsHeight._value);
    },
    onPanResponderMove: (evt, gestureState) => {
      this.reservationsHeight.setValue(-gestureState.dy);
    },
    onPanResponderRelease: () => {
      this.reservationsHeight.flattenOffset();
      if (this.reservationsHeight._value < 49) {
        Animated.spring(this.reservationsHeight, {
          toValue: 49,
          useNativeDriver: false,
        }).start();
      } else if (
        this.reservationsHeight._value >
        this.state.viewDimensions.height - 8
      ) {
        Animated.spring(this.reservationsHeight, {
          toValue: this.state.viewDimensions.height - 8,
          useNativeDriver: false,
        }).start();
      }
    },
  });

  componentDidMount() {
    const { rooms } = this.props.global;
    if (rooms && rooms.length > 0) {
      this.changeSelectedRoom(rooms[0].id);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.animating) {
      return false;
    }
    return true;
    // return this.state.showRoomSelectMenu === nextState.showRoomSelectMenu;
  }

  componentDidUpdate = prevProps => {
    const { selectedDate } = this.props.global;
    if (prevProps.global.selectedDate !== selectedDate) {
      this.updateTimeInterval(selectedDate);
    }
  };

  expandNewReservation = () => {
    Animated.spring(this.reservationsHeight, {
      toValue: this.state.viewDimensions.height - 8,
      useNativeDriver: false,
    }).start();
  };

  plusMinusDate = action => {
    const { selectedDate } = this.props.global;
    let changeValue = 0;
    if (action === 'add') {
      changeValue = 1;
    } else if (action === 'subtract') {
      changeValue = -1;
    }

    const date = moment(selectedDate.clone().add(changeValue, 'days')).format(
      'YYYY-MM-DD',
    );

    this.props.global.updateSelectedDate(date);
  };

  changeSelectedDate = date => {
    this.hideCalendarModal();
    this.props.global.updateSelectedDate(date);
  };

  updateTimeInterval = () => {
    const { timeFrom, timeTo } = this.state;
    const { selectedDate } = this.props.global;
    this.setState({
      timeFrom: `${moment(selectedDate).format('YYYY-MM-DD')} ${moment(
        timeFrom,
      ).format('HH:mm')}`,
      timeTo: `${moment(selectedDate).format('YYYY-MM-DD')} ${moment(
        timeTo,
      ).format('HH:mm')}`,
    });
  };

  changeSelectedRoom = roomId => {
    this.setState({ selectedRoomId: roomId, selectedTables: new Set() });
    if (this.isPortrait()) {
      this.switchRoomSelectMenu(true);
    }
  };

  showCalendarModal = () => {
    this.setState({ isCalendarVisible: true });
  };

  hideCalendarModal = () => {
    this.setState({ isCalendarVisible: false });
  };

  getTimeInterval = (timeFrom, timeTo) => {
    this.setState({
      timeFrom,
      timeTo,
    });
  };

  switchReservations = () => {
    const { showNewReservation, viewDimensions } = this.state;
    const { selectedDate, getReservations } = this.props.global;
    getReservations(selectedDate?.format('YYYY-MM-DD'), true);
    this.setState({
      showNewReservation: !showNewReservation,
      selectedTables: new Set(),
    });
    // Animated.spring(
    //   this.reservationsHeight, // Auto-multiplexed
    //   {
    //     toValue: viewDimensions.height - 30,
    //     // speed: 30,
    //     useNativeDriver: false,
    //   },
    // ).start();
  };

  reservationsInRoomDuringInterval = () => {
    const { reservations, rooms, openHours, selectedDate } = this.props.global;
    const { selectedRoomId, timeFrom, timeTo } = this.state;
    const room = rooms?.find?.(element => element.id === selectedRoomId);

    const tablesInRoom = [];
    const date = selectedDate.format('YYYY-MM-DD');

    const dFrom = moment(timeFrom);
    const dTo = moment(timeTo);
    const selectedRange = moment.range(dFrom, dTo);

    if (room && reservations) {
      room.tables.forEach(table => tablesInRoom.push(table.table_id));

      let reservationsOnDate = [];

      if (reservations[date] && reservations[date].reservations) {
        // all reservations on selected date
        reservationsOnDate = reservations[date].reservations;
      }

      reservationsOnDate = reservationsOnDate.filter(reservation => {
        const reservationRange = moment.range(
          moment(reservation.reservation_date),
          moment(reservation.reservation_date_end),
        );
        return (
          selectedRange.overlaps(reservationRange) && // reservations overlaping selected range
          reservation.tables.some(v => tablesInRoom.indexOf(v.id) !== -1) && // reservations in selected room
          !reservation.cancelled // reservation isn't cancelled
        );
      });

      return reservationsOnDate;
    }
  };

  registerTableClick = table => {
    const { selectedTables, showNewReservation } = this.state;

    const occupiedTables = new Set();
    this.reservationsInRoomDuringInterval().forEach(reservation => {
      reservation.tables.forEach(table2 => occupiedTables.add(table2.id));
    });

    if (
      !selectedTables.has(table) &&
      showNewReservation &&
      !occupiedTables.has(table.table_id)
    ) {
      selectedTables.add(table);
    } else {
      selectedTables.delete(table);
    }
    this.setState({ selectedTables });
    // console.log(`Table: ${table.table_id}`);
    // console.log(`occupiedTables tables: ${Array.from(occupiedTables)}`);
  };

  switchRoomSelectMenu(hide) {
    const { showRoomSelectMenu, roomSelectMenuAnimated } = this.state;
    let toValue = showRoomSelectMenu ? 0 : 1;
    if (hide) {
      toValue = 0;
    }

    this.setState({ animating: true });
    Animated.timing(roomSelectMenuAnimated, {
      toValue: toValue,
      duration: 350,
      useNativeDriver: false,
    }).start(() => this.setState({ animating: false }));
    this.setState({
      showRoomSelectMenu: !showRoomSelectMenu && !hide,
    });
  }

  isPhoneAndPortrait = () => {
    const { height, width } = Dimensions.get('window');
    return width < 800 && height > width;
  };

  isPortrait = () => {
    const { height, width } = Dimensions.get('window');
    return height > width;
  };

  isPhone = () => {
    const { height, width } = Dimensions.get('window');
    return height < 600 || width < 600;
  };

  render() {
    const {
      isCalendarVisible,
      selectedRoomId,
      timeFrom,
      timeTo,
      viewDimensions,
      roomSelectMenuAnimated,
      selectedTables,
      showNewReservation,
      showRoomSelectMenu,
    } = this.state;

    const { rooms, openHours, selectedDate, deviceType } = this.props.global;

    const room = rooms?.find?.(element => element.id === selectedRoomId);

    let roomContainer = null;
    let reservationsOnInterval = [];
    const occupiedTables = new Set();

    if (room && viewDimensions) {
      let k = (viewDimensions.width - 40) / room.dimensions.width;
      if (k * room.dimensions.height >= viewDimensions.height) {
        k = (viewDimensions.height - 40) / room.dimensions.height;
      }
      reservationsOnInterval = this.reservationsInRoomDuringInterval();

      reservationsOnInterval.forEach(reservation => {
        reservation.tables.forEach(table => occupiedTables.add(table.id));
      });

      roomContainer = {
        width: k * room.dimensions.width,
        height: k * room.dimensions.height,
        fullWidth: viewDimensions.width,
        scaleFactor: k,
      };
    }
    let openTimes = { open_time: '10:00', close_time: '20:00' };
    if (
      openHours &&
      openHours.length > 0 &&
      openHours[0].days[selectedDate.format('ddd').toLowerCase()]
    ) {
      openTimes = openHours[0].days[selectedDate.format('ddd').toLowerCase()];
    }

    const hours = {
      from: openTimes.open_time
        ? moment(openTimes.open_time, 'HH:mm').hour()
        : 0,
      to: openTimes.close_time
        ? moment(openTimes.close_time, 'HH:mm').hour()
        : 0,
    };

    const rangeEnd = moment(selectedDate)
      .add(hours.from < hours.to ? 0 : 1, 'days')
      .minutes(0)
      .hours(hours.to);

    const rangeStart = moment(selectedDate)
      .minutes(0)
      .hours(hours.from);

    const selectedRoom = rooms?.find?.(
      element => element.id === selectedRoomId,
    );
    // console.log(selectedRoom);
    const deviceWidth = Dimensions.get('window').width;

    return (
      <SafeAreaView style={TimelineHeadStyle.mainContainer} edges={['top']}>
        <View
          style={[
            TimelineHeadStyle.container,
            {
              flex: 2,
            },
          ]}
        >
          <View
            style={[
              TimelineHeadStyle.topBarContainer,
              this.isPhone() && TimelineHeadStyle.topBarContainerSmall,
              this.isPhoneAndPortrait() &&
                TimelineHeadStyle.topBarContainerNotch,
            ]}
          >
            <View
              style={[
                TimelineHeadStyle.topBarSpace,
                this.isPhoneAndPortrait() && TimelineHeadStyle.topBarSpaceFixed,
                { justifyContent: 'flex-start', flexDirection: 'row' },
              ]}
            >
              <Image
                style={[
                  TimelineHeadStyle.logo,
                  this.isPhone() && TimelineHeadStyle.logoSmall,
                  this.isPhoneAndPortrait() && TimelineHeadStyle.logoSquare,
                ]}
                source={
                  this.isPhoneAndPortrait()
                    ? require('../../assets/images/reetab_logo.png')
                    : require('../../assets/images/reetab.png')
                }
                resizeMode={'contain'}
              />
            </View>
            <View style={TimelineHeadStyle.topBarSpace}>
              <DateSelectItem
                selectedDate={selectedDate}
                changeSelectedDate={this.changeSelectedDate}
                plusMinusDate={this.plusMinusDate}
                showCalendarModal={this.showCalendarModal}
                size={this.isPhone() ? 'small' : 'big'}
              />
            </View>
            <View
              style={[
                TimelineHeadStyle.topBarSpace,
                this.isPhoneAndPortrait() && TimelineHeadStyle.topBarSpaceFixed,
                {
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                },
              ]}
            >
              <View>
                <TouchableOpacity
                  style={[
                    Buttons.button,
                    this.isPhoneAndPortrait() && Buttons.buttonSmall,
                    showNewReservation && Buttons.blueBackground,
                  ]}
                  onPress={() => this.switchReservations()}
                >
                  <Text
                    style={[
                      Buttons.buttonIcon,
                      { marginRight: this.isPhoneAndPortrait() ? 0 : 0 },
                    ]}
                  >
                    <Ionicons
                      name={showNewReservation ? 'list' : 'add'}
                      size={showNewReservation ? 20 : 22}
                    />
                  </Text>
                  {this.isPhoneAndPortrait() || (
                    <Text style={Buttons.buttonText}>
                      {showNewReservation
                        ? ' Show Reservations '
                        : ' Reservation '}
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View
              style={[
                {
                  paddingLeft: 0,
                  // paddingTop: 10,
                  zIndex: 2,
                  backgroundColor: Colors.white,
                },
                Style.shadowDown,
              ]}
              pointerEvents={'box-none'}
            >
              <TimeRangeSelector
                rangeFrom={rangeStart.format('YYYY-MM-DDTHH:mm:ss')}
                rangeTo={rangeEnd.format('YYYY-MM-DDTHH:mm:ss')}
                // hourWidth={this.isPhone() ? deviceWidth / 7 : deviceWidth / 12}
                hourWidth={Math.max(deviceWidth / 12, 52)}
                height={this.isPhone() ? 36 : 40}
                fontSize={this.isPhone() ? 13 : 14}
                selectedFrom={timeFrom}
                selectedTo={timeTo}
                snapInterval={this.isPhone() ? 30 : 15}
                minRange={45}
                showTicks={true}
                showControlls={Platform.OS === 'web' || false}
                onIntervalUpdate={this.getTimeInterval}
              />
            </View>
            <View
              style={{
                // flexDirection: 'row',
                flexDirection: this.isPortrait() ? 'column' : 'row',
                flex: 1,
                marginTop: 0,
                backgroundColor: Colors.lightest,
              }}
            >
              <Animated.View
                style={[
                  {
                    backgroundColor: Colors.white,
                    zIndex: 1,
                    maxHeight: this.isPortrait()
                      ? roomSelectMenuAnimated.interpolate({
                          inputRange: [0, 1],
                          outputRange: [46, 250],
                          // outputRange: [46, 140],
                        })
                      : null,
                  },
                  Style.shadowDown,
                ]}
              >
                <Animated.View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Animated.Text
                    selectable={false}
                    style={{
                      color: Colors.darker,
                      fontSize: 17,
                      marginLeft: roomSelectMenuAnimated.interpolate({
                        inputRange: [0, 1],
                        outputRange: [this.isPortrait() ? 16 : 0, 16],
                      }),
                      opacity: this.isPortrait() ? 1 : roomSelectMenuAnimated,
                      // textAlign: 'center',
                      maxWidth: this.isPortrait()
                        ? null
                        : roomSelectMenuAnimated.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, 300],
                          }),

                      fontWeight: '500',
                    }}
                    numberOfLines={1}
                  >
                    {!showRoomSelectMenu &&
                      this.isPortrait() &&
                      selectedRoom?.name}
                    {showRoomSelectMenu && this.isPortrait() && 'Spaces'}
                    {!this.isPortrait() && 'Spaces'}
                  </Animated.Text>
                  <Animated.View>
                    <TouchableOpacity
                      onPress={() => this.switchRoomSelectMenu()}
                      style={{ paddingHorizontal: 16, paddingVertical: 12 }}
                    >
                      <Ionicons
                        name="ios-menu"
                        size={23}
                        style={{
                          color: Colors.dark,
                        }}
                      />
                    </TouchableOpacity>
                  </Animated.View>
                </Animated.View>
                <Animated.ScrollView>
                  {rooms?.map?.((room, key) => {
                    return (
                      <Animated.View
                        key={key}
                        style={{
                          flex: 1,
                          backgroundColor:
                            room.id === selectedRoomId
                              ? Colors.confirmGreen
                              : null,
                          borderColor:
                            room.id !== selectedRoomId
                              ? Colors.lightererer
                              : 'rgba(0,0,0,0)',
                          borderTopWidth: roomSelectMenuAnimated.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0.25, 0],
                          }),
                          borderBottomWidth: roomSelectMenuAnimated.interpolate(
                            {
                              inputRange: [0, 1],
                              outputRange: [0.25, 0],
                            },
                          ),
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => this.changeSelectedRoom(room.id)}
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingVertical: 10,
                            paddingHorizontal: 16,
                          }}
                        >
                          <Animated.Text
                            style={{
                              fontSize: 15,
                              fontWeight:
                                room.id === selectedRoomId ? '500' : '500',
                              color:
                                room.id === selectedRoomId
                                  ? Colors.white
                                  : Colors.dark,
                              textTransform: 'capitalize',
                              opacity: roomSelectMenuAnimated,
                              maxWidth: roomSelectMenuAnimated.interpolate({
                                inputRange: [0, 1],
                                outputRange: [0, 300],
                              }),
                              paddingRight: roomSelectMenuAnimated.interpolate({
                                inputRange: [0, 1],
                                outputRange: [0, 20],
                              }),
                            }}
                            numberOfLines={1}

                            // ellipsizeMode={'head'}
                          >
                            {room.name}
                          </Animated.Text>
                          <Ionicons
                            name={
                              room.id === selectedRoomId
                                ? 'chevron-forward'
                                : null
                            }
                            size={17}
                            style={{
                              color:
                                room.id === selectedRoomId
                                  ? Colors.white
                                  : Colors.light,
                              width: 17,
                              marginHorizontal: 6,
                              textAlign: 'right',
                            }}
                          />
                        </TouchableOpacity>
                      </Animated.View>
                    );
                  })}
                </Animated.ScrollView>
              </Animated.View>
              <View
                style={{
                  flex: 1,
                  height: '100%',
                  justifyContent: this.isPortrait() ? 'flex-start' : 'center',
                  paddingVertical: 12,
                  alignItems: 'center',
                }}
                ref={ref => {
                  this.marker = ref;
                }}
                onLayout={({ nativeEvent }) => {
                  if (this.marker) {
                    this.marker.measure((x, y, width, height) => {
                      if (
                        !viewDimensions ||
                        viewDimensions.width !== width ||
                        viewDimensions.height !== height
                      ) {
                        if (width && height) {
                          this.setState({
                            viewDimensions: { width, height },
                          });
                        }
                      }
                    });
                  }
                }}
              >
                <RoomStatic
                  room={room}
                  roomContainer={roomContainer}
                  // occupiedTables={occupiedTables}
                  occupiedTables={Array.from(occupiedTables).map(tableId => {
                    return tableId;
                  })}
                  selectedTables={Array.from(selectedTables).map(table => {
                    return table.table_id;
                  })}
                  registerTableClick={this.registerTableClick}
                  enableSelection
                  backgroundColor="rgba(255,255,255, 0.7)"
                />
              </View>

              <Animated.View
                style={[
                  {
                    // backgroundColor: Colors.white,
                    width: 350,
                    zIndex: 20,
                  },
                  this.isPortrait() && {
                    width: Dimensions.get('window').width,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: this.reservationsHeight.interpolate({
                      inputRange: [
                        0,
                        Math.max(this.state.viewDimensions.height + 48, 0),
                      ],
                      outputRange: [
                        0,
                        Math.max(this.state.viewDimensions.height + 48, 0),
                      ],
                      extrapolate: 'clamp',
                    }),
                    // height: this.reservationsHeight,
                  },
                ]}
              >
                {showNewReservation ? (
                  <NewMultitableReservation
                    panHandlers={this.panResponder.panHandlers}
                    dateFrom={timeFrom}
                    dateTo={timeTo}
                    selectedTables={Array.from(selectedTables)}
                    onSuccess={() => this.switchReservations()}
                    onBack={() => this.switchReservations()}
                    vertical={this.isPortrait()}
                    expand={this.expandNewReservation}
                  />
                ) : (
                  <ListReservations
                    panHandlers={this.panResponder.panHandlers}
                    reservationsOnInterval={reservationsOnInterval}
                    newReservation={() => this.switchReservations()}
                    vertical={this.isPortrait()}
                  />
                )}
              </Animated.View>
            </View>
          </View>
          <CalendarModal
            isVisible={isCalendarVisible}
            onBackDropPress={this.hideCalendarModal}
            selectedDate={selectedDate}
            changeSelectedDate={date => this.changeSelectedDate(date)}
            size={this.isPhone() ? 'small' : 'big'}
          />
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({});

const Spaces = withGlobalContext(Spaces2);
Spaces.navigationOptions = {
  headerShown: false,
  // headerTitle: () => <View />,
  //   // headerStyle: {
  //   //   shadowRadius: 0,
  //   //   height: null,
  //   //   shadowOffset: {
  //   //     height: 0,
  //   //   },
  //   // },
};
export default Spaces;
