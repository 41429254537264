import React, { useContext, useState } from 'react';
import {
  Alert,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';

import ModalStyle from '../../../styles/ModalStyle';
import Buttons from '../../../styles/Buttons';
import { GlobalContext } from '../../../GlobalContext';
import ModalWrapper from '../components/ModalWrapper';
import LayoutServices from '../../../services/LayoutServices';
import CountIncreaser from '../../../components/CountIncreaser';

const Row = ({ children, high, last }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: high ? 150 : 50,
      }}
    >
      {children}
    </View>
  );
};

const NewSpaceModal = props => {
  const { visible, close } = props;
  const global = useContext(GlobalContext);

  const [name, setName] = useState('');
  const [width, setWidth] = useState(1000);
  const [height, setHeight] = useState(700);
  const [isOutside, setIsOutside] = useState(false);
  const [floor, setFloor] = useState(0);

  const floorOptions = [];
  floorOptions[0] = { label: 'Ground', key: 0, value: 0 };
  floorOptions[1] = { label: '1st', key: 1, value: 1 };
  floorOptions[2] = { label: '2nd', key: 2, value: 2 };
  floorOptions[3] = { label: '3rd', key: 3, value: 3 };
  for (let i = 4; i < 100; i += 1) {
    floorOptions[i] = {
      label: `${i}th`,
      key: i,
      value: i,
    };
  }

  const createRoom = () => {
    const { updateRooms, showSnackbarMessage } = global;

    const data = {
      name,
      dimensions: { width: parseInt(width, 10), height: parseInt(height, 10) },
      outside: isOutside,
      floor,
    };

    LayoutServices.addNewRoom(data).then(result => {
      if (result.ok) {
        showSnackbarMessage(`Space ${name} was created.`);
        updateRooms();
        close();
      } else {
        Alert.alert('Something went wrong', result.message);
      }
    });
  };

  return (
    <ModalWrapper onClose={close} title="New Space" isVisible={visible}>
      <Row>
        <Text style={ModalStyle.fontLeft}>Name</Text>
        <View style={ModalStyle.touchableSelect}>
          <TextInput
            style={[
              ModalStyle.fontRight,
              ModalStyle.inputRight,
              { color: '#222', paddingVertical: 4 },
            ]}
            placeholderTextColor={Colors.placeholder}
            onChangeText={value => setName(value)}
            placeholder="Enter space name"
            value={name}
          />
        </View>
      </Row>

      <Row>
        <Text style={ModalStyle.fontLeft}>
          Size <Text style={{ color: Colors.lighter, fontSize: 16 }}>(cm)</Text>
        </Text>
        <View style={ModalStyle.touchableSelect}>
          <TextInput
            style={ModalStyle.dimensionsInput}
            placeholderTextColor={Colors.placeholder}
            selectTextOnFocus
            onChangeText={value => setWidth(value)}
            maxLength={5}
            multiline={false}
            keyboardType="number-pad"
            value={String(width)}
            autoCorrect={false}
          />
          <Ionicons
            name="close"
            size={18}
            style={{ paddingHorizontal: 10, color: Colors.darker }}
          />
          <TextInput
            style={ModalStyle.dimensionsInput}
            placeholderTextColor={Colors.placeholder}
            selectTextOnFocus
            onChangeText={value => setHeight(value)}
            maxLength={5}
            multiline={false}
            keyboardType="number-pad"
            value={String(height)}
            autoCorrect={false}
          />
        </View>
      </Row>
      <Row>
        <Text style={ModalStyle.fontLeft}>Floor</Text>
        <CountIncreaser
          initialIndex={Math.max(floor - 1, 0)}
          optionsArray={floorOptions}
          updateValueFunc={value => setFloor(value)}
          circleBorder={0}
          valueWidth={65}
          iconSize={24}
        />
      </Row>
      <Row>
        <Text style={ModalStyle.fontLeft}>Outside</Text>
        <View style={ModalStyle.touchableSelect}>
          <Switch onValueChange={setIsOutside} value={isOutside} />
        </View>
      </Row>

      <View style={Buttons.container}>
        <TouchableOpacity
          style={[Buttons.big, Buttons.green]}
          onPress={() => createRoom()}
        >
          <Text style={Buttons.text}>Add New Space</Text>
        </TouchableOpacity>
      </View>
    </ModalWrapper>
  );
};
export default NewSpaceModal;
