import AppLoading from 'expo-app-loading';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useEffect, useState } from 'react';
import { StatusBar, useWindowDimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import {
  initialWindowMetrics,
  SafeAreaProvider,
} from 'react-native-safe-area-context';
import { Provider as PaperProvider } from 'react-native-paper';
import AppNavigator from './navigation/AppNavigator';
import NavigationService from './services/NavigationService';
import { GlobalContextProvider } from './GlobalContext';

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [hideStatusBar, setHideStatusBar] = useState(false);
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (height < 400) {
      setHideStatusBar(true);
    } else {
      setHideStatusBar(false);
    }
  }, [height]);

  const { skipLoadingScreen } = props;
  if (!isLoadingComplete && !skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  }
  return (
    <PaperProvider>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <GlobalContextProvider>
          <StatusBar
            barStyle="dark-content"
            backgroundColor={'white'}
            hidden={hideStatusBar}
          />
          {/*{Platform.OS !== 'ios' && (*/}
          {/*  // <StatusBar hidden={!hideStatusBar} translucent={true} />*/}
          {/*)}*/}
          <AppNavigator
            ref={navigatorRef => {
              NavigationService.setTopLevelNavigator(navigatorRef);
            }}
          />
        </GlobalContextProvider>
      </SafeAreaProvider>
    </PaperProvider>
  );
}

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([
      require('./assets/images/robot-dev.png'),
      require('./assets/images/robot-prod.png'),
      require('./assets/images/icon.png'),
      require('./assets/images/logo.png'),
      require('./assets/images/no_results.jpg'),
      require('./assets/images/reetab_business_app_icon.png'),
    ]),
    Font.loadAsync({
      // This is the font that we are using for our tab bar
      ...Ionicons.font,
      // We include SpaceMono because we use it in HomeScreen.js. Feel free to
      // remove this if you are not using it in your app
      'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
      // Ionicons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'),
    }),
  ]);
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}
