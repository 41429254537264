import React from 'react';
import {
  Alert,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import moment from 'moment';

import { TextInputMask } from 'react-native-masked-text';
import CountIncreaser from '../../../components/CountIncreaser';

import ReservationServices from '../../../services/ReservationsServices';
import Colors from '../../../constants/Colors';
import Buttons from '../../../styles/Buttons';
import Collapsible from '../../../components/Collapsible';
import ModalWrapper from '../../Setup/components/ModalWrapper';

export default class QuickAddReservationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      selectedDuration: null,
      selectedPersons: 2,
      name: '',
      phone: '',
      table: null,
      params: null,
      note: '',
      isNoteCollapsed: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.params !== state.params && props.params) {
      return {
        params: props.params,
        selectedDate: props.params.date,
        table: props.params.table,
        name: '',
        phone: '',
        note: '',
        isNoteCollapsed: true,
        selectedPersons: props.params.persons || 2,
        selectedDuration: props.params.duration || moment.duration('02:00'),
      };
    }
    return null;
  }

  closeMe = force => {
    const { hideModal } = this.props;
    hideModal(force);
  };

  updatePersonsCount = value => {
    this.setState({ selectedPersons: value });
  };

  updateDurationValue = value => {
    this.setState({ selectedDuration: value });
  };

  async submitReservation(walkIn = false) {
    const { jwtToken, restaurantId, showAlert } = this.props;
    const {
      name,
      phone,
      table,
      selectedDate,
      selectedDuration,
      selectedPersons,
      note,
    } = this.state;

    const dateTo = selectedDate.clone().add(selectedDuration);

    const data = {};
    data.customer = { fullname: name, phone };
    data.restaurant_id = restaurantId;
    data.tables = [table.id];
    data.date_from = selectedDate.toISOString(true);
    data.date_to = dateTo.toISOString(true);
    data.persons = selectedPersons;
    data.note = note;

    const result = await ReservationServices.submitReservation(
      jwtToken,
      data,
      walkIn,
    );
    if (result.ok) {
      this.closeMe(true);
      showAlert('Reservation was added successfully', 'success');
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  switchCollapse = () => {
    const { isNoteCollapsed } = this.state;
    this.setState({
      isNoteCollapsed: !isNoteCollapsed,
    });
  };

  render(fontWeight = '400') {
    const { isVisible, fullScreen } = this.props;
    const { closeMe } = this;
    const { selectedDate, selectedDuration, table, params } = this.state;

    if (!params) {
      return null;
    }

    const chairsOptions = [];
    for (let i = 0; i < table?.chairs; i += 1) {
      chairsOptions[i] = {
        label: i + 1,
        key: i + 1,
        value: i + 1,
      };
    }

    const durationOptions = [];
    for (let i = 3; i < 50; i += 1) {
      const hours = Math.floor(i / 4);
      const minutes = (i % 4) * 15;
      const minutesString = minutes === 0 ? '00' : minutes;
      const time = `${hours}:${minutesString}`;

      durationOptions[i - 3] = {
        key: i - 3,
        label: time,
        value: moment.duration(15 * i, 'minutes'),
      };
    }
    const durationIndex = selectedDuration
      ? Math.floor(selectedDuration.asMinutes() / 15 - 3)
      : 0;

    const showWalkIn =
      moment.duration(selectedDate.diff(moment())).asHours() < 2;
    return (
      <ModalWrapper
        onClose={closeMe}
        title={'Add Reservation'}
        isVisible={isVisible}
      >
        {/*<Modal*/}
        {/*  // isVisible={isVisible}*/}
        {/*  visible={isVisible}*/}
        {/*  onRequestClose={() => closeMe()}*/}
        {/*  backdropOpacity={0.5}*/}
        {/*  animationIn="fadeIn"*/}
        {/*  animationInTiming={200}*/}
        {/*  animationOut="fadeOut"*/}
        {/*  animationOutTiming={300}*/}
        {/*  // onBackdropPress={() => closeMe()}*/}
        {/*  coverScreen*/}
        {/*  scrollHorizontal*/}
        {/*  backdropTransitionOutTiming={0}*/}
        {/*  hideModalContentWhileAnimating*/}
        {/*  style={fullScreen ? { margin: 0 } : null}*/}
        {/*  // style={{ margin: 0 }}*/}
        {/*>*/}
        {/*<SafeAreaView*/}
        {/*  style={[*/}
        {/*    fullScreen*/}
        {/*      ? ModalStyle.modalContainerFullScreen*/}
        {/*      : ModalStyle.modalContainer,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <View style={ModalStyle.headerBar}>*/}
        {/*    <View style={ModalStyle.headerNavigation} />*/}

        {/*    <Text style={ModalStyle.headerTitle}>Add Reservation</Text>*/}
        {/*    <TouchableOpacity*/}
        {/*      style={ModalStyle.headerNavigation}*/}
        {/*      onPress={() => closeMe()}*/}
        {/*    >*/}
        {/*      <Ionicons name="md-close" size={26} color="#ccc" />*/}
        {/*    </TouchableOpacity>*/}
        {/*  </View>*/}
        {/*  <KeyboardAwareScrollView*/}
        {/*    style={{ marginHorizontal: 16, flex: null }}*/}
        {/*    extraHeight={240}*/}
        {/*  >*/}
        <View>
          <View style={styles.info_line}>
            <Text style={styles.info_left}>When</Text>
            <Text style={styles.info_right}>
              {selectedDate.calendar(null, {
                sameDay: '[Today] [at] HH:mm',
                nextDay: '[Tomorrow] [at] HH:mm',
                nextWeek: 'ddd, D. MMM [at] HH:mm',
                lastDay: 'ddd, D. MMM [at] HH:mm',
                lastWeek: 'ddd, D. MMM [at] HH:mm',
                sameElse: 'ddd, D. MMM [at] HH:mm',
              })}
              {/* {timeIcon} */}
            </Text>
          </View>
          <View style={styles.info_line}>
            <Text style={styles.info_left}>Table</Text>
            <Text style={styles.info_right}>{table.table_number}</Text>
          </View>
          <View style={styles.info_line}>
            <Text style={styles.info_left}>Name </Text>
            <TextInput
              style={[
                styles.info_right,
                styles.info_right_input,
                { color: '#222' },
              ]}
              onChangeText={name => this.setState({ name })}
              placeholder="customer's name"
              placeholderTextColor={Colors.placeholder}
              value={this.state.name}
              autoCapitalize="words"
              autoCorrect={false}
            />
          </View>
          <View style={styles.info_line}>
            <Text style={styles.info_left}>Phone</Text>
            <TextInputMask
              type="custom"
              options={{
                mask: '999 999 999',
              }}
              style={[
                styles.info_right,
                styles.info_right_input,
                { color: '#222' },
              ]}
              onChangeText={phone => this.setState({ phone })}
              placeholder="phone number"
              placeholderTextColor={Colors.placeholder}
              value={this.state.phone}
              selectTextOnFocus
              autoCorrect={false}
              textContentType="telephoneNumber"
              keyboardType="phone-pad"
            />
          </View>
          <View style={[styles.info_line, { borderBottomWidth: 0 }]}>
            <Text style={styles.info_left}>Duration </Text>
            <View style={styles.info_right}>
              <CountIncreaser
                initialIndex={durationIndex}
                optionsArray={durationOptions}
                updateValueFunc={value => this.updateDurationValue(value)}
                circleBorder={0}
                textColor="#000"
                iconSize={28}
                addIcon="ios-add"
                removeIcon="ios-remove"
                valueWidth={50}
              />
            </View>
          </View>
          <View style={[styles.info_line, { borderBottomWidth: 0 }]}>
            <Text style={styles.info_left}>Guests </Text>
            <View style={styles.info_right}>
              <CountIncreaser
                initialIndex={table?.chairs ? table.chairs - 1 : 0}
                optionsArray={chairsOptions}
                updateValueFunc={value => this.updatePersonsCount(value)}
                circleBorder={0}
                textColor="#000"
                iconSize={28}
                addIcon="ios-add"
                removeIcon="ios-remove"
                valueWidth={50}
              />
            </View>
          </View>

          <View style={[styles.info_line, { borderBottomWidth: 0 }]}>
            <Text style={styles.info_left}>Note</Text>
            <TouchableOpacity onPress={() => this.switchCollapse()}>
              <Text style={[styles.info_right, { color: Colors.lightBlue }]}>
                {this.state.isNoteCollapsed ? 'add a note' : null}
              </Text>
            </TouchableOpacity>
          </View>
          <Collapsible
            collapsed={this.state.isNoteCollapsed}
            duration={500}
            collapsedHeight={0}
          >
            <View
              style={[
                styles.info_line,
                {
                  borderWidth: 0,
                  height: undefined,
                  alignItems: undefined,
                  justifyContent: undefined,
                },
              ]}
            >
              <TextInput
                multiline
                placeholder="Enter additional information..."
                onChangeText={note => this.setState({ note })}
                value={this.state.note}
                style={{
                  fontSize: 15,
                  fontWeight,
                  color: Colors.dark,
                  flex: 1,
                  height: 80,
                  paddingVertical: 8,
                  textAlignVertical: 'top',
                  marginVertical: 5,
                  marginHorizontal: Platform.OS === 'web' ? 3 : 0,
                  paddingHorizontal: 6,
                  borderRadius: 4,
                  backgroundColor: Colors.lightererer,
                }}
              />
            </View>
          </Collapsible>

          <View style={[Buttons.container, { marginTop: 16, marginBottom: 0 }]}>
            <TouchableOpacity
              style={[Buttons.big, Buttons.green]}
              onPress={() => this.submitReservation()}
            >
              <Text style={Buttons.text}>Confirm reservation</Text>
            </TouchableOpacity>
          </View>
          {showWalkIn && (
            <View
              style={[Buttons.container, { marginTop: 0, marginBottom: 10 }]}
            >
              <TouchableOpacity
                style={[Buttons.big, Buttons.border]}
                onPress={() => this.submitReservation(true)}
              >
                <Text style={[Buttons.text, Buttons.greenFont]}>
                  Seat walk-in
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
        {/*  </KeyboardAwareScrollView>*/}
        {/*</SafeAreaView>*/}
        {/*</Modal>*/}
      </ModalWrapper>
    );
  }
}

const styles = StyleSheet.create({
  info_left: {
    fontSize: 17,
    fontWeight: '500',
    // marginLeft: 12,
    // flex: 2,
    flexDirection: 'row',
    // backgroundColor:"blue",
  },
  info_right: {
    // backgroundColor:"yellow",
    fontSize: 17,
    fontWeight: '400',
    // flex: 4,
    textAlign: 'right',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    color: Colors.darker,
  },
  info_right_input: {
    paddingVertical: 10,
    paddingLeft: 20,
  },
  info_line: {
    justifyContent: 'space-between',
    // paddingLeft: 12,
    // paddingRight: 12,

    flexDirection: 'row',
    // borderBottomWidth: 0.75,
    borderBottomColor: '#ddd',
    // paddingBottom: 12,
    alignItems: 'center',
    height: 50,
    // backgroundColor:"green",
  },

  reservationContainer: {
    margin: 40,
    marginHorizontal: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: 46,
    backgroundColor: '#23d160',
    // borderWidth: 1.3,
    borderColor: '#00f',
    // paddingVertical: 12,
    borderRadius: 45,
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: {
      height: 3,
      width: 3,
    },
    elevation: 2,
  },
  reservationButtonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 18,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginLeft: 12,
    marginRight: 0,
  },
});
