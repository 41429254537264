import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';

const ICON_COLOR = '#333';

const userIcon = (
  <Ionicons name="md-person" size={18} color={ICON_COLOR} style={{ paddingRight: 6 }} />
);
const rightIcon = (
  <Ionicons name="ios-arrow-forward" size={22} color="#bbb" style={{}} />
);
const selectedIcon = (
  <Ionicons name="ios-checkmark" size={40} color={'rgba(35, 209, 96, 0.8)'} style={{}} />
);
const timeIcon = (
  <Ionicons name="md-time" size={17} color={ICON_COLOR} style={{ paddingRight: 4 }} />
);
const locationIcon = (
  <Ionicons name="md-pin" size={17} color={ICON_COLOR} style={{ paddingRight: 8 }} />
);
const groupIcon = (
  <Ionicons name="ios-cube" size={17} color={ICON_COLOR} style={{ paddingRight: 6 }} />
);

export default class AvailableTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTableId: 2,
    };
  }

  onSelect = selectedTableId => {
    const { showDetails } = this.props;

    this.setState({ selectedTableId });
    showDetails(selectedTableId);
  };

  renderTables = () => {
    const tables = [];
    const { availableTables, time, endTime } = this.props;

    if (availableTables && availableTables.length > 0) {
      for (let i = 0; i < availableTables.length; i += 1) {
        const { selectedTableId } = this.state;
        const isSelected = (selectedTableId === availableTables[i].id);
        tables.push(
          <TouchableOpacity
            style={[
              styles.tableView,
              isSelected
                ? styles.selectedItem
                : null,
            ]}
            key={i}
            onPress={() => this.onSelect(availableTables[i].id)}
          >
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingRight: 22,
                paddingLeft: 8,
              }}
            >
              <Text style={{ fontSize: 28, color: Colors.darkest }}>
                {availableTables[i].table_number}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                <View style={{}}>
                  <View style={{ flexDirection: 'row' }}>
                    {timeIcon}
                    <Text numberOfLines={1} style={styles.propertyText}>
                      {time} - {endTime}
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 3 }}>
                    {groupIcon}
                    <Text numberOfLines={1} style={styles.propertyText}>
                      {availableTables[i].group}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                {userIcon}
                <Text numberOfLines={1} style={styles.propertyText}>
                  {availableTables[i].chairs}
                </Text>
              </View>

              <View style={{ flexDirection: 'row', marginTop: 3 }}>
                {locationIcon}
                <Text numberOfLines={1} style={styles.propertyText}>
                  {availableTables[i].location}
                </Text>
              </View>
            </View>

            <View style={{ alignContent: 'center', justifyContent: 'center' }}>
              { isSelected ? selectedIcon : rightIcon}
            </View>
          </TouchableOpacity>,
        );
      }
    } else if (availableTables && availableTables.length == 0) {
      return (
        <View
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          <Image
            style={{ flex: 1, height: 300, width: 300, marginHorizontal: 65 }}
            source={require('../../assets/images/no_results.jpg')}
            resizeMode="contain"
          />
          <Text
            style={{
              fontSize: 21,
              color: Colors.dark,
              paddingHorizontal: 25,
              textAlign: 'center',
            }}
          >
            No tables are available on selected date and time :(
          </Text>
        </View>
      );
    }

    return tables;
  };

  render() {
    const { loadingAvailableTables, renderTables, isExpanding } = this.props;

    if (isExpanding || (renderTables && loadingAvailableTables)) {
      return (
        <View style={{ flex: 1, marginTop: 40, overflow: 'hidden' }}>
          <ActivityIndicator color="#bbb" />
        </View>
      );
    }
    if (renderTables) {
      return (
        <ScrollView>
          <this.renderTables />
        </ScrollView>
      );
    }
    return <View />;
  }
}

const styles = StyleSheet.create({
  tableListScroll: {
    flex: 1,
  },
  propertyText: { paddingTop: 0, color: '#000', fontSize: 16 },
  tableListStyle: {
    width:
      Dimensions.get('window').width > 420
        ? 420
        : Dimensions.get('window').width,
  },
  tableView: {
    // height: 65,
    margin: 12,
    marginTop: 10,
    padding: 12,
    marginBottom: 5,
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    borderRadius: 12,
    borderWidth: Platform.OS === 'ios' ? 0 : 0.4,
    borderColor: '#eee',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedItem: {
    borderColor: 'rgba(35, 209, 96, 0.55)',
    backgroundColor: 'rgba(35, 209, 96, 0.04)',
    // shadowColor:  Colors.confirmGreen,
    // borderColor: 'rgba(134, 197, 218, 0.95)',
    // shadowColor:  'rgba(35, 209, 96, 1)',
    borderWidth: 2,
    // shadowOpacity: 0,
    // elevation: 0,
  },
});
