import React from 'react';
import {
  Alert,
  Keyboard,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../../../constants/Colors';

import ModalStyle from '../../../../styles/ModalStyle';
import Buttons from '../../../../styles/Buttons';
import { withGlobalContext } from '../../../../GlobalContext';
import LayoutServices from '../../../../services/LayoutServices';

class NewRoomModal extends React.Component {
  constructor(props) {
    const { navigation } = props;
    super(props);
    this.state = {
      isVisible: true,
      roomName: '',
      roomWidth: 600,
      roomHeight: 400,
    };
  }

  closeModal = () => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  async addNewTable() {
    Keyboard.dismiss();

    const { roomName, roomWidth, roomHeight } = this.state;

    const { updateRooms } = this.props.global;

    const data = {};
    data.name = roomName;
    data.dimensions = { width: roomWidth, height: roomHeight };

    const result = await LayoutServices.addNewRoom(data);
    if (result.ok) {
      this.closeModal();
      updateRooms();
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  updateRoomName = value => {
    this.setState({ roomName: value });
  };

  updateRoomHeight = value => {
    const height = parseInt(value);
    this.setState({ roomHeight: height });
  };

  updateRoomWidth = value => {
    const width = parseInt(value);
    this.setState({ roomWidth: width });
  };

  render() {
    // const { isVisible } = this.props;
    const { isVisible } = this.state;
    const { roomName, roomHeight, roomWidth } = this.state;
    const {
      closeModal,
      updateRoomName,
      updateRoomHeight,
      updateRoomWidth,
    } = this;

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => closeModal()}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <TouchableWithoutFeedback
          onPress={Keyboard.dismiss}
          accessible={false}
          disabled={Platform.OS === 'web'}
        >
          <View style={ModalStyle.modalContainer}>
            <View style={ModalStyle.headerBar}>
              <View style={ModalStyle.headerNavigation} />
              <Text style={ModalStyle.headerTitle}>New Space</Text>
              <TouchableOpacity
                style={ModalStyle.headerNavigation}
                onPress={() => closeModal()}
              >
                <Ionicons name="md-close" size={29} color="#ccc" />
              </TouchableOpacity>
            </View>
            <View style={{ marginHorizontal: 36 }}>
              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>Name</Text>
                  <View style={ModalStyle.touchableSelect}>
                    <TextInput
                      // keyboardType="numeric"
                      style={[
                        ModalStyle.fontRight,
                        ModalStyle.inputRight,
                        { color: '#222', minWidth: 100 },
                      ]}
                      placeholderTextColor={Colors.placeholder}
                      onChangeText={value => updateRoomName(value)}
                      placeholder="Enter room name"
                      value={roomName}
                      autoCorrect={false}
                    />
                  </View>
                </View>
              </View>

              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>
                    Dimensions{' '}
                    <Text style={{ color: Colors.lighter, fontSize: 16 }}>
                      (cm)
                    </Text>
                  </Text>
                  <View style={ModalStyle.touchableSelect}>
                    <View style={ModalStyle.editableTextContainer}>
                      <TextInput
                        style={[ModalStyle.editableText]}
                        placeholderTextColor={Colors.placeholder}
                        // onFocus={() => this.setState({ lengthTemp: '' })}
                        selectTextOnFocus
                        onChangeText={value => updateRoomWidth(value)}
                        onEndEditing={this.confirmDimensions}
                        onBlur={this.confirmDimensions}
                        maxLength={5}
                        multiline={false}
                        keyboardType="number-pad"
                        value={String(roomWidth)}
                        autoCorrect={false}
                      />
                    </View>
                    <Text
                      style={[
                        ModalStyle.inputRight,
                        {
                          marginHorizontal: 12,
                          color: Colors.dark,
                          fontWeight: '500',
                        },
                      ]}
                    >
                      x
                    </Text>
                    <View style={ModalStyle.editableTextContainer}>
                      <TextInput
                        style={ModalStyle.editableText}
                        placeholderTextColor={Colors.placeholder}
                        // onFocus={() => this.setState({ widthTemp: '' })}
                        selectTextOnFocus
                        onChangeText={value => updateRoomHeight(value)}
                        onBlur={this.confirmDimensions}
                        onEndEditing={this.confirmDimensions}
                        maxLengt={5}
                        multiline={false}
                        keyboardType="number-pad"
                        value={String(roomHeight)}
                        autoCorrect={false}
                      />
                    </View>
                  </View>
                </View>
              </View>

              <View style={Buttons.container}>
                <TouchableOpacity
                  style={[Buttons.big, Buttons.green]}
                  onPress={() => this.addNewTable()}
                >
                  <Text style={Buttons.text}>Add New Space</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
}

export default withGlobalContext(NewRoomModal);
