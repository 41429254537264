import { FlatList, Platform, Text, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import Style from '../../../constants/Style';
import TablesViewStyle from '../../../styles/TablesViewStyle';
import Colors from '../../../constants/Colors';
import { Ionicons } from '@expo/vector-icons';

import NavigationService from '../../../services/NavigationService';
import TableVanilla from '../../Spaces/RoomsComponents/TableVanilla';
import AddEditTableModal from '../modals/AddEditTableModal';
import Table from '../../Spaces/RoomsComponents/Table';

const extractTableKey = item => `table + ${item.id}`;
const moreIcon = (
  <Ionicons name="ellipsis-vertical" size={18} color={Colors.lightBlue} />
);

const showUpdateModal = table => {
  NavigationService.navigate('updateTable', { table });
};

const showNewTablesModal = groupId => {
  NavigationService.navigate('newTable', { groupId });
};

const renderTablesHeader = () => {
  return (
    <View
      style={[
        TablesViewStyle.tableRowContainer,
        {
          backgroundColor: Colors.lightBackground,
          height: 38,
          borderRadius: 6,
        },
      ]}
    >
      <View style={[TablesViewStyle.column, TablesViewStyle.columnFirst]}>
        <Text style={[TablesViewStyle.rowTextDark]}>Table</Text>
      </View>
      <View style={[TablesViewStyle.column]}>
        <Text style={[TablesViewStyle.rowTextLight]}>Chairs</Text>
      </View>
      <View style={[TablesViewStyle.column]}>
        <Text style={[TablesViewStyle.rowTextLight]}>Device</Text>
      </View>
      <View style={[TablesViewStyle.column]}>
        <Text style={[TablesViewStyle.rowTextLight]}>Model</Text>
      </View>
      <View style={[TablesViewStyle.column, TablesViewStyle.columnLast]}>
        <Text style={[TablesViewStyle.rowTextLight]}>Edit</Text>
      </View>
    </View>
  );
};

const deviceStatus = table => {
  let color = Colors.white;
  const { status, battery_level } = table.device;
  if (!table.device_linked) {
    color = Colors.disabledLighter;
  } else if (battery_level < 7 || status === 'offline') {
    color = Colors.dangerRed;
  } else if (battery_level < 20) {
    color = Colors.yellow;
    // return <Ionicons name="ios-battery-dead" size={24} color={color} />;
  } else {
    color = Colors.confirmGreen;
  }

  // return (<Text>{table.device.battery_level}</Text>);
  const batteryLevel = parseInt(table.device.battery_level);
  return (
    <View
      style={{
        backgroundColor: color,
        minWidth: 10,
        minHeight: 10,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {table.device_linked && status !== 'offline' ? (
        <Text
          style={{
            color: '#fff',
            fontSize: 12,
            fontWeight: '500',
            paddingHorizontal: 7,
            paddingVertical: 1.5,
          }}
        >
          {(parseInt(batteryLevel / 5, 10) + 1) * 5}
        </Text>
      ) : null}
    </View>
  );
};

const RenderTable = ({ item, onEdit }) => {
  // console.log(item);
  return (
    <View style={[TablesViewStyle.tableRowContainer]}>
      <View style={[TablesViewStyle.column, TablesViewStyle.columnFirst]}>
        <Text
          style={[
            TablesViewStyle.rowText,
            {
              fontWeight: Platform.OS === 'ios' ? '600' : '700',
              color: Colors.darker,
              paddingLeft: 10,
            },
          ]}
        >
          {item.table_number}
        </Text>
      </View>

      <View style={[TablesViewStyle.column]}>
        <Text style={TablesViewStyle.rowText}>{item.chairs}</Text>
      </View>
      <View style={[TablesViewStyle.column]}>
        {deviceStatus(item)}
        {/* <Text style={[TablesViewStyle.rowText, {fontSize: 13}]}> 20</Text> */}
      </View>
      <View
        style={[
          TablesViewStyle.column,
          { width: 38, justifyContent: 'center' },
        ]}
      >
        {item.table_model.id ? (
          <Table
            maxWidth={50}
            maxHeight={38}
            tableWidth={item.table_model.dimensions.width}
            tableLength={item.table_model.dimensions.length}
            isRound={item.table_model.dimensions.round}
            chairsTop={item.table_model.chairs.top}
            chairsBottom={item.table_model.chairs.bottom}
            chairsLeft={item.table_model.chairs.left}
            chairsRight={item.table_model.chairs.right}
            chairsCount={item.table_model.chairs.count}
          />
        ) : (
          <Text> </Text>
        )}
      </View>

      <TouchableOpacity
        style={[TablesViewStyle.column, TablesViewStyle.columnLast]}
        // onPress={() => showUpdateModal(item)}
        onPress={() => onEdit(item)}
      >
        <Text style={[TablesViewStyle.rowText, {}]}>{moreIcon}</Text>
      </TouchableOpacity>
    </View>
  );
};

const TablesFooter = ({ groupId, groupName }) => {
  const [showNewTable, setShowNewTable] = useState(false);
  return (
    <View>
      {showNewTable && (
        <AddEditTableModal
          visible={showNewTable}
          close={() => setShowNewTable(!showNewTable)}
          groupId={groupId}
          groupName={groupName}
        />
      )}

      <TouchableOpacity
        // onPress={() => showNewTablesModal(groupId)}
        onPress={() => setShowNewTable(true)}
        style={[
          TablesViewStyle.tableRowContainer,
          {
            marginBottom: 12,
            marginTop: 4,
            marginHorizontal: 4,
            alignItems: 'center',
            backgroundColor: Colors.someGreen,
            borderRadius: 4,
            height: 36,
          },
          Style.shadowX,
        ]}
      >
        <Ionicons
          name="add"
          size={22}
          color={Colors.white}
          style={{ width: 22, height: 22 }}
        />
        <View
          style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}
        >
          <Text
            style={{ color: Colors.white, fontSize: 13, fontWeight: '500' }}
          >
            ADD TABLE
          </Text>
        </View>
        <View style={{ width: 25 }} />
      </TouchableOpacity>
    </View>
  );
};

const Separator = () => {
  return (
    <View
      style={{
        marginHorizontal: 0,
        borderBottomWidth: 1,
        borderBottomColor: Colors.lightererer,
      }}
    />
  );
};

const NoTables = () => {
  return (
    <View
      style={{
        margin: 30,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text style={{ fontSize: 17, color: Colors.light, fontWeight: '400' }}>
        You haven't added any tables to this group yet.
      </Text>
    </View>
  );
};

const RenderTables = props => {
  const { tables, groupId, groupName } = props;
  const [showEditTable, setShowEditTable] = useState(false);
  const [editTable, setEditTable] = useState(null);

  const onEdit = table => {
    setEditTable(table);
  };

  const onEndEdit = () => {
    setEditTable(null);
  };

  useEffect(() => {
    setShowEditTable(prevState => !!editTable);
  }, [editTable]);

  return (
    <View style={{ flex: 1 }}>
      {renderTablesHeader()}
      <FlatList
        // style={[{ marginLeft: 7 }]}
        data={tables}
        // renderItem={renderTable}
        renderItem={({ item }) => <RenderTable item={item} onEdit={onEdit} />}
        keyExtractor={extractTableKey}
        ItemSeparatorComponent={Separator}
        // ListFooterComponent={() => TablesFooter(groupId)}
        ListEmptyComponent={NoTables}
        initialNumToRender={500}
      />
      <TablesFooter groupId={groupId} groupName={groupName} />
      {editTable && (
        <AddEditTableModal
          id={editTable ? editTable?.id : 0}
          visible={showEditTable}
          close={() => onEndEdit()}
          groupId={editTable?.table_group_id}
          groupName={editTable?.table_group_name}
          editTable={editTable || null}
        />
      )}
    </View>
  );
};

export default RenderTables;
