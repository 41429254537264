import React, { useContext, useState } from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import TablesViewStyle from '../../../styles/TablesViewStyle';

import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import RoomStatic from '../../Spaces/RoomsComponents/RoomStatic';
import Buttons from '../../../styles/Buttons';
import NewSpaceModal from '../modals/NewSpaceModal';
import FloatButton from '../components/FloatButton';
import { GlobalContext } from '../../../GlobalContext';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const RenderRooms = props => {
  const { rooms, width, showRoom } = props;

  if (!rooms || !width) {
    return null;
  }
  return rooms.map((room, key) => {
    let k = (width * 1 - 60) / room.dimensions.width;
    if (k * room.dimensions.height >= 1.5 * width) {
      k = (width * 1 - 60) / room.dimensions.height;
    }
    const roomContainer = {
      width: k * room.dimensions.width,
      height: k * room.dimensions.height,
      fullWidth: width,
      scaleFactor: k,
    };

    const floorOptions = [];
    floorOptions[0] = { label: 'Ground', key: 0, value: 0 };
    floorOptions[1] = { label: '1st', key: 1, value: 1 };
    floorOptions[2] = { label: '2nd', key: 2, value: 2 };
    floorOptions[3] = { label: '3rd', key: 3, value: 3 };
    for (let i = 4; i < 100; i += 1) {
      floorOptions[i] = {
        label: i + 'th',
        key: i,
        value: i,
      };
    }

    return (
      <View
        key={key}
        style={[
          {
            margin: 10,
            borderRadius: 6,
            backgroundColor: Colors.white,
            paddingHorizontal: 16,
          },
          Style.shadow,
        ]}
      >
        <View
          style={{
            marginTop: 16,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ justifyContent: 'center' }}>
            <Text
              style={{
                fontSize: 17,
                fontWeight: '600',
                color: Colors.darkest,
                textTransform: 'capitalize',
              }}
            >
              {room.name}
              <Text
                style={{
                  color: Colors.lighter,
                  fontSize: 15,
                  textTransform: 'none',
                  // letterSpacing: 2,
                }}
              >
                {' '}
                {floorOptions[room?.floor]?.label}{' '}
                {room?.floor !== 0 && 'floor'}
              </Text>
            </Text>
          </View>
          <View
            style={
              {
                // justifyContent: 'center',
                // alignItems: 'center',
                // paddingVertical: 12,
              }
            }
          >
            <TouchableOpacity
              onPress={() => showRoom(room)}
              style={TablesViewStyle.editButton}
            >
              <Text style={TablesViewStyle.editButtonFont}>Edit Space</Text>
            </TouchableOpacity>
            {/*<Ionicons*/}
            {/*  name="chevron-forward"*/}
            {/*  size={20}*/}
            {/*  style={{ color: Colors.dark }}*/}
            {/*/>*/}
          </View>
        </View>
        <View
          style={{
            paddingBottom: 16,
            paddingTop: 10,
          }}
        >
          <View
            style={{
              paddingBottom: 16,
            }}
          >
            <Text style={style.bodyText}>
              <Text style={style.boldText}>Size </Text>
              {room.dimensions.width / 100} m x {room.dimensions.height / 100}
              {' m  |  '}
              <Text style={style.boldText}>Location </Text>
              {room.outside ? 'Outside' : 'Inside'}
            </Text>
          </View>
          <RoomStatic
            room={room}
            roomContainer={roomContainer}
            backgroundColor={'rgba(0,0,0, 0.02)'}
          />
        </View>
      </View>
    );
  });
};

const SpacesView = props => {
  const global = useContext(GlobalContext);

  const { deviceType, rooms } = global;
  const { navigation } = props;

  const [showAddSpaceModal, setShowAddSpaceModal] = useState(false);

  const hideAddSpaceModal = () => {
    setShowAddSpaceModal(false);
  };
  const showNewModal = () => {
    setShowAddSpaceModal(true);
  };

  const showRoom = room => {
    navigation.push('editSpace', { room });
  };

  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const isNarrow = dimensions.width < 650;

  return (
    <View style={[TablesViewStyle.container]}>
      {isNarrow && <FloatButton onPress={() => showNewModal()} />}
      <View style={TablesViewStyle.topBarContainer}>
        <View
          style={[
            TablesViewStyle.menuBarContainer,
            { marginTop: Math.max(insets.top, 10) + 6 },
          ]}
        >
          <View />
          {!isNarrow && (
            <TouchableOpacity
              style={Buttons.button}
              onPress={() => showNewModal()}
            >
              <Text style={Buttons.buttonIcon}>
                <Ionicons name="md-add" size={22} />
              </Text>
              <Text style={Buttons.buttonText}> Add space </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <ScrollView
        style={[TablesViewStyle.innerContainerRooms, { flex: 1 }]}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ height: 60 + Math.max(insets.top, 10) }} />

        <View
          style={{
            flex: 1,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <RenderRooms
            showRoom={showRoom}
            rooms={rooms}
            width={Math.min(800, dimensions.width)}
          />
        </View>
      </ScrollView>
      <NewSpaceModal visible={showAddSpaceModal} close={hideAddSpaceModal} />
    </View>
  );
};
export default SpacesView;

const style = StyleSheet.create({
  bodyText: {
    fontSize: 15,
    color: Colors.darkest,
    marginTop: 6,
  },
  boldText: {
    fontWeight: Platform.OS === 'android' ? '600' : '600',
  },
});
