import {
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
} from 'react-native-reanimated';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import Colors from '../constants/Colors';

interface SnackbarInterface {
  message: string;
  isVisible: boolean;
  onDismiss: () => null;
  type?: 'success' | 'warning' | 'error' | 'info';
}

const SnackbarNotification = ({
  message,
  isVisible,
  onDismiss,
  type,
}: SnackbarInterface) => {
  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const portrait = dimensions.width < dimensions.height ? 1 : 0;
  const web = Platform.OS === 'web' ? 1 : 0;
  const snackbarWidth = dimensions.width > 600 ? 420 : dimensions.width;
  const snackbarXCollapsed = 600;
  const x = useSharedValue(snackbarXCollapsed);

  const [timerId, setTimerId] = useState();

  const showSnackBar = ({ delay = 0 }: any) => {
    x.value = withDelay(
      delay || 0,
      withSpring(0, {
        damping: 30,
        stiffness: 200,
      }),
    );
  };

  const hideSnackBar = () => {
    onDismiss();
    clearTimeout(timerId);
    x.value = withDelay(
      0,
      withSpring(snackbarXCollapsed, {
        damping: 20,
        stiffness: 100,
      }),
    );
  };

  useEffect(() => {
    if (isVisible) {
      clearTimeout(timerId);
      const timer = setTimeout(() => {
        hideSnackBar();
      }, 6000);
      setTimerId(timer);
      showSnackBar({ delay: 300 });

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isVisible]);

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.start = x.value;
    },
    onActive: (event, ctx) => {
      x.value = ctx.start + event.translationX;
    },
    onEnd: (event, ctx) => {},
  });

  const handlePanHandlerState = ({ nativeEvent }) => {
    if (nativeEvent.state === State.END) {
      if (x.value > 60) {
        hideSnackBar();
      } else {
        showSnackBar({ delay: 0 });
      }
    } else if (nativeEvent.state === State.BEGAN) {
      clearTimeout(timerId);
    } else if (nativeEvent.state === State.CANCELLED) {
      console.log('oops');
    }
  };

  const animatedStyleContainer = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: x.value,
        },
      ],
    };
  });

  return (
    <PanGestureHandler
      onGestureEvent={gestureHandler}
      onHandlerStateChange={handlePanHandlerState}
    >
      <Animated.View
        style={[
          styles.container,
          {
            width: snackbarWidth,
            bottom: 46 + insets.bottom + portrait * 8 + web * 10,
            overflow: 'hidden',
          },
          // animatedStyleContainer,
        ]}
      >
        <Animated.View style={[animatedStyleContainer]}>
          <View
            style={[
              styles.innerContainer,
              type === 'success' && styles.successBg,
              type === 'info' && styles.infoBg,
              type === 'warning' && styles.warningBg,
              type === 'error' && styles.errorBg,
            ]}
          >
            <Text style={styles.text}>{message}</Text>
            {type === 'warning' && (
              <Text style={[styles.buttonText, styles.warning]}>Warning</Text>
            )}
            {type === 'error' && (
              <Text style={[styles.buttonText, styles.error]}>Error</Text>
            )}
            {/*{type === 'success' && (*/}
            {/*  <Text style={[styles.buttonText, styles.success]}>Success</Text>*/}
            {/*)}*/}
            {/*{type === 'info' && (*/}
            {/*  <Text style={[styles.buttonText, styles.info]}>*/}
            {/*    /!*<Ionicons name={'information'} size={20} />*!/*/}
            {/*    Info*/}
            {/*  </Text>*/}
            {/*)}*/}
          </View>
          {/*<View*/}
          {/*  style={{*/}
          {/*    width: snackbarWidth - 24,*/}
          {/*    backgroundColor: '#358be1',*/}
          {/*    height: 5,*/}
          {/*  }}*/}
          {/*/>*/}
        </Animated.View>
      </Animated.View>
    </PanGestureHandler>
  );
};

export default SnackbarNotification;
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    minHeight: 70,
    right: 0,
    padding: 12,
  },
  warningBg: {
    backgroundColor: '#e5a14a',
  },
  errorBg: {
    backgroundColor: Colors.pastelRed,
  },
  successBg: {
    backgroundColor: 'rgba(11,203,78,0.9)',
  },
  infoBg: {
    backgroundColor: '#4a87e5',
  },
  innerContainer: {
    backgroundColor: 'black',
    flex: 1,
    borderRadius: 4,
    // shadowOpacity: 0.2,
    shadowOpacity: 1,
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowRadius: 10,
    elevation: 10,
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontWeight: '500',
    flexWrap: 'wrap',
    flex: 1,
    lineHeight: 20,
  },
  buttonText: {
    color: '#7cc0ef',
    fontSize: 14,
    fontWeight: '600',
    marginLeft: 20,
    marginRight: 6,
    // width: 100,
  },
  warning: {
    color: '#fffc47',
  },
  error: {
    // color: '#f32828',
    color: '#940909',
  },
  success: {
    color: '#52e731',
  },
  info: {
    // color: '#6593d9',
    color: '#135485',
  },
});
