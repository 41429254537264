import React, { useContext, useState } from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TablesViewStyle from '../../../styles/TablesViewStyle';
import TableVanilla from '../../Spaces/RoomsComponents/TableVanilla';

import { GlobalContext, withGlobalContext } from '../../../GlobalContext';

import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import Buttons from '../../../styles/Buttons';
import FloatButton from '../components/FloatButton';
import NewModelModal from '../modals/NewModelModal';
import Table from '../../Spaces/RoomsComponents/Table';

const ROW_HEIGHT = 140;

const RenderModels = props => {
  const { models } = props;
  if (!models) {
    return null;
  }
  return models.map((model, key) => {
    return (
      <View
        key={key}
        style={[
          {
            flex: 1,
            flexBasis: 300,
            height: 210,
            margin: 8,
            padding: 15,
            flexDirection: 'row',
            borderRadius: 8,
            backgroundColor: Colors.white,
            borderWidth: 0.5,
            borderColor: Colors.lighterer,
          },
          Style.shadowShort,
        ]}
      >
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 18, marginBottom: 10, fontWeight: '500' }}>
            {model.name}
          </Text>
          <Text style={{ marginBottom: 5, fontSize: 16, color: Colors.dark }}>
            {model.dimensions.length} x {model.dimensions.width} cm
          </Text>
          <Text style={{ marginBottom: 5, fontSize: 16, color: Colors.dark }}>
            {model.chairs.count} chairs
          </Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            // backgroundColor: 'red',
            paddingHorizontal: 40,
            // marginHorizontal: 40,
          }}
        >
          <Table
            maxWidth={ROW_HEIGHT}
            maxHeight={ROW_HEIGHT}
            tableWidth={model.dimensions.width}
            tableLength={model.dimensions.length}
            isRound={model.dimensions.round}
            chairsTop={model.chairs.top}
            chairsBottom={model.chairs.bottom}
            chairsLeft={model.chairs.left}
            chairsRight={model.chairs.right}
            chairsCount={model.chairs.count}
          />
        </View>
      </View>
    );
  });
};

const ModelsView = props => {
  const global = useContext(GlobalContext);
  const { deviceType, models } = global;

  // const showNewModal = () => {
  //   props.navigation.navigate('add_model');
  // };

  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const isNarrow = dimensions.width < 650;

  const [showAddModal, setShowAddModal] = useState(false);

  const hideNewModelModal = () => {
    setShowAddModal(false);
  };
  const showNewModal = () => {
    setShowAddModal(true);
  };

  return (
    <View style={[TablesViewStyle.container]}>
      {isNarrow && <FloatButton onPress={() => showNewModal()} />}
      <View style={TablesViewStyle.topBarContainer}>
        <View
          style={[
            TablesViewStyle.menuBarContainer,
            { marginTop: Math.max(insets.top, 10) + 6 },
          ]}
        >
          <View />
          {!isNarrow && (
            <TouchableOpacity
              style={Buttons.button}
              onPress={() => showNewModal()}
            >
              <Text style={Buttons.buttonIcon}>
                <Ionicons name="md-add" size={22} />
              </Text>
              <Text style={Buttons.buttonText}> Add model </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <ScrollView
        style={[TablesViewStyle.innerContainerModels]}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ height: 60 + Math.max(insets.top, 10) }} />
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: 1,
            justifyContent: 'center',
            marginLeft: insets.left,
            marginRight: insets.right,
          }}
        >
          <RenderModels models={models} />
        </View>
      </ScrollView>
      <NewModelModal
        isVisible={showAddModal}
        closeModal={hideNewModelModal}
        smallDevice={Math.min(dimensions.width, dimensions.height) < 600}
      />
    </View>
  );
};

export default withGlobalContext(ModelsView);
